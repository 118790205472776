import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

interface FilterItemProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const ListFilterItem: React.FC<FilterItemProps> = ({
  label,
  onChange,
  checked,
}) => (
  <FormControlLabel
    control={
      <Checkbox onChange={onChange} color={'primary'} checked={checked} />
    }
    label={label}
  />
);

export default React.memo(ListFilterItem);
