import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import MDModal from '../../shared/MDModal';
import { User } from '../../../entities/User';
import { isValidUKNumber } from '../../../utils/utils';

import { editUser } from '../../../services/api/dashboard';

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  tenant: string;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  user,
  tenant,
}) => {
  const defaultUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    electionManager: user.electionManager,
  };

  const [userDetails, setUserDetails] = useState(defaultUser);
  const [smsIsValid, setSmsIsValid] = useState(true);

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFirstNameChange = (name: string) =>
    setUserDetails({ ...userDetails, firstName: name });

  const handleLastNameChange = (name: string) =>
    setUserDetails({ ...userDetails, lastName: name });

  const handleNumberChange = (phoneNumber: string) => {
    if (
      (phoneNumber.startsWith('0') && phoneNumber.length <= 11) ||
      (phoneNumber.startsWith('+') && phoneNumber.length <= 13) ||
      phoneNumber === ''
    ) {
      setUserDetails({ ...userDetails, phoneNumber });
    }
  };

  const edit = async () => {
    try {
      setLoading(true);
      const params = {
        tenant,
        userId: user.id,
        ...userDetails,
        isEM: false,
      };
      await editUser(params);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSmsIsValid(
      userDetails?.phoneNumber.length > 0
        ? isValidUKNumber(userDetails?.phoneNumber)
        : true
    );
  }, [userDetails]);

  useEffect(() => {
    if (
      userDetails?.firstName &&
      userDetails?.lastName &&
      userDetails?.phoneNumber &&
      smsIsValid
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [userDetails, smsIsValid]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={'Edit User'}
      loading={loading}
      success={success}
      errorMessage={errorMessage}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <TextField
            label={'First Name'}
            value={userDetails.firstName || ''}
            onChange={({ target }) => handleFirstNameChange(target.value)}
            variant="standard"
          />
          <TextField
            label={'Last Name'}
            value={userDetails.lastName || ''}
            onChange={({ target }) => handleLastNameChange(target.value)}
            variant="standard"
          />
          <TextField
            label={'Phone Number'}
            value={userDetails.phoneNumber || ''}
            onChange={({ target }) => handleNumberChange(target.value)}
            error={!smsIsValid}
            helperText={'Must match:\n0XXXXXXXXX or +44XXXXXXXXXX'}
            variant="standard"
          />
        </div>
      }
      actionBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading || success || !submitEnabled}
            onClick={edit}
          >
            Confirm Edit
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(EditUserModal);
