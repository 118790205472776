import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { EnvLoader } from './Env';
import './index.css';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }
ReactDOM.render(
  <React.StrictMode>
    <EnvLoader>
      <App />
    </EnvLoader>
  </React.StrictMode>,
  document.getElementById('root')
);
