import { Button, TextField } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import { reassignBallots } from '../../../../services/api/support';
import { DropDown } from '../../../shared/DropDown';
import MDModal from '../../../shared/MDModal';
import { GenericModalProps } from '../../GenericModalProps';
import TenantPollstationPicker from './TenantPollstationPicker';

import { sortAlphaNumeric } from '../../../../utils/utils';

const ReassignBallotsModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
}: GenericModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  // Modal fields
  const [uimPrefix, setUimPrefix] = useState<string>('');
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [electionName, setElectionName] = useState<string>('');
  const [electionTitle, setElectionTitle] = useState<string>('');
  const [areaName, setAreaName] = useState<string>('');
  const [selectedBallotBook, selectedBallotBookSet] = useState<string>('');

  // Modal flags
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 10000);
    }
  }, [errorMessage]);

  const electionNames: string[] = useMemo(() => {
    if (selectedPollstation) {
      return [
        '',
        ...Object.entries(selectedPollstation.ballots)
          .map(([key, election]) => {
            const name = Object.values(election)[0].electionname;
            return `${key} - ${name}`;
          })
          .sort(sortAlphaNumeric),
      ];
    } else {
      return [];
    }
  }, [selectedPollstation]);

  useEffect(() => {
    setElectionName('');
  }, [selectedPollstation]);

  const areaNames: string[] = useMemo(() => {
    if (selectedPollstation && electionName) {
      const election = electionName.split(' - ')[0];
      return ['', ...Object.keys(selectedPollstation.ballots[election])];
    } else {
      return [];
    }
  }, [selectedPollstation, electionName]);

  const ballotNumbers: string[] = useMemo(() => {
    if (selectedPollstation && electionName && areaName) {
      const election = electionName.split(' - ')[0];
      return [
        '',
        ...selectedPollstation.ballots[election][areaName].ordinary.numbers.map(
          ({ uimPrefix, from, to }) => {
            console.log('uimPrefix', uimPrefix);
            console.log('from', from);
            console.log('to', to);
            return `UIM: ${uimPrefix}, From: ${from}, To: ${to}`;
          }
        ),
      ];
    }
    return [];
  }, [selectedPollstation, electionName, areaName]);

  const lengthsMatch = from.length === to.length;

  useEffect(() => {
    setSubmitEnabled(
      !!(
        selectedTenant &&
        selectedPollstation &&
        selectedElectionDate &&
        electionName &&
        electionTitle &&
        areaName &&
        selectedBallotBook &&
        uimPrefix &&
        from &&
        to &&
        !success &&
        lengthsMatch
      )
    );
  }, [
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
    electionName,
    electionTitle,
    areaName,
    selectedBallotBook,
    uimPrefix,
    from,
    to,
    success,
  ]);

  const onSubmit = async () => {
    const [originalUimPrefix, originalFrom, originalTo] = selectedBallotBook
      .split(',')
      .map((e) => e.split(':')[1].trim());
    return Promise.resolve(setLoading(true)).then(() =>
      reassignBallots({
        tenant: selectedTenant,
        pollStation: selectedPollstation,
        electionDate: selectedElectionDate,
        electionName: electionName.split(' - ')[0],
        electionTitle,
        areaName,
        originalUimPrefix,
        originalFrom,
        originalTo,
        uimPrefix,
        from,
        to,
      })
        .then(() => setSuccess(true))
        .catch((err) => setErrorMessage(err.message))
        .finally(() => setLoading(false))
    );
  };

  const content = (
    <>
      <TenantPollstationPicker />
      <DropDown
        disabled={loading || fetching}
        options={electionNames}
        label="Election Name"
        name="Election Name"
        value={electionName}
        onSelect={(name) => {
          setAreaName('');
          setElectionName(name);
        }}
        fullWidth
      />
      {electionName && (
        <DropDown
          disabled={loading || fetching}
          options={areaNames}
          label="Area Name"
          name="Area Name"
          value={areaName}
          onSelect={setAreaName}
          fullWidth
        />
      )}
      {areaName && (
        <DropDown
          disabled={loading || fetching}
          options={ballotNumbers}
          label="Ballots to replace"
          name="Ballots to replace"
          value={selectedBallotBook}
          onSelect={selectedBallotBookSet}
          fullWidth
        />
      )}
      {selectedBallotBook && (
        <>
          <TextField
            disabled={loading || fetching}
            id="uim"
            label="New UIM"
            variant="outlined"
            margin="normal"
            fullWidth
            value={uimPrefix}
            onChange={(e) => setUimPrefix(e.target.value)}
          />
          <TextField
            disabled={loading || fetching}
            id="startRange"
            label="New From"
            variant="outlined"
            margin="normal"
            fullWidth
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
          <TextField
            disabled={loading || fetching}
            id="endRange"
            label="New To"
            variant="outlined"
            margin="normal"
            fullWidth
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
          {!lengthsMatch && to && to.length > 0 ? (
            <p style={{ color: 'red' }}>
              From and To values must have the same amount of digits.
            </p>
          ) : (
            ''
          )}
          <TextField
            disabled={loading || fetching}
            id="electionTitle"
            label="Election Title"
            variant="outlined"
            margin="normal"
            fullWidth
            value={electionTitle}
            onChange={(e) => setElectionTitle(e.target.value)}
          />
        </>
      )}
    </>
  );

  const actionBar = (
    <Button
      id="runReasignBallotsBtn"
      disabled={!submitEnabled}
      color="primary"
      variant="contained"
      size="medium"
      fullWidth
      onClick={onSubmit}
    >
      Run Task
    </Button>
  );

  return (
    <MDModal
      open={open}
      content={content}
      actionBar={actionBar}
      onClose={onClose}
      title="Reassign Ballots"
      height={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
    />
  );
};

export default ReassignBallotsModal;
