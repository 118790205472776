import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { SyncDisabled, SyncProblem, Sync } from '@mui/icons-material';
import { getLastOnlineTime } from '../../utils/utils';

enum DeviceStatus {
  LoggedOut,
  Syncd,
  Unsyncd,
}

interface DeviceStatusProps {
  openStatus: boolean;
  lastTimestamp: number;
  unsyncdCount: number;
}

export const DeviceStatusComp: React.FC<DeviceStatusProps> = ({
  openStatus,
  lastTimestamp,
  unsyncdCount,
}) => {
  let status;

  const twoMinsAgo = Date.now() - 2 * 60 * 1000;
  if (openStatus === true) {
    status =
      getLastOnlineTime(lastTimestamp) > twoMinsAgo
        ? DeviceStatus.Syncd
        : DeviceStatus.Unsyncd;
  } else {
    status = DeviceStatus.LoggedOut;
  }

  let icon;
  let syncStatus;
  switch (status) {
    case DeviceStatus.LoggedOut:
      icon = <SyncDisabled className={'sync-icon_offline'} />;
      syncStatus = (
        <>
          <Typography>Logged Out</Typography>
          Logged out with {unsyncdCount} records to sync
        </>
      );

      break;
    case DeviceStatus.Unsyncd:
      icon = <SyncProblem className={'sync-icon_alert'} />;
      syncStatus = (
        <>
          <Typography>Logged In</Typography>
          Device connectivity issues or is asleep and had {unsyncdCount} records
          to sync at last heartbeat
        </>
      );
      break;
    default:
      icon = <Sync className={'sync-icon_good'} />;
      syncStatus = (
        <>
          <Typography>Logged In</Typography>
          Device is logged in with {unsyncdCount} records to sync
        </>
      );
      break;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Tooltip title={syncStatus}>{icon}</Tooltip>
    </div>
  );
};

export default React.memo(DeviceStatusComp);
