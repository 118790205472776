import React, { useState, useContext, useMemo } from 'react';
import { Button } from '@mui/material';
import { FilterList as FilterIcon } from '@mui/icons-material';
import { TenantContext } from '../../../context/TenantContext';
import { DropDown } from '../../shared/DropDown';
import FilterBar from '../../shared/FilterBar';
import FilterList from './FilterList';
import { DashboardContext } from '../../../context/DashboardContext';

const PollstationFilterBar = () => {
  const {
    tenants,
    selectedTenant,
    setTenant,
    electionsForTenant,
    selectedElection,
    setElection,
  } = useContext(TenantContext);

  const { activeFilterCount } = useContext(DashboardContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const filterListOpen = Boolean(anchorEl);

  const buttonHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const filterListClose = () => {
    setAnchorEl(null);
  };

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  return (
    <FilterBar>
      <DropDown
        options={tenantNames}
        name="Tenant"
        label="Tenant"
        value={selectedTenant}
        onSelect={(value) => setTenant(value)}
      />
      <DropDown
        options={['', ...electionsForTenant]}
        name="Election Date"
        label="Election Date"
        value={selectedElection}
        onSelect={(value) => setElection(value)}
      />
      <Button
        color="primary"
        onClick={buttonHandler}
        startIcon={<FilterIcon />}
      >
        {`More Filters${
          activeFilterCount > 0 ? ` (${activeFilterCount})` : ''
        }`}
      </Button>

      {filterListOpen && (
        <FilterList
          open={filterListOpen}
          onClose={filterListClose}
          anchor={anchorEl}
        />
      )}
    </FilterBar>
  );
};

export default React.memo(PollstationFilterBar);
