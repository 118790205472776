import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { GetEligibilityRulesRequest } from '../../../../entities/Support';
import { updateEligibilityRules } from '../../../../services/api/support';
import '../../../../styles.css';
import { DropDown, DropDownOption } from '../../../shared/DropDown';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { EligibilityRules } from '../../../../entities/EligibilityRules';
import { RadioOption } from '../../../shared/MDRadioGroup';
import { ElectionTypeRadioGroup } from '../ElectionTypeRadioGroup';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

interface EligibilityRulesModalProps extends GenericModalProps {
  eligibilityRulesFetch: (
    params: GetEligibilityRulesRequest
  ) => Promise<EligibilityRules>;
  title: string;
}

interface JurisdictionRules {
  [electionType: string]: {
    age: number;
    flags: string[];
    voterIdRequired: boolean;
    enforcementDate: string;
  };
}

const ChangeEligibilityRulesModal: React.FC<EligibilityRulesModalProps> = ({
  open,
  onClose,
  eligibilityRulesFetch,
  title,
}: EligibilityRulesModalProps) => {
  const [eligibilityConfig, setEligibilityConfig] =
    useState<EligibilityRules>();
  const [jurisdictionRules, setJurisdictionRules] =
    useState<JurisdictionRules>();
  const [electionTypesList, setElectionTypesList] = useState<string[]>([]);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('');
  const [selectedElectionType, setSelectedElectionType] = useState<string>('');
  const [selectedAgeRequirement, setSelectedAgeRequirement] =
    useState<string>('');
  const [selectedFlags, setSelectedFlags] = useState<string>('');
  const [enforcementDate, setEnforcementDate] = useState<string>(
    dayjs(new Date()).format('YYYY/MM/DD')
  );
  const [newElectionType, setNewElectionType] = useState<string>('');
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [voteridRequired, setVoteridRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [electionTypeIsValid, setElectionTypeIsValid] = useState(true);
  const handleEnforcementDateChange = (newValue: Dayjs | null) => {
    setEnforcementDate(
      newValue ? newValue.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
    );
  };

  const handleVoteridRequiredChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVoteridRequired(event.target.checked);
  };
  const onSubmit = async () => {
    if (eligibilityConfig) {
      const updatedEligibilityConfig = eligibilityConfig;
      if (selectedJurisdiction && selectedElectionType) {
        updatedEligibilityConfig[selectedJurisdiction][selectedElectionType][
          'age'
        ] = Number(selectedAgeRequirement);
        updatedEligibilityConfig[selectedJurisdiction][selectedElectionType][
          'flags'
        ] = selectedFlags.split(',');
        updatedEligibilityConfig[selectedJurisdiction][selectedElectionType][
          'voterIdRequired'
        ] = Boolean(voteridRequired);
        updatedEligibilityConfig[selectedJurisdiction][selectedElectionType][
          'enforcementDate'
        ] = dayjs(enforcementDate).format('YYYY-MM-DD');
      }
      const data = {
        eligibilityRules: updatedEligibilityConfig,
      };
      return Promise.resolve(setLoading(true))
        .then(() =>
          updateEligibilityRules(data)
            .then(() => {
              setSuccess(true);
              setTimeout(() => setSuccess(false), 5000);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  };

  useEffect(() => {
    setSelectedElectionType('');
    setSelectedAgeRequirement('');
    setSelectedFlags('');
  }, [selectedJurisdiction]);

  useEffect(() => {
    if (selectedJurisdiction && selectedJurisdiction !== '') {
      const params = {
        jurisdiction: selectedJurisdiction,
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          eligibilityRulesFetch(params)
            .then((response) => {
              setEligibilityConfig(response);
              const jurisdictionRules = response[selectedJurisdiction];
              setJurisdictionRules(jurisdictionRules);
              const electionTypes = Object.keys(jurisdictionRules);
              setElectionTypesList(electionTypes);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  }, [selectedJurisdiction]);

  useEffect(() => {
    if (selectedElectionType && jurisdictionRules) {
      setSelectedAgeRequirement(
        jurisdictionRules[selectedElectionType]['age'].toString()
      );
      setSelectedFlags(
        jurisdictionRules[selectedElectionType]['flags'].toString()
      );
      setVoteridRequired(
        jurisdictionRules[selectedElectionType]['voterIdRequired']
      );
      setEnforcementDate(
        dayjs(
          jurisdictionRules[selectedElectionType]['enforcementDate']
        ).format('YYYY-MM-DD')
      );
    }
  }, [selectedElectionType, jurisdictionRules, selectedJurisdiction]);

  const jurisdictionOptions: DropDownOption[] = [
    {
      display: '',
      value: '',
    },
    {
      display: 'England',
      value: 'England',
    },
    {
      display: 'Wales',
      value: 'Wales',
    },
    {
      display: 'Northern Ireland',
      value: 'Northern Ireland',
    },
    {
      display: 'Scotland',
      value: 'Scotland',
    },
  ];

  const electionTypesOptions: RadioOption[] = useMemo(() => {
    return [
      ...electionTypesList.map((et) => ({
        value: et,
        label: et,
      })),
    ];
  }, [electionTypesList, selectedJurisdiction]);

  const deleteElectionType = (electionType: string) => {
    const updatedElectionTypes = electionTypesList.filter(
      (option) => option !== electionType
    );
    setElectionTypesList(updatedElectionTypes);
    const updatedConfig = eligibilityConfig;
    if (updatedConfig) delete updatedConfig[selectedJurisdiction][electionType];
    setEligibilityConfig(updatedConfig);
    setSelectedElectionType('');
  };

  const addElectionType = () => {
    if (!electionTypesList.includes(newElectionType)) {
      setElectionTypesList([...electionTypesList, newElectionType]);
      // ToDo: Default these to local election rules when we know them
      const newRule = {
        age: 18,
        flags: ['#', 'A', 'D', 'E', 'F', 'M', 'U'],
        voterIdRequired: false,
        enforcementDate: dayjs().format('YYYY-MM-DD'),
      };
      const updatedConfig = eligibilityConfig;
      if (updatedConfig)
        updatedConfig[selectedJurisdiction][newElectionType] = newRule;
      setEligibilityConfig(updatedConfig);
      setShowAdd(false);
      setNewElectionType('');
      setSelectedElectionType('');
    } else {
      setElectionTypeIsValid(false);
    }
  };

  const cancelAddElectionType = () => {
    setNewElectionType('');
    setShowAdd(false);
  };

  return (
    <MDModal
      id={`${title}-modal`}
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          {jurisdictionOptions && jurisdictionOptions.length > 0 && (
            <DropDown
              disabled={loading}
              options={jurisdictionOptions}
              name="Jurisdiction"
              label="Jurisdiction"
              value={selectedJurisdiction}
              onSelect={(value) => setSelectedJurisdiction(value)}
              fullWidth
            />
          )}
          {selectedJurisdiction !== '' &&
            electionTypesOptions &&
            electionTypesOptions.length > 0 && (
              <>
                <ElectionTypeRadioGroup
                  title="Election Types"
                  selectedValue={selectedElectionType}
                  setValue={setSelectedElectionType}
                  options={electionTypesOptions}
                  fullWidth
                  onDelete={deleteElectionType}
                />
                {showAdd && (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <TextField
                      disabled={loading}
                      error={!electionTypeIsValid}
                      helperText={
                        !electionTypeIsValid
                          ? 'Election Type already exists'
                          : ''
                      }
                      label="My new election type"
                      name="My new election type"
                      variant="outlined"
                      margin="normal"
                      value={newElectionType}
                      onChange={(e) => setNewElectionType(e.target.value)}
                      inputProps={{ maxLength: 50 }}
                    />

                    <Button
                      id="addBtn"
                      variant="contained"
                      color="primary"
                      onClick={addElectionType}
                      disabled={newElectionType === ''}
                      size={'small'}
                      style={{ margin: '20px' }}
                    >
                      Add
                    </Button>

                    <Button
                      id="cancelBtn"
                      variant="contained"
                      color="primary"
                      onClick={cancelAddElectionType}
                      size={'small'}
                      style={{ margin: '20px' }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!showAdd && (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Button
                      id={'addElectionType'}
                      onClick={() => {
                        setShowAdd(true);
                        setSelectedElectionType('');
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                )}
              </>
            )}
          {selectedElectionType && (
            <>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                <DropDown
                  disabled={loading}
                  options={[
                    { display: '16', value: '16' },
                    { display: '18', value: '18' },
                  ]}
                  name="Age Requirement"
                  label="Age Requirement"
                  value={selectedAgeRequirement}
                  onSelect={(value) => setSelectedAgeRequirement(value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                <TextField
                  disabled={loading}
                  label="Ineligible elector flags"
                  name="Ineligible elector flags"
                  variant="outlined"
                  margin="normal"
                  value={selectedFlags}
                  onChange={(e) => setSelectedFlags(e.target.value)}
                  fullWidth
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={voteridRequired}
                      onChange={handleVoteridRequiredChange}
                    />
                  }
                  label="Voter ID is required"
                />
              </FormGroup>
              {voteridRequired && (
                <div style={{ paddingTop: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Enforcement Date"
                      inputFormat="DD/MM/YYYY"
                      value={enforcementDate}
                      onChange={handleEnforcementDateChange}
                      renderInput={(params: TextFieldProps) => (
                        <TextField {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </>
          )}
        </>
      }
      actionBar={
        <Button
          id="runTaskBtn"
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default ChangeEligibilityRulesModal;
