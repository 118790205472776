import { createQuery } from 'react-query-kit';
import tenantApi from '../api/tenants';
import { type DataValidationTenant } from '../model/tenant';

export const apiPath = '/dashboard/tenants';

/**
 * useTenants.
 * @description CreateQuery wrapper around tenantApi.getTenantList();.
 * @returns Query for DataValidationTenant[].
 */
export const useTenants = createQuery<DataValidationTenant[], void, Error>(
  apiPath,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ queryKey: [url] }) => {
    const result = await tenantApi.getTenantList();
    return result.data;
  }
);

export default useTenants;
