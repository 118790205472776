import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CnlCheckResults } from '../../../entities/CnlResults';
import { AddCircle, CheckCircle } from '@mui/icons-material';
import { sortAlphaNumeric } from '../../../utils/utils';

const statusCheck = (showCheck: boolean) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    {showCheck ? (
      <CheckCircle style={{ color: 'forestgreen' }} />
    ) : (
      // eslint-disable-next-line react/jsx-no-undef
      <AddCircle style={{ transform: 'rotate(45deg)', color: 'red' }} />
    )}
  </div>
);

const CnlCheckerResultsTable: React.FC<any> = (
  headers: string[],
  jsonData: CnlCheckResults
) => {
  const entries = Object.values(jsonData);
  entries.sort((a, b) => sortAlphaNumeric(a.stationNumber, b.stationNumber));

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <TableContainer style={containerStyle}>
      <Table style={containerStyle}>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
            <>
              <TableRow key={`${entry.stationNumber}_${index}`}>
                <TableCell key={`${index}_Station_${entry.stationNumber}`}>
                  {entry.stationNumber}
                </TableCell>
                <TableCell key={`${index}_CnlData_${entry.stationNumber}`}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          id={`${index}_typeDescription`}
                          style={{ width: '15%', fontWeight: 'bold' }}
                        >
                          Type Description
                        </TableCell>
                        <TableCell
                          id={`${index}_areaName`}
                          style={{ width: '15%', fontWeight: 'bold' }}
                        >
                          Area Name
                        </TableCell>
                        <TableCell
                          id={`${index}_uim`}
                          style={{ width: '15%', fontWeight: 'bold' }}
                        >
                          UIM
                        </TableCell>
                        <TableCell
                          id={`${index}_fromRange`}
                          style={{ width: '15%', fontWeight: 'bold' }}
                        >
                          From Range
                        </TableCell>
                        <TableCell
                          id={`${index}_toRange`}
                          style={{ width: '15%', fontWeight: 'bold' }}
                        >
                          To Range
                        </TableCell>
                        <TableCell
                          id={`${index}_correctBallotRange`}
                          style={{ width: '20%', fontWeight: 'bold' }}
                        >
                          Correct Range
                        </TableCell>
                      </TableRow>
                      {entry.cnlData
                        .sort(
                          (
                            a: { typeDescription: string },
                            b: { typeDescription: string }
                          ) =>
                            sortAlphaNumeric(
                              a.typeDescription,
                              b.typeDescription
                            )
                        )
                        .map((cnl: any, indext: number) => (
                          <TableRow key={`${indext}_${cnl.typeDescription}`}>
                            <TableCell
                              id={`${index}_typeDescription`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {cnl.typeDescription}
                            </TableCell>
                            <TableCell
                              id={`${index}_areaName`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {cnl.areaName}
                            </TableCell>
                            <TableCell
                              id={`${index}_uim`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {cnl.uim}
                            </TableCell>
                            <TableCell
                              id={`${index}_fromRange`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {cnl.fromRange}
                            </TableCell>
                            <TableCell
                              id={`${index}_fromRange`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {cnl.toRange}
                            </TableCell>
                            <TableCell
                              id={`${index}_correctBallotRange`}
                              style={{ width: '20%', fontWeight: 'bold' }}
                            >
                              {statusCheck(cnl.correctBallotRange)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableHead>
                  </Table>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CnlCheckerResultsTable;
