import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { DropDown } from '../../shared/DropDown';
import { GenericModalProps } from '../../SupportTasks/GenericModalProps';
import { TenantContext } from '../../../context/TenantContext';
import { isValidUKNumber } from '../../../utils/utils';
import { createUser } from '../../../services/api/dashboard';
import MDModal from '../../shared/MDModal';

const CreateUserModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
}: GenericModalProps) => {
  const defaultUser = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isEM: false,
  };

  const { tenants, selectedTenant: contextTenant } = useContext(TenantContext);

  const [userDetails, setUserDetails] = useState(defaultUser);
  const [selectedTenant, setSelectedTenant] = useState<string>(
    contextTenant || ''
  );

  const [emChecked, setEmChecked] = useState<boolean>(false);
  const [smsIsValid, setSmsIsValid] = useState(true);

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFirstNameChange = (name: string) =>
    setUserDetails({ ...userDetails, firstName: name });

  const handleLastNameChange = (name: string) =>
    setUserDetails({ ...userDetails, lastName: name });

  const handleNumberChange = (phoneNumber: string) => {
    if (
      (phoneNumber.startsWith('0') && phoneNumber.length <= 11) ||
      (phoneNumber.startsWith('+') && phoneNumber.length <= 13) ||
      phoneNumber === ''
    ) {
      setUserDetails({ ...userDetails, phoneNumber });
    }
  };

  const handleRun = async () => {
    try {
      setLoading(true);
      await createUser({
        ...userDetails,
        tenant: selectedTenant,
        isEM: emChecked,
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error?.message || error?.error);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      userDetails?.firstName &&
      userDetails?.lastName &&
      userDetails?.phoneNumber &&
      smsIsValid
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [userDetails, smsIsValid]);

  useEffect(() => {
    setSmsIsValid(
      userDetails?.phoneNumber.length > 0
        ? isValidUKNumber(userDetails?.phoneNumber)
        : true
    );
  }, [userDetails]);

  const tenantNames: string[] = useMemo(() => ['', ...tenants], [tenants]);

  return (
    <MDModal
      title={'Create User'}
      open={open}
      onClose={onClose}
      loading={loading}
      errorMessage={errorMessage}
      success={success}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <DropDown
            name="Select Tenant"
            label="Select Tenant"
            disabled={loading}
            options={tenantNames}
            value={selectedTenant}
            onSelect={setSelectedTenant}
            fullWidth
          />
          {selectedTenant && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setEmChecked(e.target.checked)}
                    color={'primary'}
                    checked={emChecked}
                  />
                }
                label={'Election Manager'}
              />
              <TextField
                label={'First Name'}
                value={userDetails.firstName || ''}
                onChange={({ target }) => handleFirstNameChange(target.value)}
                variant="standard"
              />
              <TextField
                label={'Last Name'}
                value={userDetails.lastName || ''}
                onChange={({ target }) => handleLastNameChange(target.value)}
                variant="standard"
              />
              <TextField
                label={'Phone Number'}
                value={userDetails.phoneNumber || ''}
                onChange={({ target }) => handleNumberChange(target.value)}
                error={!smsIsValid}
                helperText={'Must match:\n0XXXXXXXXX or +44XXXXXXXXXX'}
                variant="standard"
              />
            </>
          )}
        </div>
      }
      actionBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!submitEnabled || success}
            onClick={handleRun}
          >
            Create
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(CreateUserModal);
