import React from 'react';
import {
  BatteryFull,
  Battery90,
  Battery80,
  Battery60,
  Battery50,
  Battery30,
  Battery20,
  BatteryAlert,
} from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BatteryIcon = (props: any) => {
  const { batteryLevel, online }: { batteryLevel: string; online: string } =
    props;
  let icon;
  const battery = parseFloat(batteryLevel);
  if (battery > 0.9) {
    icon = (
      <BatteryFull
        fontSize="small"
        style={{ color: online ? 'forestgreen' : 'gray' }}
      />
    );
  } else if (battery > 0.8) {
    icon = (
      <Battery90
        fontSize="small"
        style={{ color: online ? 'forestgreen' : 'gray' }}
      />
    );
  } else if (battery > 0.6) {
    icon = (
      <Battery80
        fontSize="small"
        style={{ color: online ? 'forestgreen' : 'gray' }}
      />
    );
  } else if (battery > 0.5) {
    icon = (
      <Battery60
        fontSize="small"
        style={{ color: online ? 'forestgreen' : 'gray' }}
      />
    );
  } else if (battery > 0.3) {
    icon = (
      <Battery50 fontSize="small" style={{ color: online ? 'gold' : 'gray' }} />
    );
  } else if (battery > 0.2) {
    icon = (
      <Battery30 fontSize="small" style={{ color: online ? 'gold' : 'gray' }} />
    );
  } else if (battery > 0.1) {
    icon = (
      <Battery20 fontSize="small" style={{ color: online ? 'red' : 'gray' }} />
    );
  } else {
    icon = (
      <BatteryAlert
        fontSize="small"
        style={{ color: online ? 'red' : 'gray' }}
      />
    );
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      {`${(Number(batteryLevel) * 100).toFixed(0)}%`}
    </div>
  );
};

export default React.memo(BatteryIcon);
