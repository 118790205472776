import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, TextField } from '@mui/material';
import '../../../../styles.css';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { DropDown, DropDownOption } from '../../../shared/DropDown';
import {
  getBallotRef,
  getElectionName,
  updateBallotRef,
} from '../../../../services/api/support';
import { TenantContext } from '../../../../context/TenantContext';
import { sortAlphaNumeric } from '../../../../utils/utils';

interface UpdateBallotRefModalProps extends GenericModalProps {
  title: string;
}
const UpdateBallotRefModal: React.FC<UpdateBallotRefModalProps> = ({
  open,
  onClose,
  title,
}: UpdateBallotRefModalProps) => {
  const { tenants, tenantDetails } = useContext(TenantContext);
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [selectedElectionDate, setSelectedElectionDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [electionName, setElectionName] = useState<string>('');
  const [selectedElection, setElections] = useState<object>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updatedRef, setUpdatedRef] = useState<string>('');

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  const electionNameList: DropDownOption[] = useMemo(() => {
    if (selectedElection) {
      const elections = [{ value: '', display: '' }];
      Object.entries(selectedElection).map((election) => {
        const option = {
          value: `${election[1].electionkey}`,
          display: `${election[1].electionkey} - ${election[1].name}`,
        };
        if (!elections.includes(option)) {
          elections.push(option);
        }
      });
      return elections;
    } else return [];
  }, [selectedElection]);

  const electionDates: string[] = useMemo(() => {
    if (selectedTenant) {
      const dates =
        tenantDetails.find((t) => t.tenant === selectedTenant)?.electionDates ||
        [];
      return dates.sort(sortAlphaNumeric);
    } else {
      return [];
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant && selectedElectionDate) {
      const params = {
        tenant: selectedTenant || '',
        electionDate: selectedElectionDate || '',
      };
      Promise.resolve(setLoading(true)).then(() => {
        getElectionName(params)
          .then((response) => {
            setElections(response['result']['elections']);
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
          .finally(() => setLoading(false));
      });
    }
  }, [selectedTenant, selectedElectionDate]);

  useEffect(() => {
    if (selectedTenant && selectedElectionDate && electionName) {
      const params = {
        tenant: selectedTenant || '',
        electionDate: selectedElectionDate || '',
        electionName: electionName,
      };
      Promise.resolve(setLoading(true)).then(() =>
        getBallotRef(params)
          .then((response) => {
            setUpdatedRef(response['result'].elections[electionName].ballotRef);
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
          .finally(() => setLoading(false))
      );
    }
  }, [selectedTenant, selectedElectionDate, electionName]);

  useEffect(() => {
    setElectionName('');
  }, [selectedTenant, selectedElectionDate]);

  const updateRef = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedRef(event.target.value);
  };

  const onSubmit = async () => {
    const params = {
      tenant: selectedTenant || '',
      electionDate: selectedElectionDate || '',
      electionName: electionName,
      ballotRef: updatedRef,
    };
    Promise.resolve(setLoading(true))
      .then(() =>
        updateBallotRef(params).catch((error) => {
          setErrorMessage(error.message);
          setTimeout(() => setErrorMessage(''), 5000);
        })
      )
      .then(() => setLoading(false))
      .finally(() => {
        setSelectedElectionDate('');
        setSelectedTenant('');
      });
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      width={750}
      loading={loading}
      errorMessage={errorMessage}
      content={
        <>
          <>
            <DropDown
              disabled={loading}
              options={tenantNames}
              name="Tenants"
              label="Tenants"
              value={selectedTenant || ''}
              onSelect={setSelectedTenant}
              fullWidth
            />
            <DropDown
              disabled={loading || !selectedTenant}
              options={electionDates}
              name="Election Date"
              label="Date"
              value={selectedElectionDate || ''}
              onSelect={setSelectedElectionDate}
              fullWidth
            />
            <DropDown
              disabled={loading || !selectedElectionDate}
              options={electionNameList}
              label="Election Name"
              name="Election Name"
              value={electionName || ''}
              onSelect={(name) => {
                setElectionName(name);
              }}
              fullWidth
            />
            {electionName && (
              <TextField
                id="updateRef"
                label="Update the Ballot Ref"
                variant="outlined"
                margin="normal"
                value={updatedRef}
                onChange={updateRef}
                fullWidth
              />
            )}
          </>
        </>
      }
      actionBar={
        <Button
          disabled={!updatedRef}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default UpdateBallotRefModal;
