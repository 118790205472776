import { ActionMap } from '../../ActionMap';

export enum ActionTypes {
  clearScrubbingData = 'CLEAR_SCRUBBING_DATA',
  loadScrubbedSignedUrls = 'LOAD_SCRUBBED_SIGNED_URLS',
  loadScrubbedSignedUrlsFailure = 'LOAD_SCRUBBED_SIGNED_URLS_FAILURE',
  loadScrubbedSignedUrlsSuccess = 'LOAD_SCRUBBED_SIGNED_URLS_SUCCESS',
}

type PayloadType = {
  [ActionTypes.loadScrubbedSignedUrls]: {
    tenant: string;
  };
  [ActionTypes.loadScrubbedSignedUrlsSuccess]: {
    tenant: string;
    elections: string[];
    signedUrls: string[];
  };
  [ActionTypes.loadScrubbedSignedUrlsFailure]: {
    error: unknown;
  };
};

export type DataScrubbingActions =
  ActionMap<PayloadType>[keyof ActionMap<PayloadType>];

export const loadScrubbedUrls = (queryKey: string) => ({
  type: ActionTypes.loadScrubbedSignedUrls,
  payload: { queryKey: queryKey },
});

export const loadScrubbedUrlsSuccess = (
  tenant: string,
  signedUrls: string[]
) => ({
  type: ActionTypes.loadScrubbedSignedUrlsSuccess,
  payload: { tenant: tenant, signedUrls: signedUrls },
});

export const loadScrubbedUrlsError = (error: unknown) => ({
  type: ActionTypes.loadScrubbedSignedUrlsFailure,
  payload: { error: error },
});
// tenant.elections
// tenant.election[electionDate] => Record<string, string>
export type ElectionDateType = string;
export type FileType = 'ballots' | 'proxy' | 'register' | 'staff';
export type RedactedUrlType = string;

export type ScrubbedUrlsType = Record<
  string,
  Record<ElectionDateType, Record<FileType, RedactedUrlType>>
> & { error?: string | undefined };
/**
 * ScrubbedUrlsType.
 * @description Record<tenant, Record<electionDate, Record<fileType, url>>>
 */
export type ScrubbedUrlsType2 = Record<
  string,
  Record<string, Record<string, string>>
> & { error?: string | undefined };
// tenant.elections[election][proxy]
// { 'tenant': string, elections: election[],}
export const INITIAL_STATE: ScrubbedUrlsType = {};

export const dataScrubbingReducer = (
  state = INITIAL_STATE,
  action: DataScrubbingActions
) => {
  const cloneState = structuredClone(state);

  switch (action.type) {
    case ActionTypes.loadScrubbedSignedUrls: {
      cloneState.loading = true;
      return cloneState;
    }
    case ActionTypes.loadScrubbedSignedUrlsFailure: {
      const { error } = action.payload;
      cloneState.error = error;
      return cloneState;
    }
    case ActionTypes.loadScrubbedSignedUrlsSuccess: {
      const { tenant, signedUrls } = action.payload;

      // split the signedUrls and determine electionDate and fileType
      const tenantData = signedUrls.map((url) => {
        // 9 inc https, "", bucket
        const metadata = url.split('/');
        // const tenant = metadata[3];
        const urlElection = metadata[4];
        const fileType = metadata[5];
        return { urlElection, fileType, url };
      });

      tenantData.forEach((td) => {
        if (cloneState[tenant] === undefined) cloneState[tenant] = {};
        if (cloneState[tenant][td.urlElection] === undefined)
          cloneState[tenant][td.urlElection] = {};
        if (cloneState[tenant][td.urlElection][td.fileType] === undefined)
          cloneState[tenant][td.urlElection][td.fileType] = {};
        cloneState[tenant][td.urlElection][td.fileType] = td.url;
      });

      return cloneState;
    }
    default:
      return state;
  }
};
