import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button, TextField } from '@mui/material';
import { DDBElector } from '../../../../entities/DynamoObjects';
import { GetElectorsRequest } from '../../../../entities/Support';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import { markElector } from '../../../../services/api/support';
import '../../../../styles.css';
import { DropDown, DropDownOption } from '../../../shared/DropDown';
import { BallotInfo, BallotInfoProps } from '../../../shared/BallotInfo';
import { ElectorInfo, ElectorInfoProps } from '../../../shared/ElectorInfo';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import TenantPollstationPicker from './TenantPollstationPicker';
import { sortAlphaNumeric } from '../../../../utils/utils';

interface ElectorModalProps extends GenericModalProps {
  electorFetch: (params: GetElectorsRequest) => Promise<DDBElector[]>;
  title: string;
  isProxy?: boolean;
  isClericalAdditions?: boolean;
}

const MarkElectorModal: React.FC<ElectorModalProps> = ({
  open,
  onClose,
  electorFetch,
  title,
  isProxy = false,
  isClericalAdditions,
}: ElectorModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  let electorLbl = 'Electors';
  if (isProxy) electorLbl = 'Electors with Proxies';
  if (isClericalAdditions) electorLbl = 'Clerical Additions';

  const [electorObjectList, setElectorObjects] = useState<DDBElector[]>([]);
  const [ballotInfo, setBallotInfo] = useState<BallotInfoProps>({
    uim: '',
    start: '',
    end: '',
    colour: '',
  });

  const [selectedElector, setSelectedElector] = useState<string>('');
  const [electorInfo, setElectorInfo] = useState<ElectorInfoProps>({
    electorId: '',
    name: '',
    address: {},
    proxyName: undefined,
    proxyAddress: undefined,
  });

  const [selectedElection, setSelectedElection] = useState<string>('');
  const [selectedElectionKey, setSelectedElectionKey] = useState<string>('');
  const [elections, setElections] = useState<string[]>([]);
  const [selectedUIM, setSelectedUIM] = useState<string>('');
  const [selectedBallotNum, setSelectedBallotNum] = useState<string>('');

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = async () => {
    const data = {
      tenant: selectedTenant || '',
      electionDate: selectedElectionDate || '',
      stationId: selectedPollstation?.pollStationId || '',
      electorId: selectedElector.split(' - ')[0],
      electionKey: selectedElectionKey,
      uim: selectedUIM.split(' - ')[0],
      ballotNum: selectedBallotNum,
      proxyVoted: `${isProxy}`,
    };
    return Promise.resolve(setLoading(true))
      .then(() =>
        markElector(data)
          .then(() => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 5000);
          })
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedTenant && selectedElectionDate && selectedPollstation) {
      const params = {
        tenant: selectedTenant,
        electionDate: selectedElectionDate,
        stationId: selectedPollstation?.pollStationId || '',
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          electorFetch(params)
            .then(setElectorObjects)
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setElectorObjects([]);
              setSelectedElector('');
              setSelectedElection('');
              setSelectedUIM('');
              setSelectedBallotNum('');
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  }, [selectedTenant, selectedElectionDate, selectedPollstation]);

  const electorOptions: DropDownOption[] = useMemo(() => {
    return [
      { value: '', display: '' },
      ...electorObjectList
        .map((el) => ({
          display: `${el.uniqueIdentifier} - ${el?.firstName} ${el?.lastName}`,
          value: el.uniqueIdentifier,
        }))
        .sort((a, b) => sortAlphaNumeric(a.value, b.value)),
    ];
  }, [electorObjectList, selectedElectionDate, selectedPollstation]);

  useEffect(() => {
    if (selectedElector) {
      const elector = electorObjectList.find((elector) =>
        selectedElector.includes(elector.uniqueIdentifier)
      );

      if (elector) {
        const elections = elector.franchise
          .map((f) => `${f.election} - ${f.electionName}`)
          .sort(sortAlphaNumeric);

        setElectorInfo({
          electorId: elector.uniqueIdentifier,
          name: `${elector.firstName} ${elector.lastName}`,
          address: elector.registeredAddress || {},
          proxyName: elector.proxyName,
          proxyAddress: elector.proxyAddress || {},
        });

        setElections(['', ...elections]);
      }
    }
  }, [selectedElector]);

  const uims = useMemo(() => {
    if (selectedPollstation && selectedElection) {
      const electionKey = selectedElection.split(' - ')[0];
      return [
        '',
        ...Object.entries(selectedPollstation.ballots[electionKey])
          .map(([areaKey, area]) =>
            area.ordinary.numbers.map((num) => `${num.uimPrefix} - ${areaKey}`)
          )
          .flat(2),
      ];
    } else {
      return [];
    }
  }, [selectedPollstation, selectedElection]);

  useEffect(() => {
    setSelectedElectionKey(selectedElection.split(' - ')[0]);
  }, [selectedPollstation, selectedElection]);

  useEffect(() => {
    setSelectedUIM('');
    setSelectedElector('');
  }, [selectedPollstation]);

  useEffect(() => {
    if (selectedPollstation && selectedUIM) {
      const [localUim, area] = selectedUIM.split(' - ');
      const elections = Object.values(selectedPollstation.ballots);

      elections.forEach((election) => {
        const elArea = election[area];
        if (elArea?.ordinary.numbers[0].uimPrefix === localUim) {
          const prefix = elArea.ordinary.firstBallot.includes('-') ? '-' : '';

          setBallotInfo({
            uim: localUim,
            start: `${prefix}${String(elArea.ordinary.numbers[0].from)}`,
            end: `${prefix}${String(elArea.ordinary.numbers[0].to)}`,
            colour: elArea.ordinary.colour,
          });
        }
      });
    }
  }, [selectedUIM, selectedPollstation]);

  useEffect(() => {
    setSubmitEnabled(selectedUIM && selectedBallotNum ? true : false);
  }, [selectedUIM, selectedBallotNum]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <TenantPollstationPicker />
          {selectedPollstation && (
            <DropDown
              disabled={loading}
              options={electorOptions}
              name="Electors"
              label={electorLbl}
              value={selectedElector}
              onSelect={(value) => setSelectedElector(value)}
              fullWidth
            />
          )}
          {selectedElector && electorOptions.length > 0 && (
            <>
              <ElectorInfo
                electorId={electorInfo.electorId}
                name={electorInfo.name}
                address={electorInfo.address}
                proxyName={electorInfo.proxyName}
                proxyAddress={electorInfo.proxyAddress}
                isProxy={isProxy}
              />
              <DropDown
                disabled={loading}
                options={elections}
                name="Election"
                label="Election"
                value={selectedElection}
                onSelect={(value) => setSelectedElection(value)}
                fullWidth
              />
            </>
          )}
          {selectedElection && selectedElector && electorOptions.length > 0 && (
            <DropDown
              disabled={loading}
              options={uims}
              name="UIM"
              label="UIM"
              value={selectedUIM}
              onSelect={(value) => setSelectedUIM(value)}
              fullWidth
            />
          )}
          {selectedUIM && selectedElector && electorOptions.length > 0 && (
            <div style={{ margin: '2rem 0' }}>
              <BallotInfo
                uim={ballotInfo.uim}
                start={ballotInfo.start}
                end={ballotInfo.end}
                colour={ballotInfo.colour}
              />
              <TextField
                disabled={loading}
                id="ballotPaperId"
                label="Ballot Number"
                variant="outlined"
                margin="normal"
                fullWidth
                value={selectedBallotNum}
                onChange={(e) => setSelectedBallotNum(e.target.value)}
              />
            </div>
          )}
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default MarkElectorModal;
