// eslint-disable-next-line
export const noop = (): void => {};
import moment from 'moment-timezone';

import { Pollstation, OverallTurnout } from '../entities/Pollstation';
import { AlarmStatus } from '../entities/AlarmStatus';

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const setDefaultTurnoutStats = () => ({
  '6': 0,
  '7': 0,
  '8': 0,
  '9': 0,
  '10': 0,
  '11': 0,
  '12': 0,
  '13': 0,
  '14': 0,
  '15': 0,
  '16': 0,
  '17': 0,
  '18': 0,
  '19': 0,
  '20': 0,
  '21': 0,
  '22': 0,
  '23': 0,
});

export const sortAlphaNumeric = (a: string, b: string): number => {
  if (a) {
    return a.localeCompare(b, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    });
  } else {
    return 0;
  }
};

export const isValidUKNumber = (number: string): boolean => {
  const UKregEx =
    /^((\+44(\(0\)\s|\s0\s|\s)?)|(\+353(\(0\)\s|\s0\s|\s)?)|0)7\d{9}$/gi;
  const valid = UKregEx.test(number);
  return valid;
};

export const normaliseUKPhoneNumberWithPrefix = (number: string): string => {
  let digits = '';
  let prefix = '';

  ['+44', '+353'].forEach((code) => {
    if (number.startsWith(code)) {
      prefix = code;
      digits = number.split(code)[1];
    }
  });

  const noSpaces = digits.replaceAll(/[^0-9]/gi, '');
  return noSpaces.startsWith('0')
    ? `${prefix}${noSpaces.slice(1)}`
    : `${prefix}${noSpaces}`;
};

export const normaliseUKPhoneNumber = (number: string): string => {
  const noSpaces = number.replaceAll(/[^0-9]/gi, '');
  if (noSpaces.startsWith('0')) {
    if (noSpaces.length > 1) {
      return `7${noSpaces.slice(1)}`;
    } else {
      return '';
    }
  } else {
    return noSpaces;
  }
};

export const getUpcomingDate = (dates: string[]): string => {
  let upcomingDate = dates[0];
  const now = moment.utc(Date.now()).tz(TIME_ZONE);
  for (const date of dates) {
    const momentDate = moment(date);
    if (momentDate.isSame(now, 'day')) {
      upcomingDate = date;
      break;
    }
    if (momentDate.isSameOrAfter(now, 'day')) {
      upcomingDate = date;
      break;
    }
  }
  return upcomingDate;
};

export const noContactInLastHour = (station: Pollstation): boolean => {
  return station.devices.some((device) => {
    const now = moment.utc(Date.now()).tz(TIME_ZONE);
    const lastStamp = moment.utc(device.lastTimestamp).tz(TIME_ZONE);
    const duration = moment.duration(now.diff(lastStamp, 'hours'));
    return duration.asHours() >= 1;
  });
};

export const hasExpiredTokens = (station: Pollstation): boolean => {
  return station.devices.some((d) =>
    moment.utc(d.tokenExpiry).tz(TIME_ZONE).isBefore()
  );
};

export const hasVotesCast = (station: Pollstation): boolean => {
  let votesCast = false;
  for (const election of Object.values(station.ballots)) {
    for (const area of Object.values(election)) {
      if (area.ordinary.totalIssued > 0) votesCast = true;
      if (votesCast) break;
    }
    if (votesCast) break;
  }
  return votesCast;
};

export const noBPASubmitted = (station: Pollstation): boolean => {
  let filter = false;
  for (const election of Object.values(station.ballots)) {
    for (const ballot of Object.values(election)) {
      if (!ballot.bpaSubmitTime) filter = true;
    }
  }
  return filter;
};

export const byPrefix = (file: string, term: string): boolean => {
  return file.split('|')[0].startsWith(term);
};

export const hasAllExpeditedReports = (station: Pollstation): boolean => {
  if (station.reports) {
    const noOfElections = Object.keys(station.ballots).length;
    const reports = station.reports;

    let bpa = 0;
    let videf = 0;
    for (const report of reports) {
      if (byPrefix(report, 'bpa')) bpa++;
      if (byPrefix(report, 'videf')) videf++;
    }
    const allBPAs = bpa >= noOfElections;
    const allVidef = videf >= noOfElections;

    return (allBPAs && allVidef) || false;
  } else {
    return false;
  }
};

export const hasAllReports = (station: Pollstation): boolean => {
  if (station.reports) {
    const noOfElections = Object.keys(station.ballots).length;
    const reports = station.reports;

    let register = 0;
    let proxy = 0;
    let cnl = 0;
    let bpa = 0;
    let videf = 0;
    let voterStats = 0;
    let bprl = 0;

    for (const report of reports) {
      if (byPrefix(report, 'markedregister')) register++;
      if (byPrefix(report, 'markedproxy')) proxy++;
      if (byPrefix(report, 'corresponding')) cnl++;
      if (byPrefix(report, 'bpa')) bpa++;
      if (byPrefix(report, 'videf')) videf++;
      if (byPrefix(report, 'voteridstatistics')) voterStats++;
      if (byPrefix(report, 'ballotpaperefusallist')) bprl++;
    }

    const allRegs = register >= 2 * noOfElections; //2 reports for each election (csv + pdf)
    const allProx = proxy >= 2 * noOfElections;
    const allCNLs = cnl >= 1 * noOfElections;
    const allBPAs = bpa >= noOfElections;
    const allVidef = videf >= noOfElections;
    const allStats = voterStats >= noOfElections;
    const allBprl = bprl >= noOfElections;

    return (
      (allRegs &&
        allProx &&
        allCNLs &&
        allBPAs &&
        allVidef &&
        allStats &&
        allBprl) ||
      false
    );
  } else {
    return false;
  }
};

export const calculateTurnoutForElections = (
  pollStations: Pollstation[],
  date: string
): OverallTurnout => {
  const overall: OverallTurnout = {};

  for (const station of pollStations) {
    for (const [elKey, election] of Object.entries(station?.ballots || {})) {
      if (!(elKey in overall)) {
        overall[elKey] = {
          electionName: '',
          values: setDefaultTurnoutStats(),
          totalTurnout: 0,
          totalEligible: 0,
          maxValue: 0,
          totalAnonymousVoters: 0,
          totalPostalProxyVoters: 0,
          totalPostalVoters: 0,
          totalProxyVoters: 0,
          totalVoters: 0,
        };
      }

      for (const area of Object.values(election)) {
        for (const [hour, turnout] of Object.entries(
          area?.ordinary?.turnOutByHour || {}
        )) {
          const localHour = moment
            .utc(`${date} ${hour.padStart(2, '0')}:00`)
            .tz(TIME_ZONE)
            .format('H');

          if (!(localHour in overall[elKey].values)) {
            overall[elKey]['values'][localHour] = 0;
          }

          overall[elKey]['values'][localHour] += turnout;
          overall[elKey].totalTurnout += turnout;

          const newturnout = overall[elKey]['values'][localHour];
          if (newturnout > overall[elKey].maxValue) {
            overall[elKey].maxValue = newturnout * 1.1;
          }
        }
      }
    }

    for (const [elKey, election] of Object.entries(station?.voters || {})) {
      for (const area of Object.keys(election)) {
        if (!(elKey in overall)) {
          overall[elKey] = {
            electionName: '',
            values: setDefaultTurnoutStats(),
            totalTurnout: 0,
            totalEligible: 0,
            maxValue: 0,
            totalAnonymousVoters: 0,
            totalPostalProxyVoters: 0,
            totalPostalVoters: 0,
            totalProxyVoters: 0,
            totalVoters: 0,
          };
        }
        overall[elKey].electionName = election[area].electionname;
        overall[elKey].totalEligible += election[area].eligible;
        overall[elKey].totalAnonymousVoters +=
          election[area].totalanonymousvoters;
        overall[elKey].totalPostalVoters += election[area].totalpostalvoters;
        overall[elKey].totalProxyVoters += election[area].totalproxyvoters;
        overall[elKey].totalVoters += election[area].totalvoters;
      }
    }
  }

  for (const [elKey, election] of Object.entries(overall)) {
    const mapped = Object.entries(election.values).map(([hour, turnout]) => ({
      x: hour,
      y: turnout,
    }));
    overall[elKey].values = mapped;
  }

  return overall;
};

export const calculateTurnoutForStation = (
  pollStation: Pollstation,
  date: string
): OverallTurnout => {
  const overall: OverallTurnout = {};
  for (const [elKey, election] of Object.entries(pollStation?.ballots || {})) {
    if (!(elKey in overall)) {
      overall[elKey] = {
        electionName: '',
        values: setDefaultTurnoutStats(),
        totalTurnout: 0,
        totalEligible: 0,
        maxValue: 0,
        totalAnonymousVoters: 0,
        totalPostalProxyVoters: 0,
        totalPostalVoters: 0,
        totalProxyVoters: 0,
        totalVoters: 0,
      };
    }

    for (const area of Object.values(election)) {
      for (const [hour, turnout] of Object.entries(
        area?.ordinary?.turnOutByHour || {}
      )) {
        const localHour = moment
          .utc(`${date} ${hour.padStart(2, '0')}:00`)
          .tz(TIME_ZONE)
          .format('H');

        overall[elKey]['values'][localHour] += turnout;
        overall[elKey].totalTurnout += turnout;

        const newturnout = overall[elKey]['values'][localHour];
        if (newturnout > overall[elKey].maxValue) {
          overall[elKey].maxValue = newturnout * 1.1;
        }
      }
    }
  }

  for (const [elKey, election] of Object.entries(pollStation?.voters || {})) {
    for (const area of Object.keys(election)) {
      if (!(elKey in overall)) {
        overall[elKey] = {
          electionName: '',
          values: setDefaultTurnoutStats(),
          totalTurnout: 0,
          totalEligible: 0,
          maxValue: 0,
          totalAnonymousVoters: 0,
          totalPostalProxyVoters: 0,
          totalPostalVoters: 0,
          totalProxyVoters: 0,
          totalVoters: 0,
        };
      }
      overall[elKey].electionName = election[area].electionname;
      overall[elKey].totalEligible += election[area].eligible;
      overall[elKey].totalAnonymousVoters +=
        election[area].totalanonymousvoters;
      overall[elKey].totalPostalVoters += election[area].totalpostalvoters;
      overall[elKey].totalProxyVoters += election[area].totalproxyvoters;
      overall[elKey].totalVoters += election[area].totalvoters;
    }
  }

  for (const [elKey, election] of Object.entries(overall)) {
    const mapped = Object.entries(election.values).map(([hour, turnout]) => ({
      x: hour,
      y: turnout,
    }));
    overall[elKey].values = mapped;
  }
  return overall;
};

export const getLastOnlineTime = (unixStamp: number): number => {
  const lastOnlineDate = new Date(unixStamp * 1000);
  const lastOnlineTime = lastOnlineDate.getTime();
  return lastOnlineTime;
};

export const getDeviceStatus = (
  battery: string,
  unsynced: number,
  lastTimestamp: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  let overallStatus = AlarmStatus.none;
  let batteryStatus = AlarmStatus.none;
  let unsyncedStatus = AlarmStatus.none;

  const lastOnline = getLastOnlineTime(lastTimestamp);
  const fiveMinsAgo = Date.now() - 5 * 60 * 1000;

  const b = parseFloat(battery);
  if (b > 0.75) {
    batteryStatus = AlarmStatus.good;
  } else if (b > 0.25) {
    batteryStatus = AlarmStatus.alert;
  } else {
    batteryStatus = AlarmStatus.warning;
  }

  if (unsynced === 0) {
    unsyncedStatus = AlarmStatus.good;
  } else if (unsynced > 1 && unsynced <= 5) {
    unsyncedStatus = AlarmStatus.alert;
  } else if (unsynced >= 10) {
    unsyncedStatus = AlarmStatus.warning;
  }

  if (
    batteryStatus === AlarmStatus.good &&
    unsyncedStatus === AlarmStatus.good
  ) {
    overallStatus = AlarmStatus.good;
  } else {
    overallStatus = AlarmStatus.alert;
  }
  if (
    batteryStatus === AlarmStatus.warning ||
    unsyncedStatus === AlarmStatus.warning
  ) {
    overallStatus = AlarmStatus.warning;
  }

  if (lastOnline <= fiveMinsAgo) {
    if (overallStatus === AlarmStatus.alert) {
      overallStatus = AlarmStatus.warning;
    }
    if (overallStatus === AlarmStatus.good) {
      overallStatus = AlarmStatus.alert;
    }
  }
  return {
    overallStatus,
    batteryStatus,
    unsyncedStatus,
  };
};

export const csvExtension = (fileName: string): string => {
  return (fileName = fileName.includes('.tsv')
    ? fileName.replace('.tsv', '.csv')
    : fileName);
};
