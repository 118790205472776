import React, { createContext, useState, useEffect } from 'react';
import { DDBPollstation } from '../entities/DynamoObjects';
import { fetchPollstations } from '../services/api/support';
import { WithChildren } from '../types/WithChildren';
import { noop } from '../utils/utils';

export interface TenantPollstationContextI {
  selectedTenant?: string;
  setSelectedTenant: (newTenant?: string) => void;
  selectedPollstation?: DDBPollstation;
  setSelectedPollstation: (newPollstation?: string) => void;
  selectedElectionDate?: string;
  setSelectedElectionDate: (newDate?: string) => void;
  tenantPollstationObjects: DDBPollstation[];
  fetching?: boolean;
  resetForm: () => void;
}

export const TenantPollstationContext =
  createContext<TenantPollstationContextI>({
    setSelectedTenant: noop,
    setSelectedPollstation: noop,
    setSelectedElectionDate: noop,
    tenantPollstationObjects: [],
    resetForm: noop,
  } as TenantPollstationContextI);

export const TenantPollstationContextProvider: React.FC<WithChildren> = ({
  children,
}: WithChildren) => {
  const value = useTenantPollstationContext();
  return (
    <TenantPollstationContext.Provider value={value}>
      {children}
    </TenantPollstationContext.Provider>
  );
};

const useTenantPollstationContext = () => {
  const [selectedTenant, selectedTenantSet] = useState<string | undefined>();
  const [selectedPollstation, selectedPollstationSet] = useState<
    DDBPollstation | undefined
  >();
  const [selectedElectionDate, selectedElectionDateSet] = useState<
    string | undefined
  >();

  const [tenantPollstationObjects, tenantPollstationsSet] = useState<
    DDBPollstation[]
  >([]);

  const [fetching, fetchingSet] = useState(false);

  const fetchFromApi = () => {
    if (selectedTenant) {
      return fetchPollstations(selectedTenant).then(tenantPollstationsSet);
    }
  };

  useEffect(() => {
    selectedPollstationSet(undefined);
    selectedElectionDateSet(undefined);

    if (selectedTenant) {
      Promise.resolve(fetchingSet(true))
        .then(() => fetchFromApi())
        .finally(() => fetchingSet(false));
    } else {
      tenantPollstationsSet([]);
    }
  }, [selectedTenant]);

  const setSelectedElectionDate = (dateStr?: string) => {
    selectedPollstationSet(undefined);
    selectedElectionDateSet(dateStr);
  };

  const setSelectedPollstation = (id?: string) => {
    selectedPollstationSet(
      tenantPollstationObjects.find(({ pollStationId }) => pollStationId === id)
    );
  };

  const setSelectedTenant = (newTenant?: string) => {
    selectedElectionDateSet(undefined);
    selectedPollstationSet(undefined);
    selectedTenantSet(newTenant || '');
  };

  const resetForm = () => {
    selectedTenantSet(undefined);
  };

  return {
    selectedTenant,
    setSelectedTenant,
    selectedPollstation,
    setSelectedPollstation,
    selectedElectionDate,
    setSelectedElectionDate,
    tenantPollstationObjects,
    fetching,
    resetForm,
  };
};
