import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../../context/DashboardContext';
import { TenantContext } from '../../context/TenantContext';
import { AlarmStatus } from '../../entities/AlarmStatus';
import { SyncFilterStatus } from '../../entities/SyncStatus';
import { DropDown } from '../shared/DropDown';
import FilterBar from '../shared/FilterBar';
import './DeviceItemRow.scss';

const DeviceFilterBar: React.FC = () => {
  const {
    tenants,
    selectedTenant,
    setTenant,
    electionsForTenant,
    selectedElection,
    setElection,
  } = useContext(TenantContext);
  const { filters, updateFilter } = useContext(DashboardContext);
  const { overallStatus, syncFilter } = filters;

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  return (
    <FilterBar>
      <DropDown
        options={tenantNames}
        name="Tenant"
        label="Tenant"
        value={selectedTenant}
        onSelect={(value) => setTenant(value)}
      />
      <DropDown
        options={['', ...electionsForTenant]}
        name="Election Date"
        label="Election Date"
        value={selectedElection}
        onSelect={(value) => setElection(value)}
      />
      <DropDown
        options={Object.values(AlarmStatus)}
        name="Status"
        label="Status"
        value={overallStatus}
        onSelect={(value) =>
          updateFilter({ overallStatus: value as AlarmStatus })
        }
      />
      <DropDown
        options={Object.values(SyncFilterStatus)}
        name="Sync"
        label="Sync"
        value={syncFilter}
        onSelect={(value) =>
          updateFilter({ syncFilter: value as SyncFilterStatus })
        }
      />
    </FilterBar>
  );
};

export default React.memo(DeviceFilterBar);
