import React, { Fragment, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import TurnoutPerHour from '../shared/graphs/TurnoutPerHour';
import TurnoutOverall from '../shared/graphs/OverallTurnout';
import { OverallTurnout as TurnoutType } from '../../entities/Pollstation';
import { DashboardContext } from '../../context/DashboardContext';
import { calculateTurnoutForElections } from '../../utils/utils';
import { TenantContext } from '../../context/TenantContext';

const AggregateStatistics: React.FC = () => {
  const { selectedElection } = useContext(TenantContext);
  const { selectedStations } = useContext(DashboardContext);

  const overallTurnout: TurnoutType = useMemo(
    () =>
      calculateTurnoutForElections(selectedStations, selectedElection || ''),
    [selectedStations]
  );

  return (
    <div className="stats-section">
      <div>
        <Typography variant={'h5'}>Overview</Typography>
      </div>
      {Object.entries(overallTurnout).length ? (
        <div className="stats-content">
          {Object.entries(overallTurnout).map(([key, stats], index) => (
            <Fragment key={`${key}-${stats.electionName}`}>
              <div
                key={`${key}-${stats.electionName}`}
                className={stats.maxValue > 0 ? 'stats-row' : 'stats-row-empty'}
              >
                <div className="stats-graphs">
                  <div className="stats-totals">
                    <Typography
                      variant={'h6'}
                    >{`${key} (${stats.electionName})`}</Typography>
                    <Typography>{`Total Voters: ${stats.totalVoters}`}</Typography>
                    <Typography>{`Total Eligible: ${stats.totalEligible}`}</Typography>
                    <Typography>{`Total Proxies: ${stats.totalProxyVoters}`}</Typography>
                    <Typography>{`Total Postals: ${stats.totalPostalVoters}`}</Typography>
                    <Typography>{`Total Anonymous: ${stats.totalAnonymousVoters}`}</Typography>
                  </div>
                  {stats.maxValue > 0 ? (
                    <>
                      <TurnoutOverall
                        size={200}
                        elevation={0}
                        data={stats}
                        noFooter
                      />
                    </>
                  ) : (
                    <Typography style={{ padding: '2rem' }} variant={'h6'}>
                      No Turnout
                    </Typography>
                  )}
                </div>
                <div className="stats-graphs">
                  {stats.maxValue > 0 ? (
                    <>
                      <TurnoutPerHour size={750} elevation={0} data={stats} />
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {index < Object.entries(overallTurnout).length - 1 && (
                <hr key={`hr-${key}`} style={{ width: '100%' }} />
              )}
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <Typography variant={'h6'} paragraph align="center" gutterBottom>
            No Data
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(AggregateStatistics);
