import React, { useState, useContext } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  Tooltip,
  TableCell,
  TableHead,
  IconButton,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { User, Assignment } from '../../../entities/User';
import { UsersContext } from '../../../context/UsersContext';
import DeleteUserAssignmentModal from '../Modals/DeleteUserAssignment';
import EditAssignmentModal from '../Modals/EditAssignment';

interface UserAssignmentsProps {
  tenant: string;
  user: User;
  assignments: Assignment[];
}

const UserAssignments: React.FC<UserAssignmentsProps> = ({
  tenant,
  user,
  assignments,
}) => {
  const { refreshUsers } = useContext(UsersContext);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);

  const onClose = () => {
    setDisplayDelete(false);
    setDisplayEdit(false);
    refreshUsers();
  };

  return (
    <Table size="small" padding="none">
      <TableHead>
        <TableCell style={{ width: '25%' }}>Election Date</TableCell>
        <TableCell style={{ width: '25%' }}>Station</TableCell>
        <TableCell style={{ width: '25%' }}>Role</TableCell>
        <TableCell align="center" style={{ width: '25%' }}>
          Actions
        </TableCell>
      </TableHead>
      <TableBody>
        {assignments.map((row) => (
          <>
            <TableRow key={`${row.date}-${row.station}`}>
              <TableCell style={{ border: 'none' }}>{row.date}</TableCell>
              <TableCell style={{ border: 'none' }}>{row.station}</TableCell>
              <TableCell style={{ border: 'none' }}>{row.role}</TableCell>
              <TableCell style={{ border: 'none' }} align="center">
                <Tooltip title="Edit Assignment">
                  <IconButton onClick={() => setDisplayEdit(true)}>
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Assignment">
                  <IconButton onClick={() => setDisplayDelete(true)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>

            {displayDelete && (
              <DeleteUserAssignmentModal
                open={displayDelete}
                onClose={onClose}
                assignment={row}
                user={user}
                tenant={tenant}
              />
            )}

            {displayEdit && (
              <EditAssignmentModal
                open={displayEdit}
                onClose={onClose}
                assignment={row}
                user={user}
                tenant={tenant}
              />
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(UserAssignments);
