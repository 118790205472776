import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@mui/material';
import {
  getEligibilityRules,
  getGeogrpahyFile,
} from '../../../services/api/support';

import SupportTaskItem from '../SupportTaskItem';
import { Tasks } from '../../../entities/Tasks';
import { TenantPollstationContextProvider } from '../../../context/TenantPollstationContext';

import {
  ChangeEligibilityRulesModal,
  UploadCSVModal,
  ScrubDataModal,
  DeleteElectionModal,
  DcConvertorModal,
  IdoxXpressToDcModal,
  EditGeographyModal,
  ProxyConvertorModal,
  EnableExportAllReportsModal,
  CreateCertificateElectorModal,
} from './Modals';
import DcGeographyModal from './Modals/DcGeographyModal';
import ConvertBallotModal from './Modals/ConvertBallotModal';

const UtilitiesTaskList: React.FC = () => {
  const [deleteElection, deleteElectionSet] = useState(false);
  const [scrubData, scrubDataSet] = useState(false);
  const [uploadCSV, uploadCSVSet] = useState(false);
  const [changeEligibilityRules, changeEligibilityRulesSet] = useState(false);
  const [DcConvertor, DcConvertorSet] = useState(false);
  const [DcGeography, DcGeographySet] = useState(false);
  const [IdoxXpressToDc, IdoxXpressToDcSet] = useState(false);
  const [EditGeography, EditGeographySet] = useState(false);
  const [ProxyConvertor, ProxyConvertorSet] = useState(false);
  const [ConvertBallot, ConvertBallotSet] = useState(false);
  const [changeEnableExportsAllReports, changeEnableExportsAllReportsSet] =
    useState(false);
  const [certificateElector, certificateElectorSet] = useState(false);
  return (
    <>
      <TenantPollstationContextProvider>
        {deleteElection && (
          <DeleteElectionModal
            title="Delete Election"
            open={deleteElection}
            onClose={() => deleteElectionSet(false)}
          />
        )}
        {scrubData && (
          <ScrubDataModal
            title="Scrub Data"
            open={scrubData}
            onClose={() => scrubDataSet(false)}
          />
        )}
        {uploadCSV && (
          <UploadCSVModal
            title="Post Code Analysis"
            open={uploadCSV}
            onClose={() => uploadCSVSet(false)}
          />
        )}
        {changeEligibilityRules && (
          <ChangeEligibilityRulesModal
            title="Change Eligibility Rules"
            open={changeEligibilityRules}
            onClose={() => changeEligibilityRulesSet(false)}
            eligibilityRulesFetch={getEligibilityRules}
          />
        )}
        {DcConvertor && (
          <DcConvertorModal
            title="Convert DC Register(s) to Idox/Xpress Format"
            open={DcConvertor}
            onClose={() => DcConvertorSet(false)}
            geographyFileFetch={getGeogrpahyFile}
          />
        )}
        {DcGeography && (
          <DcGeographyModal
            title="Add electoral areas from Geography to DC Register(s)"
            open={DcGeography}
            onClose={() => DcGeographySet(false)}
            geographyFileFetch={getGeogrpahyFile}
          />
        )}
        {IdoxXpressToDc && (
          <IdoxXpressToDcModal
            title="Convert Idox or Xpress register(s) to  DC Register format"
            open={IdoxXpressToDc}
            onClose={() => IdoxXpressToDcSet(false)}
          />
        )}
        {EditGeography && (
          <EditGeographyModal
            title="Create or edit a Geography file"
            open={EditGeography}
            onClose={() => EditGeographySet(false)}
            geographyFileFetch={getGeogrpahyFile}
          />
        )}
        {ProxyConvertor && (
          <ProxyConvertorModal
            title="Convert proxy file to another EMS format"
            open={ProxyConvertor}
            onClose={() => ProxyConvertorSet(false)}
          />
        )}
        {changeEnableExportsAllReports && (
          <EnableExportAllReportsModal
            title="Enable Export All"
            open={changeEnableExportsAllReports}
            onClose={() => changeEnableExportsAllReportsSet(false)}
          />
        )}
        {ConvertBallot && (
          <ConvertBallotModal
            title="Convert Ballot file to another EMS format"
            open={ConvertBallot}
            onClose={() => ConvertBallotSet(false)}
          />
        )}
        {certificateElector && (
          <CreateCertificateElectorModal
            title="Create Certificate of Employment Elector"
            open={certificateElector}
            onClose={() => certificateElectorSet(false)}
          />
        )}
      </TenantPollstationContextProvider>

      <TableContainer square component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '30%' }}>Task</TableCell>
              <TableCell style={{ width: '60%' }}>Description</TableCell>
              <TableCell style={{ width: '10%' }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SupportTaskItem
              task={Tasks.DeleteElection}
              onClick={() => deleteElectionSet(true)}
              description="*IMPORTANT* This will permanently delete the election and all data associated with it"
            />
            <SupportTaskItem
              task={Tasks.ScrubData}
              onClick={() => scrubDataSet(true)}
              description="Retrieve a redacted data set for a tenant and election"
            />
            <SupportTaskItem
              task={Tasks.UploadCSV}
              onClick={() => uploadCSVSet(true)}
              description="Upload a csv file with postcodes and addresses to check signal strength"
            />
            <SupportTaskItem
              task={Tasks.ChangeEligibilityRules}
              onClick={() => changeEligibilityRulesSet(true)}
              description="Change the eligibility rules for the election"
            />
            <SupportTaskItem
              task={Tasks.EditGeography}
              onClick={() => EditGeographySet(true)}
              description="Create or edit Geography file"
            />
            <SupportTaskItem
              task={Tasks.DcGeography}
              onClick={() => DcGeographySet(true)}
              description="Add electoral areas from Geography to DC Register(s)"
            />
            <SupportTaskItem
              task={Tasks.dcConvertor}
              onClick={() => DcConvertorSet(true)}
              description="Convert DC Registers to Xpress/Idox format"
            />
            <SupportTaskItem
              task={Tasks.IdoxXpressToDc}
              onClick={() => IdoxXpressToDcSet(true)}
              description="Convert Idox or Xpress register(s) to  DC Register format"
            />
            <SupportTaskItem
              task={Tasks.ProxyConvertor}
              onClick={() => ProxyConvertorSet(true)}
              description="Convert Proxy file(s) to another EMS format"
            />
            <SupportTaskItem
              task={Tasks.ConvertBallot}
              onClick={() => ConvertBallotSet(true)}
              description="Convert Ballot file(s) to another EMS format"
            />
            <SupportTaskItem
              task={Tasks.EnableExportsAllReports}
              onClick={() => changeEnableExportsAllReportsSet(true)}
              description="Allow a .zip of all of an election's reports to be downloaded without breaking the seal"
            />
            <SupportTaskItem
              task={Tasks.CertificateEmployment}
              onClick={() => certificateElectorSet(true)}
              description="Allow a working staff member to vote at the station they are working in"
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UtilitiesTaskList;
