import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { GenericModalProps } from '../../GenericModalProps';
import MDModal from '../../../shared/MDModal';
import { GeographyFile } from '../../../../entities/GeographyFile';
import { GetGeographyFileRequest } from '../../../../entities/Support';

interface ParsedDataItem {
  ElectorID: string;
  PDCode: string;
  RollNo: string;
  OptOutMarker: string;
  JuryExemptMarker: string;
  ElectorName: string;
  AdministrativeArea: string;
  IsDeceased: string;
  FranchiseMarker: string;
  Constituency: string;
  'AV IsPostal': string;
  'AV IsProxy': string;
  DOB: string;
  PropertyNum: string;
  StreetDescriptor: string;
  TownName: string;
  PostCode: string;
  PlaceName: string;
  StationCode: string;
  PDName: string;
  VOTERTYPE: string;
  IsWaiver: string;
  IsEmergencyProxy: string;
  IsAbsent: string;
  UnverifiedDraft: string;
  Xordinate: string;
  Yordinate: string;
}

interface DcGeographyModalProps extends GenericModalProps {
  geographyFileFetch: (
    params: GetGeographyFileRequest
  ) => Promise<GeographyFile>;
  title: string;
}

function DcGeographyModal(props: DcGeographyModalProps) {
  const { title, open, onClose, geographyFileFetch } = props;
  const [geography, setGeogrpahy] = useState<GeographyFile>({
    RO: { name: '', title: 'Returning Officer' },
    authority: {
      AuthorityAddress1: '',
      AuthorityAddress2: '',
      AuthorityAddress3: '',
      AuthorityAddress4: '',
      AuthorityAddress5: '',
    },
    geography: [],
  });
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleTenantChange = (event: SelectChangeEvent<string>) => {
    setSelectedTenant(event.target.value);
  };

  useEffect(() => {
    if (selectedTenant !== '') {
      const params = {
        tenant: selectedTenant,
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          geographyFileFetch(params)
            .then((response) => {
              console.log(response);
              setGeogrpahy(response);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  }, [selectedTenant]);

  const handleFilesUpload = async (files: FileList | null) => {
    if (files) {
      const zip = new JSZip();
      const fileArray = Array.from(files);

      for (let index = 0; index < fileArray.length; index++) {
        const file = files[index];
        console.log(`Processing file: ${file.name}`);

        await new Promise<void>((resolve) => {
          parseCsvFile(file, (parsedData: ParsedDataItem[]) => {
            const transformedData = parsedData
              .filter(
                (item) =>
                  item &&
                  item.ElectorID !== undefined &&
                  item.PDCode !== undefined
              )

              .map((item) => {
                const matchedEntry = geography.geography.find(
                  (entry: any) => entry['PD Code'] === item.PDCode
                );
                if (!matchedEntry) {
                  setErrorMessage(
                    `${item.PDCode} was not found in Geography file.`
                  );
                  throw `${item.PDCode} was not found in Geography file.`;
                }

                const electoralAreaLevels = extractElectoralAreaNames(
                  matchedEntry ? matchedEntry.electoralAreas : []
                );

                // Apply header mapping and transformations here
                // Example:
                return {
                  ...item,
                  ElectoralAreaName: electoralAreaLevels,
                };
              });

            if (transformedData) {
              const csvData = Papa.unparse(transformedData);

              // Filter out empty rows from csvData
              const filteredCsvData = csvData
                .split('\n')
                .filter((row) => !/^\s*,*\s*$/.test(row))
                .join('\n');
              console.log(`Processed ${file.name}`);
              zip.file(`processed_${file.name}`, filteredCsvData);
              resolve();
            }
          });
        });
        if (index === files.length - 1) {
          await new Promise<void>((resolve) => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'processed_data.zip');
              console.log('Zip file generated');
              resolve();
            });
          });
        }
      }
    }
  };

  const parseCsvFile = (
    file: File,
    onDataParsed: (parsedData: ParsedDataItem[]) => void
  ) => {
    Papa.parse<ParsedDataItem>(file, {
      complete: (result) => {
        const parsedData = result.data;
        onDataParsed(parsedData);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      errorMessage={errorMessage}
      content={
        <Container component="div" maxWidth="xs">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
            <Select
              labelId="tenant-select-label"
              id="tenant-select"
              value={selectedTenant}
              onChange={handleTenantChange}
              label="Select Tenant"
              style={{ margin: '10px 0' }}
            >
              <MenuItem value="brent">Brent</MenuItem>
              <MenuItem value="fenland">Fenland District Council</MenuItem>
              <MenuItem value="gosport">Gosport County Council</MenuItem>
              <MenuItem value="rbwindosr">
                Royal Borough of Windsor and Maidenhead
              </MenuItem>
              <MenuItem value="staffordshire">Staffordshire</MenuItem>
              <MenuItem value="vale">Vale of Glamorgan</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              accept=".csv"
              multiple
              onChange={(e) => handleFilesUpload(e.target.files)}
              hidden
            />
          </Button>
        </Container>
      }
    />
  );

  function extractElectoralAreaNames(input: any[]): string {
    const electoralAreaNames = input
      .map((item: any) => item.electoralAreaName)
      .filter((level: string) => level !== '');
    return electoralAreaNames.join(', ');
  }
}

export default DcGeographyModal;
