import React, { useContext, useMemo } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';

import FilterBar from '../shared/FilterBar';
import { DropDown } from '../shared/DropDown';
import { TenantContext } from '../../context/TenantContext';
import { runDataValidation } from '../../services/api/dashboard';
import { CloudUpload } from '@mui/icons-material';

const ValidationFilterBar = () => {
  const {
    selectedTenant,
    selectedEmsType,
    selectedElection,
    setTenant,
    setElection,
    tenants,
    electionsForTenant,
    setValidations,
    validationsRunning,
    setValidationsRunning,
  } = useContext(TenantContext);

  const runEnabled = useMemo(
    () => selectedTenant && selectedElection && !validationsRunning,
    [validationsRunning, selectedTenant, selectedElection]
  );

  const getValidations = async () => {
    setValidationsRunning(true);
    setValidations([]);
    try {
      const params = {
        tenant: selectedTenant || '',
        electionDate: selectedElection || '',
        emsType: selectedEmsType || '',
        queryType: 'all',
      };
      const res = await runDataValidation(params);
      console.log(res);
      if (res.length) {
        setValidations(res);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log(error);
    } finally {
      setValidationsRunning(false);
    }
  };

  return (
    <FilterBar>
      {tenants && tenants.length && (
        <>
          <DropDown
            options={tenants}
            name="Tenant"
            label="Tenant"
            value={selectedTenant ?? ''}
            onSelect={(value: string) => setTenant(value)}
          />
          <DropDown
            options={electionsForTenant}
            name="Election"
            label="Election"
            value={selectedElection ?? ''}
            onSelect={(value: string) => setElection(value)}
          />
        </>
      )}
      <Button
        color="success"
        variant="contained"
        onClick={getValidations}
        disabled={!runEnabled}
        sx={{ backgroundColor: green[600] }}
      >
        <>
          {'Run'}&nbsp;
          <Tooltip title={'Start the validation process for'}>
            <>
              {validationsRunning === true ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                <CloudUpload />
              )}
            </>
          </Tooltip>
        </>
      </Button>
    </FilterBar>
  );
};

ValidationFilterBar.displayName = 'ValidationFilterBar';

export default React.memo(ValidationFilterBar);
