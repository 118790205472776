import React from 'react';
import { Paper, CardContent } from '@mui/material';
import { TurnoutStats } from '../../../entities/Pollstation';
import { VictoryChart, VictoryBar, VictoryLabel } from 'victory';

export interface TurnoutPerHourGraphProps {
  title?: string;
  className?: string;
  data: TurnoutStats;
  size?: number;
  elevation?: number;
}

const TurnoutPerHour: React.FC<TurnoutPerHourGraphProps> = (
  props: TurnoutPerHourGraphProps
) => {
  const { className, data, size = 400, elevation } = props;
  return (
    <Paper
      className={className}
      elevation={elevation}
      style={{ width: '100%' }}
    >
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <svg width={size} height={size / 2}>
          <VictoryChart
            standalone={false}
            width={size}
            height={size / 2}
            minDomain={{ y: 0 }}
            maxDomain={{
              y: data.maxValue > 10 ? data.maxValue : 10,
            }}
          >
            <VictoryBar
              alignment="middle"
              cornerRadius={{ top: 3 }}
              data={data.values}
              barRatio={1.1}
              style={{
                data: {
                  stroke: '#009AD4',
                  fill: '#36b5e5',
                  strokeWidth: ({ datum }) => (datum.y > 0 ? '1.5' : '1'),
                },
                labels: {
                  fontFamily: '"Arial", Sans-serif',
                  fontSize: 15,
                },
              }}
              labels={({ datum }) =>
                datum.y > 0 ? `${parseFloat(datum.y).toFixed(0)}` : ''
              }
              labelComponent={<VictoryLabel />}
              animate={{
                duration: 1000,
                onLoad: {
                  duration: 2000,
                },
              }}
            />
          </VictoryChart>
        </svg>
      </CardContent>
    </Paper>
  );
};

export default React.memo(TurnoutPerHour);
