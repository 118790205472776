import React, { useState, useMemo } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
  TextField,
} from '@mui/material';
import { Columns } from './ValidationFindings';
import { Download, ExpandMore } from '@mui/icons-material';
import './validation.css';

interface QueryResultProps {
  expanded: boolean;
  onChange: () => void;
  download: () => void;
  queryName: string;
  queryColumns: Columns[];
  // eslint-disable-next-line
  queryResults: any[];
}

const QueryTypeMapping = {
  getRegisterEANs: 'Register Electoral Areas by District',
  getRegisterDuplicates: 'Duplicate Electors',
  getAVBreakdown: 'Postal / Proxy Breakdown',
  getElectorsCountWithEitherDOADOB: 'DOA / DOB Count',
  getEAN: 'Electoral Area Name Breakdown',
  getEANFiltered: 'Electoral Areas Issues',
  getMapProxyToRegister: 'Proxy Register Map',
  getFindDuplicateBallots: 'Duplicate Ballots',
  getFindDuplicateRanges: 'Duplicate Ballot Ranges',
  getStaffReport: 'Staff Not Added',
  getTotalsByElection: 'Projected Statistics by Election',
  getStationTotalsByPD: 'Projected Statistics by Station / District',
  customerView: 'Customer Facing Validation Results',
};

const QueryResult: React.FC<QueryResultProps> = (props: QueryResultProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');

  const capitalised = (str: string): string =>
    str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');

  const updateTerms = (term: string, key: string) => {
    if (key !== selectedColumn) {
      setSelectedColumn(key);
    }
    if (term !== searchTerm) {
      setSearchTerm(term);
    }
  };

  const filteredRows = useMemo(() => {
    if (searchTerm === '') {
      return props.queryResults;
    }
    return props.queryResults.filter((qr) =>
      qr[selectedColumn].toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [props.queryResults, searchTerm, selectedColumn]);

  return (
    <Paper elevation={8} style={{ marginBottom: '2rem' }}>
      <Accordion
        expanded={props.expanded}
        onChange={props.onChange}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={
            <Tooltip title="Expand to view results">
              <ExpandMore />
            </Tooltip>
          }
          sx={{ backgroundColor: '#e9e9e9' }}
        >
          <Typography variant="h6" align="left">
            {/* eslint-disable-next-line */}
            {(QueryTypeMapping as any)[props.queryName]}
          </Typography>
          <Tooltip title="Download raw query results">
            <IconButton
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.download();
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0.5rem' }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: '#EBF5FB' }}>
              {props.queryColumns.map((c) => (
                <TableCell key={c.name}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {capitalised(c.name)}
                  </Typography>
                  <TextField
                    id={`${c.name}-search`}
                    variant="outlined"
                    value={c.name === selectedColumn ? searchTerm : ''}
                    onChange={(e) => updateTerms(e.currentTarget.value, c.name)}
                    size="small"
                    margin="none"
                    placeholder={`Search "${capitalised(c.name)}"`}
                  />
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {filteredRows.map((data, index) => (
                <TableRow key={index}>
                  {Object.keys(data).map((key) => (
                    <TableCell key={data[key]}>
                      <Typography variant="body1">{data[key]}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default React.memo(QueryResult);
