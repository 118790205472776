import { TenantConfig } from '../../../../entities/DynamoObjects';
import { ValidationResponseItem } from '../../../../entities/Tenant';
import {
  fetchTenants,
  runDataValidation,
  downloadDataValidationResult,
} from '../../../../services/api/dashboard';

import {
  type TenantListResponseType,
  type ValidationRequestType,
} from '../../model/tenant';

/**
 * getTenantList.
 * @description Gets a list of Organisations
 * @returns Promise of TenantListResponseType.
 */
const getTenantList = async (): Promise<TenantListResponseType> => {
  const init: TenantConfig[] = await fetchTenants();
  return {
    data: init.map((t) => {
      return {
        tenantId: t.tenant,
        electionDates: t.electionDates,
        emsType: t.emsType,
        tenant: t.tenant,
      };
    }),
  };
};

const getTenantValidationUrls = async ({
  tenant,
  emsType,
  electionDate,
  queryType = 'all',
}: ValidationRequestType): Promise<ValidationResponseItem[]> => {
  const data = await runDataValidation({
    tenant,
    electionDate,
    emsType,
    queryType,
  });
  return data;
};

const downloadValidationFiles = async ({
  tenant,
  emsType,
  queryType,
}: ValidationRequestType): Promise<string> => {
  const data = await downloadDataValidationResult({
    tenant,
    queryType: queryType || '',
    emsType,
  });
  return data;
};

export default {
  getTenantList,
  getTenantValidationUrls,
  downloadValidationFiles,
};
