export enum Tasks {
  RevertSkipped = 'Revert Skipped Ballots',
  ReassignBallots = 'Reassign Ballots',
  MarkClericalAddition = 'Mark Clerical Addition',
  MarkProxy = 'Mark Proxy Voter',
  MarkElector = 'Mark Voter',
  UpdateElectoralAreas = 'Update Electoral Areas',
  CreateTenant = 'Create Tenant',
  DeleteTenant = 'Delete Tenant',
  RedeployTenant = 'Redeploy Tenant',
  MoveElectionManager = 'Move Election Manager',
  CreateUserForTenant = 'Create User',
  GetLatestCode = 'Get Login Code',
  UploadCSV = 'Post Code Analysis',
  ChangeEligibilityRules = 'Change Eligibility Rules',
  VoterIncompleteProcess = 'Voter Incomplete',
  ScrubData = 'Scrub Data',
  DeleteElection = 'Delete Election',
  UpdateBallotRef = 'Update Ballot Reference',
  dcConvertor = 'DC Convertor',
  DcGeography = 'DC Geography',
  IdoxXpressToDc = 'Idox/Xpress to DC',
  EditGeography = 'Edit Geography',
  ProxyConvertor = 'Proxy Convertor',
  ConvertBallot = 'Ballot Convertor',
  EnableExportsAllReports = 'Enable Export All Reports',
  UpdateFirstLastElector = 'Update First and Last Elector',
  CertificateEmployment = 'Create Certificate of Employment Elector',
}
