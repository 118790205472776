import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import { User, AvailableAssignments } from '../../../entities/User';
import MDModal from '../../shared/MDModal';
import { DropDown } from '../../shared/DropDown';
import {
  getAvailableAssignments,
  createUserAssignment,
} from '../../../services/api/dashboard';

import { sortAlphaNumeric } from '../../../utils/utils';

interface CreateUserAssignmentModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  tenant: string;
}

const CreateUserAssignmentModal: React.FC<CreateUserAssignmentModalProps> = ({
  open,
  onClose,
  user,
  tenant,
}) => {
  const roles = ['Presiding Officer', 'Polling Clerk'];

  const [availableAssignments, setAvailableAssignments] = useState<
    AvailableAssignments[]
  >([]);
  const [selectedElection, setSelectedElection] = useState<string>('');
  const [selectedStation, setSelectedStation] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState(roles[0]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const elections = useMemo(
    () => [
      '',
      ...availableAssignments.map((a) => a.electionDate).sort(sortAlphaNumeric),
    ],
    [availableAssignments]
  );

  const stations = useMemo(() => {
    if (selectedElection) {
      return [
        '',
        ...(availableAssignments
          .find((a) => a.electionDate === selectedElection)
          ?.stations.sort(sortAlphaNumeric) || []),
      ];
    } else return [];
  }, [selectedElection, availableAssignments]);

  const getAssignments = async () => {
    try {
      setLoading(true);
      const res = await getAvailableAssignments({ tenant });
      setAvailableAssignments(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const createAssignment = async () => {
    try {
      setLoading(true);
      const params = {
        tenant,
        userId: user.id,
        electionDate: selectedElection,
        station: selectedStation,
        role: selectedRole,
      };
      await createUserAssignment(params);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssignments();
  }, []);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={'Create Assignment'}
      loading={loading}
      success={success}
      errorMessage={errorMessage}
      content={
        <div>
          <DropDown
            disabled={loading}
            options={elections}
            name="Elections"
            label={'Elections'}
            value={selectedElection}
            onSelect={(value) => setSelectedElection(value)}
            fullWidth
          />
          <DropDown
            disabled={loading || !selectedElection}
            options={stations}
            name="Stations"
            label={'Stations'}
            value={selectedStation}
            onSelect={(value) => setSelectedStation(value)}
            fullWidth
          />
          <DropDown
            disabled={loading || !selectedStation}
            options={roles}
            name="Role"
            label={'Role'}
            value={selectedRole}
            onSelect={(value) => setSelectedRole(value)}
            fullWidth
          />
        </div>
      }
      actionBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !selectedStation || success}
            onClick={createAssignment}
          >
            Create
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(CreateUserAssignmentModal);
