import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import { TenantContext } from '../../../../context/TenantContext';
import '../../../../styles.css';
import { DropDown } from '../../../shared/DropDown';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { runScrubData } from '../../../../services/api/dashboard';
import { sortAlphaNumeric } from '../../../../utils/utils';

interface ScrubDataProps extends GenericModalProps {
  title: string;
}

const ScrubDataModal: React.FC<ScrubDataProps> = ({
  open,
  onClose,
  title = 'Scrub Data',
}: ScrubDataProps) => {
  const { tenants, tenantDetails } = useContext(TenantContext);
  const {
    fetching,
    selectedTenant,
    setSelectedTenant,
    selectedElectionDate,
    setSelectedElectionDate,
  } = useContext(TenantPollstationContext);

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setSubmitEnabled(selectedTenant && selectedElectionDate ? true : false);
  }, [selectedTenant, selectedElectionDate]);

  const electionDates: string[] = useMemo(() => {
    if (selectedTenant) {
      const dates =
        tenantDetails.find((t) => t.tenant === selectedTenant)?.electionDates ||
        [];
      return dates.sort(sortAlphaNumeric);
    } else {
      return [];
    }
  }, [selectedTenant]);

  const onSubmit = async () => {
    const data = {
      tenant: selectedTenant || '',
      electionDate: selectedElectionDate || '',
    };
    return Promise.resolve(setLoading(true))
      .then(() =>
        runScrubData(data)
          .then((url) => {
            setSuccess(true);
            setTimeout(() => {
              window.open(url);
            }, 2000);
            setTimeout(() => setSuccess(false), 5000);
          })
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };
  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <DropDown
            disabled={fetching}
            options={tenantNames}
            name="Tenants"
            label="Tenants"
            value={selectedTenant}
            onSelect={setSelectedTenant}
            fullWidth
          />
          <DropDown
            disabled={fetching || !selectedTenant}
            options={electionDates}
            name="Election Date"
            label="Date"
            value={selectedElectionDate}
            onSelect={setSelectedElectionDate}
            fullWidth
          />
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default ScrubDataModal;
