import React from 'react';
import { Typography, Table, TableCell, TableRow, Card } from '@mui/material';

import { Address } from '../../entities/DynamoObjects';

export interface ElectorInfoProps {
  electorId: string;
  name: string;
  address: Address;
  proxyName?: string;
  proxyAddress?: Address;
  isProxy?: boolean;
}

export const ElectorInfo: React.FC<ElectorInfoProps> = (
  props: ElectorInfoProps
) => {
  const {
    electorId,
    name,
    address,
    proxyName,
    proxyAddress,
    isProxy = false,
  } = props;
  return (
    <Card style={{ padding: '1rem 0.5rem', margin: '2rem 0' }} raised>
      <Typography variant="body1" gutterBottom>
        Elector Info
      </Typography>
      <Table padding="none">
        <TableRow>
          <TableCell>
            <Typography variant="body1">PD-ENO:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{electorId}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body1">Name:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body1">Address:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {`
                  ${address?.address1 ? `${address?.address1},` : ''}
                  ${address?.address2 ? `${address?.address2},` : ''} 
                  ${address?.address3 ? `${address?.address3},` : ''}
                  ${address?.postcode ? `${address?.postcode}` : ''}
              `}
            </Typography>
          </TableCell>
        </TableRow>
        {isProxy && proxyName && (
          <TableRow>
            <TableCell>
              <Typography variant="body1">Proxy:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{proxyName}</Typography>
            </TableCell>
          </TableRow>
        )}
        {isProxy && proxyAddress && (
          <TableRow>
            <TableCell>
              <Typography variant="body1">Address:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {`
                  ${proxyAddress?.address1 ? `${proxyAddress?.address1},` : ''}
                  ${proxyAddress?.address2 ? `${proxyAddress?.address2},` : ''} 
                  ${proxyAddress?.address3 ? `${proxyAddress?.address3},` : ''}
                  ${proxyAddress?.postcode ? `${proxyAddress?.postcode}` : ''}
                `}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Card>
  );
};
