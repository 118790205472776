import React, { useContext, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

import { TenantContext } from '../context/TenantContext';
import { UsersContextProvider } from '../context/UsersContext';

import Loader from './shared/Loader';
import TabPanel from './shared/TabPanel';
import PollstationsList from './PollstationsList/PollstationsList';
import DeviceList from './DeviceList/DeviceList';
import SupportTaskList from './SupportTasks/SupportTaskList';
import UsersList from './UsersList/UsersList';
import DataValidation from './Validation/Container';
import PreElecChecks from './PreElecChecks/PreElecChecks';
import PreElecStaffChecks from './PreElecStaffChecks/PreElecStaffChecks';

enum ActiveDashboard {
  Pollstations,
  Devices,
  Users,
  Validation,
  PreElecChecks,
  PreElecStaffChecks,
  Support,
}

const Dashboard: React.FC = () => {
  const { setupComplete: tenantSetupComplete } = useContext(TenantContext);
  const [activeDashboard, activeDashboardSet] = useState<ActiveDashboard>(
    ActiveDashboard.Pollstations
  );

  if (!tenantSetupComplete) {
    return <Loader message={'Fetching tenant details'} />;
  }
  return (
    <>
      <Tabs
        centered
        indicatorColor="primary"
        value={activeDashboard}
        onChange={(_, newValue) => activeDashboardSet(newValue)}
      >
        <Tab id="pollStations" label="Poll Stations" />
        <Tab id="devices" label="Devices" />
        <Tab id="users" label="Users" />
        <Tab id="validation" label="Validation" />
        <Tab id="preeleccnlcheck" label="CNL Checker" />
        <Tab id="preelecstaffcheck" label="Staff Checker" />
        <Tab id="support" label="Support" />
      </Tabs>

      <TabPanel index={ActiveDashboard.Pollstations} value={activeDashboard}>
        <PollstationsList />
      </TabPanel>

      <TabPanel index={ActiveDashboard.Devices} value={activeDashboard}>
        <DeviceList />
      </TabPanel>

      <TabPanel index={ActiveDashboard.Users} value={activeDashboard}>
        <UsersContextProvider>
          <UsersList />
        </UsersContextProvider>
      </TabPanel>

      <TabPanel index={ActiveDashboard.Validation} value={activeDashboard}>
        <DataValidation />
      </TabPanel>

      <TabPanel index={ActiveDashboard.PreElecChecks} value={activeDashboard}>
        <PreElecChecks />
      </TabPanel>

      <TabPanel
        index={ActiveDashboard.PreElecStaffChecks}
        value={activeDashboard}
      >
        <PreElecStaffChecks />
      </TabPanel>

      <TabPanel index={ActiveDashboard.Support} value={activeDashboard}>
        <SupportTaskList />
      </TabPanel>
    </>
  );
};

export default Dashboard;
