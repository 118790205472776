import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import { Tasks } from '../../entities/Tasks';
import '../../styles.css';

interface SupportTaskItemProps {
  onClick: () => void;
  description: string;
  task: Tasks;
}

const SupportTaskItem: React.FC<SupportTaskItemProps> = (
  props: SupportTaskItemProps
) => {
  const { task, description, onClick } = props;

  return (
    <TableRow id={task}>
      <TableCell>{task}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="center">
        <Button
          id={`${task}RunBtn`}
          color="primary"
          variant="contained"
          onClick={onClick}
        >
          Run
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default SupportTaskItem;
