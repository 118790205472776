import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { GetStatsRequest, StatsResponse } from '../../../../entities/Stats';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import '../../../../styles.css';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import TenantPollstationPicker from './TenantPollstationPicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface VoterIncompleteProcessModalProps extends GenericModalProps {
  statsFetch: (params: GetStatsRequest) => Promise<StatsResponse[]>;
  title: string;
}
const VoterIncompleteProcessModal: React.FC<
  VoterIncompleteProcessModalProps
> = ({
  open,
  onClose,
  statsFetch: statsFetch,
  title,
}: VoterIncompleteProcessModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format('YYYY-MM-DD HH:mm')
  );

  const [statsObjectList, setStatsObjects] = useState<StatsResponse[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [datesValid, setDatesValid] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g Europe/London
  moment.tz.setDefault(timeZone);
  console.log(`timezone - ${timeZone}`);

  const handleChangePage = (
    // eslint-disable-next-line
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSubmit = async () => {
    if (
      selectedTenant &&
      selectedElectionDate &&
      selectedPollstation &&
      datesValid
    ) {
      const params = {
        tenant: selectedTenant,
        electionDate: selectedElectionDate,
        stationId: selectedPollstation?.pollStationId || '',
        startTime: moment(selectedDate).valueOf().toString(),
        endTime: moment(selectedEndDate).valueOf().toString(),
        statType: 'transaction/Cancelled',
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          statsFetch(params)
            .then((results) => {
              setStatsObjects(results);
              setSuccess(true);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  };

  const handleFirstDateChange = (date: string) => {
    const newDate = date;
    // const newDate = moment.utc(date).tz(timeZone).toString();
    setSelectedDate(newDate);
    isEndDateAfterStartDate(selectedDate, selectedEndDate);
  };

  const handleEndDateChange = (date: string) => {
    const newDate = date;
    // const newDate = moment.utc(date).tz(timeZone).toString();
    setSelectedEndDate(newDate);
    isEndDateAfterStartDate(selectedDate, selectedEndDate);
  };

  const isEndDateAfterStartDate = (startDate: string, endDate: string) => {
    // Get both unix timestampsm from start and end date
    const sDate = moment(startDate).unix();
    const eDate = moment(endDate).unix();

    // Ensure the end date is after start date otherwise dont set the dates as being valid so they shouldn't be able to run task
    if (eDate > sDate) {
      setDatesValid(true);
    } else {
      setDatesValid(false);
    }
  };

  useEffect(() => {
    if (selectedPollstation) {
      setStatsObjects([]);
      setSubmitEnabled(datesValid);
      isEndDateAfterStartDate(selectedDate, selectedEndDate);
    }
  }, [selectedPollstation, selectedDate, selectedEndDate]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      width={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      content={
        <>
          <TenantPollstationPicker />
          {selectedPollstation && (
            <>
              <br></br>
              <br></br>
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Start Time"
                    ampm={false}
                    ampmInClock={false}
                    openTo="day"
                    inputFormat="DD/MM/YYYY HH:mm a"
                    value={selectedDate}
                    onChange={(value) => {
                      setStatsObjects([]);
                      handleFirstDateChange(
                        moment(
                          value?.toString() ? value.toString() : ''
                        ).toString()
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          {selectedPollstation && (
            <>
              <br></br>
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                  // dateFormats={timeZone}
                >
                  <DateTimePicker
                    label="End Time"
                    openTo="day"
                    inputFormat="DD/MM/YYYY hh:mm a"
                    value={selectedEndDate}
                    ampm={false}
                    ampmInClock={false}
                    onChange={(value) => {
                      setStatsObjects([]);
                      handleEndDateChange(
                        moment(
                          value?.toString() ? value.toString() : ''
                        ).toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          !datesValid
                            ? 'End Date needs to be after start date'
                            : ''
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          {selectedPollstation &&
            datesValid &&
            statsObjectList.length > 0 &&
            success && (
              <div>
                <br></br>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table stickyHeader style={{ borderRadius: '10px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '17%' }}>
                            Number on Register
                          </TableCell>
                          <TableCell style={{ width: '17%' }}>
                            Elector
                          </TableCell>
                          <TableCell style={{ width: '17%' }}>
                            Stage of Cancellation
                          </TableCell>
                          <TableCell style={{ width: '17%' }}>Time</TableCell>
                        </TableRow>
                        {statsObjectList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
                          .map((listValue, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell>
                                    {
                                      listValue.properties.voterId.split(
                                        '::'
                                      )[2]
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {listValue.properties.voterName}
                                  </TableCell>
                                  <TableCell>
                                    {listValue.properties.stepCancelled}
                                  </TableCell>
                                  <TableCell>
                                    {moment
                                      .utc(listValue.startTime)
                                      .tz(timeZone)
                                      .isDST()
                                      ? // ? `${moment(listValue.startTime)
                                        //     // .utc(listValue.startTime)
                                        //     .tz(timeZone)
                                        //     .format("HH:mm:ss")} BST`
                                        // : `${moment(listValue.startTime)
                                        //     // .utc(listValue.startTime)
                                        //     .tz(timeZone)
                                        //     .format("HH:mm:ss")} GMT`}
                                        `${moment
                                          .utc(listValue.startTime)
                                          .tz(timeZone)
                                          .format('HH:mm:ss')} BST`
                                      : `${moment
                                          .utc(listValue.startTime)
                                          .tz(timeZone)
                                          .format('HH:mm:ss')} GMT`}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Paper>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={statsObjectList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          {selectedPollstation && statsObjectList.length === 0 && (
            <div>
              <br></br>
              <Typography variant={'h6'} paragraph align="center" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled && !datesValid}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default VoterIncompleteProcessModal;
