import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { PollstationStaff, Staff } from '../../../entities/Pollstation';

interface StaffRowsProps {
  staff: PollstationStaff;
}

const StaffRows: React.FC<StaffRowsProps> = ({ staff }) => {
  const getRole = (user: Staff): string =>
    (user?.role || '')
      .split(' ')
      .map((word) => word[0])
      .join('');

  const noPad = { padding: 'none' };
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '5%' }}>{'Role'}</TableCell>
          <TableCell style={{ width: '45%' }}>{'Name'}</TableCell>
          <TableCell style={{ width: '45%' }}>{'Number'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(staff || {}).map(([id, user]) => (
          <TableRow key={`Pollstation-User-${id}`}>
            <TableCell style={{ borderBottom: 'none', ...noPad }}>
              {getRole(user)}
            </TableCell>
            <TableCell
              style={{ borderBottom: 'none', ...noPad }}
            >{`${user.firstName} ${user.lastName}`}</TableCell>
            <TableCell style={{ borderBottom: 'none', ...noPad }}>
              {user.phoneNumber}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(StaffRows);
