import * as React from 'react';
import { WithChildren } from '../../types/WithChildren';
import './FilterBar.css';

const FilterBar: React.FC<WithChildren> = (props: WithChildren) => {
  const { children } = props;
  return <div className="filterBar">{children}</div>;
};

export default React.memo(FilterBar);
