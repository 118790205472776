import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Container,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { GenericModalProps } from '../../GenericModalProps';
import MDModal from '../../../shared/MDModal';
import { GetGeographyFileRequest } from '../../../../entities/Support';
import { updateGeographyFile } from '../../../../services/api/support';
import { GeographyFile } from '../../../../entities/GeographyFile';

interface EditGeographyModalProps extends GenericModalProps {
  geographyFileFetch: (
    params: GetGeographyFileRequest
  ) => Promise<GeographyFile>;
  title: string;
}

const EditGeographyModal: React.FC<EditGeographyModalProps> = ({
  open,
  onClose,
  geographyFileFetch,
  title,
}: EditGeographyModalProps) => {
  const [editedData, setEditedData] = useState<GeographyFile>({
    RO: { name: '', title: 'Returning Officer' },
    authority: {
      AuthorityAddress1: '',
      AuthorityAddress2: '',
      AuthorityAddress3: '',
      AuthorityAddress4: '',
      AuthorityAddress5: '',
    },
    geography: [],
  });
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newColumnName, setNewColumnName] = useState('');
  const [columnNames, setColumnNames] = useState<string[]>([]);

  useEffect(() => {
    if (selectedTenant !== '') {
      const params = {
        tenant: selectedTenant,
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          geographyFileFetch(params)
            .then((response) => {
              console.log(response);
              setEditedData(response);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (editedData.geography && editedData.geography[0]?.electoralAreas) {
      const initialColumnNames = editedData.geography[0].electoralAreas.map(
        (area) => area.electoralAreaLevel
      );
      setColumnNames(initialColumnNames);
    }
  }, [editedData.geography]);

  const handleTenantChange = (event: SelectChangeEvent<string>) => {
    setSelectedTenant(event.target.value as string);
  };

  const handleSave = () => {
    if (editedData) {
      const newData = editedData;
      console.log(JSON.stringify(newData, null, 2));
      return Promise.resolve(setLoading(true))
        .then(() =>
          updateGeographyFile(newData, selectedTenant)
            .then(() => {
              setSuccess(true);
              setEditedData(newData);
              setTimeout(() => setSuccess(false), 5000);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  };

  const handleCodeChange = (rowId: number, newValue: string) => {
    setEditedData((prevData) => {
      const updatedData = [...prevData.geography];
      const rowIndex = updatedData.findIndex((item) => item.rowId === rowId);
      if (rowIndex !== -1) {
        updatedData[rowIndex]['PD Code'] = newValue;
      }
      return { ...prevData, geography: updatedData };
    });
  };

  const handleAreaNameChange = (
    rowId: number,
    index: number,
    newValue: string
  ) => {
    setEditedData((prevData) => {
      const updatedData = [...prevData.geography];
      const rowIndex = updatedData.findIndex((item) => item.rowId === rowId);
      if (rowIndex !== -1) {
        updatedData[rowIndex].electoralAreas[index].electoralAreaName =
          newValue;
      }
      return { ...prevData, geography: updatedData };
    });
  };

  const handleAddRow = () => {
    setEditedData((prevData) => {
      const newRowId = prevData.geography.length + 1;

      // Get the last row's electoralAreaNames
      const lastRow = prevData.geography[prevData.geography.length - 1];
      const lastRowElectoralAreaNames = lastRow;
      // Create a new row with pre-populated electoralAreaNames
      const newRow = {
        rowId: newRowId,
        'PD Code': '',
        electoralAreas: lastRow.electoralAreas.map((area) => ({
          electoralAreaLevel: area.electoralAreaLevel,
          electoralAreaName: area.electoralAreaName,
        })),
      };

      return { ...prevData, geography: [...prevData.geography, newRow] };
    });
  };

  const handleRemoveRow = (rowId: number) => {
    setEditedData((prevData) => {
      const updatedData = prevData.geography.filter(
        (item) => item.rowId !== rowId
      );
      return { ...prevData, geography: updatedData };
    });
  };

  const handleRemoveColumn = (columnIndex: number) => {
    setEditedData((prevData) => {
      // Create a deep copy of the previous data
      const updatedData = JSON.parse(JSON.stringify(prevData));

      // Remove the electoralArea at the specified columnIndex
      updatedData.geography.forEach((item: any) => {
        item.electoralAreas.splice(columnIndex, 1);
      });

      return updatedData;
    });
  };

  const handleAddColumn = () => {
    if (newColumnName.trim() !== '') {
      // Update the column names
      //setColumnNames((prevColumnNames) => [...prevColumnNames, newColumnName]);

      setEditedData((prevData) => {
        const updatedData = { ...prevData };

        // Create a new electoralArea for each row with the user-entered name
        updatedData.geography.forEach((item) => {
          item.electoralAreas.push({
            electoralAreaLevel: newColumnName,
            electoralAreaName: '',
          });
        });

        return updatedData;
      });

      setNewColumnName(''); // Clear the input field
    }
  };

  const handleColumnNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewColumnName(event.target.value);
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={1000}
      width={1000}
      success={success}
      loading={loading}
      errorMessage={errorMessage}
      content={
        <Container>
          <Box marginBottom={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
              <Select
                labelId="tenant-select-label"
                id="tenant-select"
                value={selectedTenant}
                onChange={handleTenantChange}
                label="Select Tenant"
              >
                <MenuItem value="brent">Brent</MenuItem>
                <MenuItem value="fenland">Fenland District Council</MenuItem>
                <MenuItem value="gosport">Gosport County Council</MenuItem>
                <MenuItem value="rbwindosr">
                  Royal Borough of Windsor and Maidenhead
                </MenuItem>
                <MenuItem value="staffordshire">Staffordshire</MenuItem>
                <MenuItem value="vale">Vale of Glamorgan</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <div>
            <Button onClick={handleAddColumn}>Add Column</Button>
            <Input
              type="text"
              placeholder="Enter Column Header"
              value={newColumnName}
              onChange={handleColumnNameChange}
            />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>PD Code</TableCell>
                    {editedData?.geography?.[0]?.electoralAreas?.map(
                      (area, index) => (
                        <TableCell key={index}>
                          {area.electoralAreaLevel}
                          <IconButton onClick={() => handleRemoveColumn(index)}>
                            <Close />{' '}
                            {/* You can replace CloseIcon with your desired icon */}
                          </IconButton>
                        </TableCell>
                      )
                    )}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedData?.geography?.map((item) => (
                    <TableRow key={item.rowId}>
                      <TableCell>
                        <Input
                          value={item['PD Code']}
                          onChange={(e) =>
                            handleCodeChange(item.rowId, e.target.value)
                          }
                        />
                      </TableCell>
                      {item?.electoralAreas?.map((area, index) => (
                        <TableCell key={index} contentEditable>
                          <Input
                            value={area.electoralAreaName}
                            onChange={(e) =>
                              handleAreaNameChange(
                                item.rowId,
                                index,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button onClick={() => handleRemoveRow(item.rowId)}>
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button variant="contained" onClick={handleAddRow}>
                Add Row
              </Button>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ float: 'right', margin: '35px 0' }}
          >
            Save
          </Button>
        </Container>
      }
    />
  );
};

export default EditGeographyModal;
