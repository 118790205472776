import React, { useState } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { GenericModalProps } from '../../GenericModalProps';
import MDModal from '../../../shared/MDModal';

interface ParsedDataItem {
  Anonymous: string;
  'Elector ID': string;
  ENO: string;
  'Franchise Flag': string;
  'Elector Flags': string;
  'First Name': string;
  Initials: string;
  Surname: string;
  'Date Of Birth': string;
  'Polling Station Number': string;
  'Polling Station Name': string;
  'Residing Address 1': string;
  'Authority Address 1': string;
  'Residing Address 2': string;
  'Residing Address 3': string;
  'Residing Address Postcode': string;
  'PD Code': string;
  'Absent Flag': string;
  'PD Name': string;
  Eligibility: string;
  'Electoral Area Name(s)': string;
}

interface IdoxXpressToDcModalProps extends GenericModalProps {
  title: string;
}

function IdoxXpressToDcModal(props: IdoxXpressToDcModalProps) {
  const { title, open, onClose } = props;

  const handleFilesUpload = async (files: FileList | null) => {
    if (files) {
      const zip = new JSZip();
      const fileArray = Array.from(files);

      for (let index = 0; index < fileArray.length; index++) {
        const file = files[index];
        console.log(`Processing file: ${file.name}`);

        await new Promise<void>((resolve) => {
          parseCsvFile(file, (parsedData: ParsedDataItem[]) => {
            const transformedData = parsedData
              .filter((item) => item && item['Elector ID'] !== '')
              .map((item) => {
                return {
                  ElectorID: item['Elector ID'],
                  RollNo: item.ENO,
                  FranchiseMarker:
                    item.Anonymous === 'Anonymous'
                      ? item['Franchise Flag'].indexOf('N') === -1
                        ? `${item['Franchise Flag']}N`
                        : item['Franchise Flag']
                      : item['Franchise Flag'],
                  OptOutMarker: '',
                  JuryExemptMarker: '',
                  ElectorName:
                    item.Anonymous === 'Anonymous'
                      ? 'N,N'
                      : `${item.Surname}, ${item['First Name']} ${item.Initials}`.trim(),
                  StationCode: item['Polling Station Number'],
                  PlaceName: item['Polling Station Name'],
                  PropertyNum:
                    item.Anonymous === 'Anonymous'
                      ? 'OTHER ELECTORS'
                      : item['Residing Address 1'],
                  AdministrativeArea: item['Authority Address 1'],
                  StreetDescriptor:
                    item.Anonymous === 'Anonymous'
                      ? ''
                      : item['Residing Address 2'],
                  TownName:
                    item.Anonymous === 'Anonymous'
                      ? ''
                      : item['Residing Address 3'],
                  PostCode:
                    item.Anonymous === 'Anonymous'
                      ? ''
                      : item['Residing Address Postcode'],
                  PDCode: item['PD Code'],
                  PDName: item['PD Name'],
                  IsDeceased:
                    item.Eligibility === 'Ineligible' ? 'TRUE' : 'FALSE',
                  DOB: item['Date Of Birth'],
                  ElectoralAreaName: item['Electoral Area Name(s)'],
                  Constituency: item['Authority Address 1'],
                  VOTERTYPE: '',
                  'AV IsPostal': item['Absent Flag']
                    ? item['Absent Flag'] === 'A'
                      ? 'TRUE'
                      : 'FALSE'
                    : 'FALSE',
                  'AV IsProxy': item['Absent Flag']
                    ? item['Absent Flag'] === 'P'
                      ? 'True'
                      : 'FALSE'
                    : 'FALSE',
                  IsWaiver: 'FALSE',
                  IsEmergencyProxy: 'FALSE',
                  IsAbsent: item['Absent Flag']
                    ? item['Absent Flag'] === 'A'
                      ? 'TRUE'
                      : 'FALSE'
                    : 'FALSE',
                  UnverifiedDraft: 'FALSE',
                  Xordinate: '',
                  Yordinate: '',
                };
              });

            const csvData = Papa.unparse(transformedData);

            const filteredCsvData = csvData
              .split('\n')
              .filter((row) => !/^\s*,*\s*$/.test(row))
              .join('\n');
            console.log(`Processed ${file.name}`);
            zip.file(`processed_${file.name}`, filteredCsvData);

            resolve();
          });
        });
        if (index === files.length - 1) {
          await new Promise<void>((resolve) => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'processed_data.zip');
              console.log('Zip file generated');
              resolve();
            });
          });
        }
      }
    }
  };

  const parseCsvFile = (
    file: File,
    onDataParsed: (parsedData: ParsedDataItem[]) => void
  ) => {
    Papa.parse<ParsedDataItem>(file, {
      complete: (result) => {
        const parsedData = result.data;
        onDataParsed(parsedData);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      content={
        <Container component="div" maxWidth="xs">
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              accept=".csv"
              multiple
              onChange={(e) => handleFilesUpload(e.target.files)}
              hidden
            />
          </Button>
        </Container>
      }
    />
  );
}

export default IdoxXpressToDcModal;
