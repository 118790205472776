/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable quotes */
import { Auth } from 'aws-amplify';

const getEnvFromURL = (): string => {
  const fullUrl = window.location.href;
  let env = 'gcd';
  if (!fullUrl.startsWith('http://localhost')) {
    env = fullUrl.split('.')[1];
  }
  return env;
};

export const getApiUrl = (): string => {
  const env = getEnvFromURL();
  return env === 'moderndemocracy'
    ? `https://api-dashboard.moderndemocracy.com`
    : `https://api-dashboard.${env}.moderndemocracy.com`;
};

export const getContextApiUrl = (): string => {
  const env = getEnvFromURL();
  return env === 'moderndemocracy'
    ? `https://context-api.moderndemocracy.com`
    : `https://context-api.${env}.moderndemocracy.com`;
};

export const genericRequestHandler = async (res: Response): Promise<any> => {
  if (!res.ok) {
    const json = await res.json();
    return Promise.reject(json || 'An unknown error occurred');
  }
  return res.json();
};

export const addQueryParams = (
  uri = '',
  params: { [key: string]: any }
): string => {
  if (Object.entries(params).length === 0) {
    return uri;
  }
  const paramsStr = Object.keys(params)
    .map((key: string, index) =>
      index === 0
        ? `${key}=${encodeURIComponent(params[key])}`
        : `&${key}=${encodeURIComponent(params[key])}`
    )
    .join('');
  return `${uri}?${paramsStr}`;
};
export const generateNoAuthRequest = (method = 'GET'): RequestInit => {
  const params = {
    mode: 'cors' as RequestMode,
    method: method,
    headers: {
      'Access-Control-Request-Method': `${method}`,
      'Content-Type': 'application/json',
    },
  };
  return params;
};

export const generateRequest = async (method = 'GET'): Promise<RequestInit> => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  const params = {
    mode: 'cors' as RequestMode,
    method: method,
    headers: {
      Authorization: token,
      'Access-Control-Request-Method': `${method}`,
      'Content-Type': 'application/json',
    },
  };
  return params;
};
const generateCsvRequest = async (method = 'GET'): Promise<RequestInit> => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  const params = {
    mode: 'cors' as RequestMode,
    method: method,
    headers: {
      Authorization: token,
      'Access-Control-Request-Method': `${method}`,
      'Content-Type': 'text/csv',
    },
  };
  return params;
};

export const getData = async (uri = '', queryParams = {}): Promise<any> => {
  const uriQuery = addQueryParams(uri, queryParams);
  const request = await generateRequest('GET');
  const response = await fetch(`${getApiUrl()}${uriQuery}`, request);
  return genericRequestHandler(response);
};

export const getCsvData = async (url: string): Promise<any> => {
  const request = await generateCsvRequest('GET');
  const response = await fetch(`${url}`, request);
  return genericRequestHandler(response);
};

export const postData = async (uri = '', data = {}): Promise<any> => {
  const request = await generateRequest('POST');
  const postRequest = {
    ...request,
    body: JSON.stringify(data),
  };
  const response = await fetch(`${getApiUrl()}${uri}`, postRequest);
  return genericRequestHandler(response);
};

export const patchData = async (uri = '', data = {}): Promise<any> => {
  const request = await generateRequest('PATCH');
  const patchRequest = {
    ...request,
    body: JSON.stringify(data),
  };
  const response = await fetch(`${getApiUrl()}${uri}`, patchRequest);
  return genericRequestHandler(response);
};

export const deleteData = async (uri = '', data = {}): Promise<any> => {
  const request = await generateRequest('DELETE');
  const deleteRequest = {
    ...request,
    body: JSON.stringify(data),
  };
  const response = await fetch(`${getApiUrl()}${uri}`, deleteRequest);
  return genericRequestHandler(response);
};
