import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ArrowDropDownSharp } from '@mui/icons-material';
interface SupportTaskGroupProps {
  id: 'panel1' | 'panel2' | 'panel3';
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  defaultExpanded?: boolean;
  expanded: false | string;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const SupportTaskGroup: React.FC<SupportTaskGroupProps> = (
  props: SupportTaskGroupProps
) => {
  const {
    id,
    title,
    content,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defaultExpanded = false,
    expanded,
    handleChange,
  } = props;

  return (
    <Accordion
      expanded={expanded === id}
      onChange={handleChange(id)}
      elevation={1}
      style={{
        padding: 0,
        color: 'white',
        backgroundColor: '#3e90c7',
        border: '1px solid lightGray',
        borderRadius: '10px',
      }}
    >
      <AccordionSummary
        id={title}
        expandIcon={<ArrowDropDownSharp style={{ color: 'white' }} />}
      >
        <Typography variant={'h6'}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>{content}</AccordionDetails>
    </Accordion>
  );
};

export default SupportTaskGroup;
