import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Paper, Table, TableBody, TableRow, TableCell } from '@mui/material';

import { TenantContext } from '../../context/TenantContext';
import { fetchOverview } from '../../services/api/dashboard';
import { TenantOverview } from '../../entities/Pollstation';

import OverviewTable from './OverviewTable';
import Loader from '../shared/Loader';
import Controls from './HeaderControls';

import './Overview.css';

const DashboardsOverview: React.FC = () => {
  let pollInterval: NodeJS.Timeout;

  const { setupComplete, tenantDetails, selectedElection } =
    useContext(TenantContext);

  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<TenantOverview[]>([]);
  const [lastFetched, setLastFetched] = useState(Date.now());

  const getData = async (date: string | undefined) => {
    if (date) {
      setOverviewData([]);
      setLoading(true);
      try {
        const data = await fetchOverview(date);
        setOverviewData(data);
      } catch (error) {
        console.log(`Error getting overview data: ${error}`);
      } finally {
        setLastFetched(Date.now());
        setLoading(false);
      }
    }
  };

  const totalStations = useMemo(() => {
    if (overviewData.length) {
      const totalCount = overviewData.reduce(
        (total, item) => (total += item.usingMP),
        0
      );
      return totalCount;
    }
    return 0;
  }, [overviewData]);

  useEffect(() => {
    if (tenantDetails && selectedElection) {
      getData(selectedElection);
    }
    pollInterval = setInterval(() => getData(selectedElection), 300000);
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
        if (pollInterval?.unref) {
          pollInterval.unref();
        }
      }
    };
  }, [tenantDetails, selectedElection]);

  if (!setupComplete) {
    return <Loader />;
  }

  return (
    <div className="container">
      <Controls
        reloadData={() => getData(selectedElection)}
        lastFetched={lastFetched}
        loading={loading}
        totalStations={totalStations}
      />
      <Paper>
        {loading && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {!loading && setupComplete && overviewData && (
          <OverviewTable data={overviewData} electionDate={selectedElection} />
        )}
      </Paper>
    </div>
  );
};

export default React.memo(DashboardsOverview);
