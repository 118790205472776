import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CnlCheckResults } from '../../../entities/CnlResults';
import { AddCircle, CheckCircle } from '@mui/icons-material';
import { sortAlphaNumeric } from '../../../utils/utils';

const statusCheck = (showCheck: boolean) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      float: 'left',
    }}
  >
    {showCheck ? (
      <CheckCircle style={{ color: 'forestgreen' }} />
    ) : (
      // eslint-disable-next-line react/jsx-no-undef
      <AddCircle style={{ transform: 'rotate(45deg)', color: 'red' }} />
    )}
  </div>
);

const StaffCheckerResultsTable: React.FC<any> = (
  headers: string[],
  jsonData: CnlCheckResults
) => {
  const entries = Object.values(jsonData);
  let staffResults = entries[0];
  const managerAssignments = staffResults.filter((x: any) =>
    Object.keys(x).includes('manager_assignments')
  );

  staffResults = staffResults.filter(
    (x: any) => !Object.keys(x).includes('manager_assignments')
  );

  const containsStationNums = staffResults.some((z: any) => z['user_station']);
  if (containsStationNums) {
    staffResults = staffResults.sort((a: any, b: any) =>
      sortAlphaNumeric(a['user_station'], b['user_station'])
    );
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <TableContainer style={containerStyle}>
      <Table style={containerStyle} className="staff-row">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {staffResults.map((entry: any, index: any) => (
            <>
              <TableRow key={`${entry.valid}_${index}`}>
                <TableCell
                  key={`${index}_Status_${entry.valid}`}
                  className="row_item"
                >
                  {statusCheck(entry.valid)}
                </TableCell>
                <TableCell key={`${index}_StaffMember_${entry.valid}`}>
                  {`${entry.user} - ${entry.role}(${entry.sms})`}
                </TableCell>
                <TableCell key={`${index}_EM_${entry.valid}`}>
                  {statusCheck(entry.role === '(EM)')}
                </TableCell>
                <TableCell key={`${index}_ValidUser_${entry.valid}`}>
                  {statusCheck(entry.valid)}
                </TableCell>
                <TableCell key={`${index}_CanLogin_${entry.valid}`}>
                  {statusCheck(entry.login_result.can_login)}
                </TableCell>
                <TableCell key={`${index}_CanAccessMP_${entry.valid}`}>
                  {statusCheck(entry.api_access.can_access_api)}
                </TableCell>
                <TableCell key={`${index}_LoginComments_${entry.valid}`}>
                  {entry?.success ? entry.success.success_msg : ''}
                </TableCell>
                <TableCell key={`${index}_FailureComments_${entry.valid}`}>
                  {entry?.failure ? entry.failure.fail_msg : ''}
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StaffCheckerResultsTable;
