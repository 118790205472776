import React, { useState, useContext } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import moment from 'moment';
import { iPad } from '../../../entities/Device';
import BatteryIcon from './BatteryIcon';
import DeviceStatus from '../../shared/DeviceStatus';
import { removeDeviceFromStation } from '../../../services/api/dashboard';
import { Clear } from '@mui/icons-material';
import MDModal from '../../shared/MDModal';
import { TenantContext } from '../../../context/TenantContext';
import { DashboardContext } from '../../../context/DashboardContext';

interface DeviceRowsProps {
  devices: iPad[];
}

const DeviceRows: React.FC<DeviceRowsProps> = ({ devices }) => {
  const style = { borderBottom: 'none' };

  const { selectedTenant, selectedElection } = useContext(TenantContext);
  const { refreshDashboard } = useContext(DashboardContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectediPad, setSelectediPad] = useState<iPad>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const removeDevice = async () => {
    if (selectediPad && selectedElection && selectedTenant) {
      setLoading(true);
      try {
        const request = {
          tenant: selectedTenant,
          electionDate: selectedElection,
          station: selectediPad.stationNumber,
          deviceId: selectediPad.id,
        };
        await removeDeviceFromStation(request);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setShowConfirmDelete(false);
          refreshDashboard();
        }, 3000);
        // eslint-disable-next-line
      } catch (error: any) {
        console.log('Error removing device');
        setError(error.message);
        setTimeout(() => setError(''), 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  const deviceClickHandler = (device: iPad) => {
    setSelectediPad(device);
    setShowConfirmDelete(true);
  };

  const closeHandler = () => {
    setSelectediPad(undefined);
    setShowConfirmDelete(false);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{'Name'}</TableCell>
          <TableCell>{'Battery'}</TableCell>
          <TableCell>{'Memory'}</TableCell>
          <TableCell>{'Status'}</TableCell>
          <TableCell>{'Unsynced'}</TableCell>
          <TableCell>{'Last Heartbeat'}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {devices.map((d, index) => (
          <TableRow key={`Pollstation-device-${d.id}-${index}`}>
            <TableCell
              style={style}
            >{`${d.firstName} ${d.lastName}`}</TableCell>
            <TableCell style={style}>
              <BatteryIcon
                batteryLevel={d.batteryLevel}
                online={d.openStatus}
              />
            </TableCell>
            <TableCell style={style}>{d.usedMemory} MB</TableCell>
            <TableCell style={style}>
              <DeviceStatus
                openStatus={d.openStatus}
                lastTimestamp={d.lastTimestamp}
                unsyncdCount={d.unsyncdCount}
              />
            </TableCell>
            <TableCell style={style}>{d.unsyncdCount}</TableCell>
            <TableCell style={style}>
              {moment(d.lastTimestamp * 1000).fromNow()}
            </TableCell>
            <TableCell style={style} align="center">
              <Tooltip title="Remove Device from Station">
                <IconButton size="small" onClick={() => deviceClickHandler(d)}>
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        {showConfirmDelete && (
          <MDModal
            title={'Are you sure you wish to remove this device?'}
            width={450}
            height={300}
            open={showConfirmDelete}
            onClose={closeHandler}
            success={success}
            loading={loading}
            errorMessage={error}
            content={
              <>
                <Typography variant="body1">
                  {'This will remove the device record from the station.'}
                </Typography>
                <Typography variant="caption">
                  {
                    '* Note: If the user is still logged in and heartbeating, then the record for the device WILL appear again.'
                  }
                </Typography>
              </>
            }
            actionBar={
              <div className="device-remove-modal-action">
                <Button
                  id="runTaskBtn"
                  color="inherit"
                  variant="contained"
                  size="small"
                  onClick={closeHandler}
                >
                  No
                </Button>
                <Button
                  id="runTaskBtn"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={removeDevice}
                >
                  Yes
                </Button>
              </div>
            }
          />
        )}
      </TableBody>
    </Table>
  );
};
export default React.memo(DeviceRows);
