import React, { useContext, useMemo } from 'react';
import { Typography, Table, TableBody, TableRow, Paper } from '@mui/material';
import { CustomerValidationReport } from '../../entities/Validations';
import './validation.css';
import { TenantContext } from '../../context/TenantContext';
import CustomerValidation from './CustomerValidation';

interface CustomerFacingResultsProps {
  data: CustomerValidationReport;
}

const CustomerFacingResults: React.FC<CustomerFacingResultsProps> = (
  props: CustomerFacingResultsProps
) => {
  const { validationsRunning } = useContext(TenantContext);

  const noData = useMemo(() => {
    return (
      props.data?.ballots?.messages?.length === 0 &&
      props.data?.register?.messages?.length === 0 &&
      props.data?.staff?.messages?.length === 0 &&
      props.data?.proxy?.messages?.length === 0
    );
  }, [props.data, validationsRunning]);

  return (
    <Paper
      style={{ padding: '1rem', marginBottom: '1rem', borderRadius: '10px' }}
    >
      <>
        <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
          {'Validation Findings (As reported to customer)'}
        </Typography>
        <hr style={{ width: '100%' }} />
        {noData && !validationsRunning && (
          <Typography variant="h6" gutterBottom>
            No Data
          </Typography>
        )}
        {noData && validationsRunning && (
          <Typography variant="h6" gutterBottom>
            Retrieving...
          </Typography>
        )}
        {!noData && (
          <div className="customer-finding">
            <Table>
              <TableBody>
                <TableRow key={'Ballots'} style={{ padding: '0.5rem' }}>
                  <CustomerValidation
                    label="Ballots"
                    validation={props.data?.ballots}
                  />
                </TableRow>
                <hr style={{ width: '100%' }} />
                <TableRow key={'Register'} style={{ padding: '0.5rem' }}>
                  <CustomerValidation
                    label="Register"
                    validation={props.data?.register}
                  />
                </TableRow>
                <hr style={{ width: '100%' }} />
                <TableRow key={'Proxy'} style={{ padding: '0.5rem' }}>
                  <CustomerValidation
                    label="Proxy"
                    validation={props.data?.proxy}
                  />
                </TableRow>
                <hr style={{ width: '100%' }} />
                <TableRow key={'Staff'} style={{ padding: '0.5rem' }}>
                  <CustomerValidation
                    label="Staff"
                    validation={props.data?.staff}
                  />
                </TableRow>
                <hr style={{ width: '100%' }} />
              </TableBody>
            </Table>
          </div>
        )}
      </>
    </Paper>
  );
};

export default React.memo(CustomerFacingResults);
