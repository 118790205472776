import { EMSType } from '../../../entities/Tenant';
import { AllQueryType } from './tenant';

/**
 * DataFileType.
 * @description The available types of query results.
 */
export type DataFileType =
  | AllQueryType
  | 'getRegisterEANs'
  | 'getRegisterDuplicates'
  | 'getAVBreakdown'
  | 'getElectorsCountWithEitherDOADOB'
  | 'getEAN'
  | 'getEANFiltered'
  | 'getMapProxyToRegister'
  | 'getFindDuplicateBallots'
  | 'getFindDuplicateRanges'
  | 'getStaffReport';

export type QueryMapping = {
  emsType: EMSType;
  key: DataFileType;
  columns: KeysType[];
};

/**
 * ReportTextMapping.
 * @description DataFileType to label mapping.
 */
export const ReportTextMapping: Record<DataFileType | AllQueryType, string> = {
  getRegisterEANs: 'Register-ean-by-pd',
  getRegisterDuplicates: 'Register-Duplicates',
  getAVBreakdown: 'AV-Breakdown',
  getElectorsCountWithEitherDOADOB: 'DOA-DOB-Count',
  getEAN: 'EAN',
  getEANFiltered: 'EAN-Filtered',
  getMapProxyToRegister: 'Proxy-Mappings',
  getFindDuplicateBallots: 'Ballotid-Duplicates',
  getFindDuplicateRanges: 'BallotRange-Duplicates',
  getStaffReport: 'Staff-Not-Added',
  all: '',
};
export type NameDescriptionType = {
  name: string;
  description: string;
};
export type SignedUrlType = NameDescriptionType & { url?: string };

export const DescriptionMapping: Record<DataFileType, SignedUrlType> = {
  getRegisterEANs: {
    name: 'Register-ean-by-pd',
    description: 'The description for getRegisterEANs. getRegisterEANs',
  },
  getRegisterDuplicates: {
    name: 'Register-Duplicates',
    description:
      'The description for getRegisterDuplicates. getRegisterDuplicates',
  },
  getAVBreakdown: {
    name: 'AV-Breakdown',
    description: 'The description for getAVBreakdown. getAVBreakdown',
  },
  getElectorsCountWithEitherDOADOB: {
    name: 'DOA-DOB-Count',
    description:
      'The description for getElectorsCountWithEitherDOADOB. getElectorsCountWithEitherDOADOB',
  },
  getEAN: { name: 'EAN', description: 'The description for getEAN. getEAN' },
  getEANFiltered: {
    name: 'EAN-Filtered',
    description: 'The description for getEANFiltered. getEANFiltered',
  },
  getMapProxyToRegister: {
    name: 'Proxy-Mappings',
    description:
      'The description for getMapProxyToRegister. getMapProxyToRegister',
  },
  getFindDuplicateBallots: {
    name: 'Ballotid-Duplicates',
    description:
      'The description for getFindDuplicateBallots. getFindDuplicateBallots',
  },
  getFindDuplicateRanges: {
    name: 'BallotRange-Duplicates',
    description:
      'The description for getFindDuplicateRanges. getFindDuplicateRanges',
  },
  getStaffReport: {
    name: 'Staff-Not-Added',
    description: 'The description for getStaffReport. getStaffReport',
  },
  all: { name: 'All queries', description: 'All available queries files.' },
};

export type EAN_KEYS =
  | 'station'
  | 'register areas'
  | 'ballot areas'
  | 'Missing from Ballot'
  | 'Missing from Register';

export type STAFF_REPORT_KEYS =
  | 'role'
  | 'stationnumber'
  | 'contactNumber'
  | 'firstName'
  | 'lastName'
  | 'rejection reason';
// }
export type EAN_FILTERED_KEYS =
  | 'station'
  | 'register areas'
  | 'ballot areas'
  | 'Missing from Ballot'
  | 'Missing from Register';

export type REGISTER_EANS_KEYS =
  | 'polling station number'
  | 'pd code'
  | 'electoral area name(s) on register'
  | 'ballot(s) that will be issued';

export type AV_BREAKDOWN_KEYS =
  | 'Postal'
  | 'Proxy'
  | 'Postal Proxy'
  | 'has hashes'
  | 'has @'
  | 'Anon';

export type DOA_DOB_COUNT_KEYS =
  | 'date of attainment'
  | 'date of birth'
  | 'short date of birth'
  | 'short date of attainment';

/**
 * getFindDuplicateRanges
 */
export type BALLOT_RANGE_DUPLICATES_KEYS =
  | 'station number'
  | 'electoral area name'
  | 'election ref';

export type BALLOT_ID_DUPLICATES = '';

// getMapProxyToRegister
export type MapProxyToRegisterXpress =
  | 'proxy file elector no.'
  | 'pd code'
  | 'proxy file elector no.'
  | 'id'
  | 'elector id'
  | 'pd code'
  | 'eno'
  | 'title'
  | 'first name'
  | 'initials'
  | 'surname'
  | 'suffix'
  | 'eligibility'
  | 'anonymous'
  | 'elector flags'
  | 'franchise flag'
  | 'absent flag'
  | 'nationality'
  | 'date of birth'
  | 'date of attainment'
  | 'residing address uprn'
  | 'residing address 1'
  | 'residing address 2'
  | 'residing address 3'
  | 'residing address 4'
  | 'residing address 5'
  | 'residing address 6'
  | 'residing address postcode'
  | 'qualifying address uprn'
  | 'qualifying address 1'
  | 'qualifying address 2'
  | 'qualifying address 3'
  | 'qualifying address 4'
  | 'qualifying address 5'
  | 'qualifying address 6'
  | 'qualifying address postcode'
  | 'polling station name'
  | 'polling station number'
  | 'polling station address 1'
  | 'polling station address 2'
  | 'polling station address 3'
  | 'polling station address 4'
  | 'polling station address 5'
  | 'polling station postcode'
  | 'election name'
  | 'election date'
  | 'polling district'
  | 'pd name'
  | 'electoral area level(s)'
  | 'electoral area name(s)'
  | 'officer name'
  | 'officer title'
  | 'authority address 1'
  | 'authority address 2'
  | 'authority address 3'
  | 'authority address 4'
  | 'authority address 5'
  | 'authority address 6'
  | 'authority address 7'
  | 'authority address 8';

/**
 * getRegisterDuplicates
 */
export type REGISTER_DUPLICATES_KEYS = '';

export type KeysType = EAN_KEYS &
  EAN_FILTERED_KEYS &
  REGISTER_EANS_KEYS &
  AV_BREAKDOWN_KEYS &
  DOA_DOB_COUNT_KEYS;

export type QueryResultType = Record<keyof KeysType, string>;
