export enum AlarmStatus {
  none = '',
  good = 'Good',
  alert = 'Alert',
  warning = 'Warning',
}

export const AlarmColours: Record<AlarmStatus, string> = {
  [AlarmStatus.none]: '#D9EDF7',
  [AlarmStatus.good]: 'rgb(111, 183, 113)',
  [AlarmStatus.alert]: 'rgb(255, 212, 94)',
  [AlarmStatus.warning]: 'rgb(255, 73, 93)',
};

export const Alarming = [AlarmStatus.alert, AlarmStatus.warning];
