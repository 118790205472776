import * as React from 'react';
import './OverviewCircle.css';

export interface OverviewCircleProps {
  label: string | number;
  colour: 'red' | 'green' | 'yellow';
}

const OverviewCircle: React.FC<OverviewCircleProps> = (
  props: OverviewCircleProps
) => {
  const { label, colour } = props;
  return <div className={`circle +${colour}`}>{label}</div>;
};

export default OverviewCircle;
