import * as React from 'react';
import { useContext, useMemo, useEffect } from 'react';
import { sortAlphaNumeric } from '../../../../utils/utils';
import { TenantContext } from '../../../../context/TenantContext';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import { DropDown, DropDownOption } from '../../../shared/DropDown';

const TenantPollstationPicker: React.FC = () => {
  const { tenants } = useContext(TenantContext);
  const {
    fetching,
    selectedTenant,
    setSelectedTenant,
    selectedPollstation,
    setSelectedPollstation,
    tenantPollstationObjects,
    selectedElectionDate,
    setSelectedElectionDate,
    resetForm,
  } = useContext(TenantPollstationContext);

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  useEffect(() => () => resetForm(), []);

  const pollstationNames: DropDownOption[] = useMemo(() => {
    if (tenantPollstationObjects) {
      return [
        { value: '', display: '' },
        ...tenantPollstationObjects
          .filter((ps) =>
            ps && ps.electionDate
              ? ps.electionDate.toString() === selectedElectionDate
              : false
          )
          .map((ps) => ({
            display: `${ps.number} - ${ps.name}`,
            value: ps.pollStationId,
          }))
          .sort((a, b) => sortAlphaNumeric(a.value, b.value)),
      ];
    } else return [];
  }, [tenantPollstationObjects, selectedElectionDate]);

  const electionDates: string[] = useMemo(() => {
    if (tenantPollstationObjects) {
      const dateList = tenantPollstationObjects.reduce(
        (dates: string[], ps) => {
          if (ps && ps.electionDate) {
            dates.push(ps.electionDate.toString());
          }
          return dates;
        },
        []
      );
      const uniqueDates = new Set(dateList);
      const dateArr = Array.from(uniqueDates).sort(
        (a, b) => +new Date(a) - +new Date(b)
      );
      return ['', ...dateArr];
    } else {
      return [];
    }
  }, [tenantPollstationObjects]);

  return (
    <>
      <DropDown
        disabled={fetching}
        options={tenantNames}
        name="Tenants"
        label="Tenants"
        value={selectedTenant}
        onSelect={setSelectedTenant}
        fullWidth
      />
      <DropDown
        disabled={fetching || !selectedTenant}
        options={electionDates}
        name="Election Date"
        label="Date"
        value={selectedElectionDate}
        onSelect={setSelectedElectionDate}
        fullWidth
      />
      <DropDown
        disabled={fetching || !selectedElectionDate}
        options={pollstationNames}
        name="Pollstation"
        label="Pollstation"
        value={selectedPollstation?.pollStationId || ''}
        onSelect={setSelectedPollstation}
        fullWidth
      />
    </>
  );
};

export default TenantPollstationPicker;
