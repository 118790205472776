import React from 'react';
import SupportTaskGroup from './SupportTaskGroup';
import ElectionDayTasks from './ElectionDaySupport/ElectionDayTaskList';
import UtilitiesTaskList from './UtilityTasks/UtilitiesTaskList';

const SupportTasksList = (): JSX.Element => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <SupportTaskGroup
        id="panel1"
        title={'Utilities'}
        content={<UtilitiesTaskList />}
        defaultExpanded={true}
        handleChange={handleChange}
        expanded={expanded}
      />
      <SupportTaskGroup
        id="panel2"
        title={'Election Day Support'}
        content={<ElectionDayTasks />}
        defaultExpanded={false}
        handleChange={handleChange}
        expanded={expanded}
      />
    </>
  );
};

export default SupportTasksList;
