import React, { useEffect, useState, useMemo, useContext } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { TenantContext } from '../../context/TenantContext';
import { DropDown } from '../shared/DropDown';
import './Overview.css';
import { getUpcomingDate } from '../../utils/utils';

export const accounts = [
  {
    id: 'mdidox',
    name: 'MDIdox - 5 Tenants',
    location: 'https://dashboards.mdidox.moderndemocracy.com',
  },
  {
    id: 'central',
    name: 'Central - 6 Tenants',
    location: 'https://dashboards.central.moderndemocracy.com',
  },
  {
    id: 'east',
    name: 'East - 8 Tenants',
    location: 'https://dashboards.east.moderndemocracy.com',
  },
  {
    id: 'idox',
    name: 'Idox - 4 Tenants',
    location: 'https://dashboards.idox.moderndemocracy.com',
  },
  {
    id: 'south',
    name: 'South - 9 Tenants',
    location: 'https://dashboards.south.moderndemocracy.com',
  },
  {
    id: 'west',
    name: 'West - 5 Tenants',
    location: 'https://dashboards.west.moderndemocracy.com',
  },
  {
    id: 'wales',
    name: 'Wales - 12 Tenants',
    location: 'https://dashboards.wales.moderndemocracy.com',
  },
];

const getCurrentAccount = (): string => {
  let acc = '';
  const url = window.location.href;
  if (!url.includes('localhost')) {
    const id = url.split('.')[1];
    const found = accounts.find((a) => a.id === id);
    if (found) acc = found.name;
  }
  return acc;
};
// eslint-disable-next-line
const HeaderControls = (props: any) => {
  const { tenantDetails, setElection } = useContext(TenantContext);

  const dates = useMemo(() => {
    if (!tenantDetails) return [];
    const uniqueDates: string[] = [];
    for (const t of tenantDetails) {
      for (const d of t.electionDates) {
        if (!uniqueDates.includes(d)) uniqueDates.push(d);
      }
    }
    return uniqueDates.sort();
  }, [tenantDetails]);

  const defaultAcc = getCurrentAccount();
  const [selectedAccount, setSelectedAccount] = useState<string>(defaultAcc);
  const [selectedDate, setSelectedDate] = useState<string>(
    getUpcomingDate(dates)
  );

  const accountChange = (account: string) => {
    if (account) {
      const env = accounts.find((env) => env.name === account);
      if (env) {
        window.location.assign(`${env.location}/overview`);
        setSelectedAccount(account);
      }
    }
  };

  const dateChange = (date: string) => {
    if (date) {
      setSelectedDate(date);
      setElection(date);
    }
  };

  const reloadClick = () => {
    props.reloadData();
  };

  useEffect(() => {
    if (dates) {
      setElection(getUpcomingDate(dates));
    }
  }, [dates]);

  return (
    <>
      <div className="env-select">
        <DropDown
          className="select-control"
          options={accounts.map((e) => e.name)}
          name="Accounts"
          label={'Accounts'}
          value={selectedAccount}
          onSelect={accountChange}
        />
        <DropDown
          className="select-control"
          options={dates}
          name="Date"
          label="Date"
          value={selectedDate}
          onSelect={dateChange}
        />
      </div>
      <div className="header">
        <Typography variant="h5">{`Polling Stations (${props.totalStations})`}</Typography>
        <div>
          <Typography variant="caption">
            Last Fetched: {new Date(props.lastFetched).toLocaleTimeString()}
          </Typography>
          <IconButton onClick={reloadClick} disabled={props.isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default React.memo(HeaderControls);
