import React, { useEffect, useRef } from 'react';
import { Modal } from '@mui/material';
import { Close, Done, Error } from '@mui/icons-material';
import Loader from './Loader';
import './MDModal.css';

export interface MDModalProps {
  id?: string;
  title?: JSX.Element | string;
  open: boolean;
  onClose: () => void;
  content: JSX.Element | string;
  actionBar?: JSX.Element;
  width?: number;
  height?: number;
  loading?: boolean;
  errorMessage?: string;
  success?: boolean;
}

const MDModal: React.FC<MDModalProps> = ({
  id,
  title,
  open,
  onClose,
  content,
  actionBar,
  width = 500,
  height,
  loading,
  errorMessage,
  success,
}: MDModalProps) => {
  const bodyRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLInputElement>(null);

  const scrollTo = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollTo(statusRef);
  }, [success, loading, errorMessage, statusRef]);

  useEffect(() => {
    scrollTo(bodyRef);
  }, [bodyRef, content]);

  return (
    <Modal id={id} open={open} onClose={onClose}>
      <div
        className="c-modal"
        style={{
          width: `${width}px`,
          height: `${height ? `${height}px` : undefined}`,
        }}
      >
        <div className="c-modal__head">
          {title}
          <div className="+right" style={{ cursor: 'pointer' }}>
            <Close onClick={onClose} />
          </div>
        </div>
        <div className="c-modal__body">
          {content}
          {loading && (
            <div
              className="c-modal__loader"
              onClick={(e) => e.preventDefault()}
            >
              <Loader message="" />
            </div>
          )}
          <div ref={bodyRef} />
        </div>
        <div ref={statusRef} className="c-modal__status">
          {success && (
            <>
              <Done />
              <p>{'Complete'}</p>
            </>
          )}
          {errorMessage && (
            <>
              <Error />
              <p>{errorMessage}</p>
            </>
          )}
        </div>
        <div className="c-modal__footer">{actionBar}</div>
      </div>
    </Modal>
  );
};

export default React.memo(MDModal);
