import React, { useEffect, useState, useContext, useMemo } from 'react';
import { TextField, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { TenantContext } from '../../context/TenantContext';
import { UsersContext } from '../../context/UsersContext';
import { DropDown } from '../shared/DropDown';
import FilterBar from '../shared/FilterBar';
import CreateUserModal from './Modals/CreateUser';
import { TenantPollstationContextProvider } from '../../context/TenantPollstationContext';

const UsersFilterBar: React.FC = () => {
  const {
    tenants,
    selectedTenant,
    setTenant,
    electionsForTenant,
    selectedElection,
    setElection,
  } = useContext(TenantContext);
  const { fetching, searchVal, setSearchVal } = useContext(UsersContext);

  const [createUser, createUserSet] = useState(false);

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  useEffect(() => {
    if (!selectedElection) setElection('All');
  }, [selectedElection]);

  return (
    <FilterBar>
      <DropDown
        options={tenantNames}
        name="Tenant"
        label="Tenant"
        value={selectedTenant}
        onSelect={(value) => setTenant(value)}
      />
      <DropDown
        options={['All', ...electionsForTenant]}
        name="Election Date"
        label="Election Date"
        value={selectedElection}
        onSelect={(value) => setElection(value)}
      />
      <div style={{ width: '300px' }}>
        <TextField
          disabled={fetching || !selectedTenant}
          id="userSearchValue"
          label="Search ID, Phone or Name"
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          variant="standard"
          fullWidth
        />
      </div>
      <Button
        color="primary"
        size="small"
        startIcon={<Add />}
        disabled={fetching || !selectedTenant}
        onClick={() => createUserSet(true)}
      >
        Create new user
      </Button>
      <>
        {createUser && (
          <TenantPollstationContextProvider>
            <CreateUserModal
              open={createUser}
              onClose={() => createUserSet(false)}
            />
          </TenantPollstationContextProvider>
        )}
      </>
    </FilterBar>
  );
};

export default React.memo(UsersFilterBar);
