import React from 'react';
import { Typography, Table, TableCell, TableRow, Card } from '@mui/material';

export interface BallotInfoProps {
  uim: string;
  start: string;
  end: string;
  colour: string;
}

export const BallotInfo: React.FC<BallotInfoProps> = (
  props: BallotInfoProps
) => {
  const { uim, start, end, colour } = props;
  return (
    <Card style={{ padding: '1rem 0.5rem', margin: '2rem 0' }} raised>
      <Typography variant="body1" gutterBottom>
        Ballot Info
      </Typography>
      <Table padding="none">
        <TableRow>
          <TableCell>
            <Typography variant="body1">UIM</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">Start</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">End</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">Colour </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2">{uim}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{start}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{end}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption">{colour}</Typography>
          </TableCell>
        </TableRow>
      </Table>
    </Card>
  );
};
