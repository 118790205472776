import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Paper, TextField, CircularProgress } from '@mui/material';
import '../styles.css';

interface LoginProps {
  handleLoginSuccess: () => void;
}

const Login: React.FC<LoginProps> = ({ handleLoginSuccess }: LoginProps) => {
  const history = useHistory();
  const [usernameVal, setUsername] = useState('');
  const [passwordVal, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginEnabled, setEnabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (usernameVal && passwordVal) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [usernameVal, passwordVal]);

  const handleSignIn = async () => {
    Promise.resolve(setLoading(true))
      .then(() =>
        Auth.signIn(usernameVal, passwordVal)
          .then((cognitoUser) => {
            history.push('/');
            handleLoginSuccess();
            console.log(cognitoUser);
          })
          .catch((error) => {
            setUsername('');
            setPassword('');
            setError(error.message);
            setTimeout(() => setError(null), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  const keyDown = ({ key }: { key: string }) => {
    if (key === 'Enter') handleSignIn();
  };

  return (
    <div className="login">
      <Paper className="dialog" elevation={20} style={{ borderRadius: '10px' }}>
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={usernameVal}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={keyDown}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={passwordVal}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={keyDown}
        />
        <Button
          id="logIn"
          disabled={!loginEnabled}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleSignIn}
        >
          {loading ? (
            <CircularProgress size={30} color="secondary" />
          ) : (
            'Log In'
          )}
        </Button>
        {error !== null && <p className="error">{error}</p>}
      </Paper>
    </div>
  );
};

export default React.memo(Login);
