import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export interface DropDownOption {
  value: string;
  display: string;
}

export interface DropDownProps {
  name: string;
  value?: string;
  disabled?: boolean;
  options: (string | DropDownOption)[];
  label?: string;
  onSelect(selected: string): void;
  className?: string;
  fullWidth?: boolean;
}

export const DropDown: React.FC<DropDownProps> = (props: DropDownProps) => {
  const {
    name,
    value,
    disabled,
    options,
    label,
    onSelect,
    className,
    fullWidth,
  } = props;

  const inputId = `${name}DropDown`;

  return (
    <FormControl
      variant="standard"
      style={{
        width: fullWidth ? '100%' : '10rem',
        marginBottom: '10px',
      }}
      disabled={disabled || !options.length}
    >
      {label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
      <Select
        onChange={(event: SelectChangeEvent) =>
          onSelect(event.target.value as string)
        }
        MenuProps={{ PaperProps: { style: { maxHeight: '20rem' } } }}
        className={className}
        value={value}
        inputProps={{
          id: inputId,
        }}
      >
        {options.map((option: string | DropDownOption) => {
          if (typeof option === 'string') {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={option.display} value={option.value}>
                {option.display}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};
