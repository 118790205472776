import React from 'react';
import { Typography, Tooltip, IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';

export interface LastFetchTimeProps {
  lastFetch: number;
  onRefreshClick: () => void;
  disabled: boolean;
}

const LastFetchTime: React.FC<LastFetchTimeProps> = ({
  lastFetch,
  onRefreshClick,
  disabled,
}: LastFetchTimeProps) => {
  return (
    <div>
      <div style={{ float: 'right' }}>
        <Tooltip title={'Refresh Dashboard'}>
          <span style={{ marginRight: '0.1rem' }}>
            <IconButton onClick={onRefreshClick} disabled={disabled}>
              <Refresh />
            </IconButton>
          </span>
        </Tooltip>
        <Typography variant="caption">
          Last fetched at {new Date(lastFetch).toLocaleTimeString('en-GB')}
        </Typography>
      </div>
    </div>
  );
};

export default LastFetchTime;
