import React, { useState, useMemo, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import MDModal from '../../shared/MDModal';
import { DropDown } from '../../shared/DropDown';

import { User, Assignment, AvailableAssignments } from '../../../entities/User';
import {
  getAvailableAssignments,
  editUserAssignment,
} from '../../../services/api/dashboard';

import { sortAlphaNumeric } from '../../../utils/utils';

interface EditAssignmentModalProps {
  open: boolean;
  onClose: () => void;
  tenant: string;
  user: User;
  assignment: Assignment;
}

const EditAssignmentModal: React.FC<EditAssignmentModalProps> = ({
  open,
  onClose,
  tenant,
  user,
  assignment,
}) => {
  const roles = ['Presiding Officer', 'Polling Clerk'];

  const [availableAssignments, setAvailableAssignments] = useState<
    AvailableAssignments[]
  >([]);
  const [selectedStation, setSelectedStation] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState(assignment.role);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const stations = useMemo(() => {
    if (availableAssignments) {
      return [
        '',
        ...(availableAssignments
          .find((a) => a.electionDate === assignment.date)
          ?.stations.sort(sortAlphaNumeric) || []),
      ];
    } else return [];
  }, [availableAssignments, assignment]);

  const getAssignments = async () => {
    try {
      setLoading(true);
      const res = await getAvailableAssignments({ tenant });
      setAvailableAssignments(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const editAssignment = async () => {
    try {
      setLoading(true);
      const params = {
        tenant,
        userId: user.id,
        electionDate: assignment.date,
        station: selectedStation,
        role: selectedRole,
      };
      await editUserAssignment(params);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssignments();
  }, []);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={'Edit Assignment'}
      loading={loading}
      success={success}
      errorMessage={errorMessage}
      content={
        <div>
          <Typography gutterBottom>Election Date: {assignment.date}</Typography>
          <Typography gutterBottom>
            Current Station: {assignment.station}
          </Typography>
          <DropDown
            disabled={loading || !availableAssignments}
            options={stations}
            name="Station"
            label={'Station'}
            value={selectedStation}
            onSelect={(value) => setSelectedStation(value)}
            fullWidth
          />
          <DropDown
            disabled={loading || !selectedStation}
            options={roles}
            name="Role"
            label={'Role'}
            value={selectedRole}
            onSelect={(value) => setSelectedRole(value)}
            fullWidth
          />
        </div>
      }
      actionBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading || success || !selectedStation}
            onClick={editAssignment}
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(EditAssignmentModal);
