import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import DeleteElectionTypeModal from '../ElectionDaySupport/Modals/DeleteElectionTypeModal';

interface RadioGroupProps {
  title: string;
  ariaLabel?: string;
  selectedValue: string;
  setValue(value: string): void;
  options: (string | RadioOption)[];
  fullWidth?: boolean;
  onDelete(electionType: string): void;
}

export interface RadioOption {
  value: string;
  label: string;
}

export const ElectionTypeRadioGroup: React.FC<RadioGroupProps> = ({
  title,
  ariaLabel,
  selectedValue,
  setValue,
  options = [],
  fullWidth = false,
  onDelete,
}: RadioGroupProps) => {
  const [displayDelete, setDisplayDelete] = useState(false);
  const [selectedElectionType, setSelectedElectionType] = useState<string>('');

  const onClose = () => {
    setDisplayDelete(false);
  };

  return (
    <FormControl
      component="fieldset"
      style={{ width: fullWidth ? '100%' : undefined }}
    >
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        id={`${title}-radio-group`}
        aria-label={ariaLabel}
        name={title}
        value={selectedValue}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((option: string | RadioOption) => {
          const optionValue =
            typeof option === 'string' ? option : option.value;
          return (
            <div
              key={optionValue}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                id={optionValue}
                key={optionValue}
                value={optionValue}
                control={<Radio color="primary" />}
                label={optionValue}
                style={{ minWidth: 230 }}
              />
              <Tooltip title={`Delete ${optionValue}`}>
                <IconButton
                  id={`Delete Election Type ${optionValue} Button`}
                  onClick={() => {
                    setDisplayDelete(true);
                    setSelectedElectionType(optionValue);
                  }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
      </RadioGroup>
      {displayDelete && (
        <DeleteElectionTypeModal
          open={displayDelete}
          onClose={onClose}
          electionType={selectedElectionType}
          onDelete={onDelete}
        />
      )}
    </FormControl>
  );
};
