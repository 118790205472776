import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { GenericModalProps } from '../../GenericModalProps';
import MDModal from '../../../shared/MDModal';
import { GetGeographyFileRequest } from '../../../../entities/Support';
import { GeographyFile } from '../../../../entities/GeographyFile';

interface ParsedDataItem {
  ElectorID: string;
  PDCode: string;
  RollNo: string;
  ElectorName: string;
  IsDeceased: string;
  FranchiseMarker: string;
  'AV IsPostal': string;
  'AV IsProxy': string;
  DOB: string;
  PropertyNum: string;
  StreetDescriptor: string;
  TownName: string;
  PostCode: string;
  PlaceName: string;
  StationCode: string;
  PDName: string;
}

interface DcConverterProps extends GenericModalProps {
  geographyFileFetch: (
    params: GetGeographyFileRequest
  ) => Promise<GeographyFile>;
  title: string;
}

function DcConverterModal(props: DcConverterProps) {
  const { title, open, onClose, geographyFileFetch } = props;
  const [geography, setGeogrpahy] = useState<GeographyFile>({
    RO: { name: '', title: 'Returning Officer' },
    authority: {
      AuthorityAddress1: '',
      AuthorityAddress2: '',
      AuthorityAddress3: '',
      AuthorityAddress4: '',
      AuthorityAddress5: '',
    },
    geography: [],
  });
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleTenantChange = (event: SelectChangeEvent<string>) => {
    setSelectedTenant(event.target.value);
  };

  useEffect(() => {
    if (selectedTenant !== '') {
      const params = {
        tenant: selectedTenant,
      };
      Promise.resolve(setLoading(true))
        .then(() =>
          geographyFileFetch(params)
            .then((response) => {
              console.log(response);
              setGeogrpahy(response);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => setLoading(false));
    }
  }, [selectedTenant]);

  const handleFilesUpload = async (files: FileList | null) => {
    if (files) {
      const zip = new JSZip();
      const fileArray = Array.from(files);

      for (let index = 0; index < fileArray.length; index++) {
        const file = files[index];
        console.log(`Processing file: ${file.name}`);
        await new Promise<void>((resolve) => {
          parseCsvFile(file, (parsedData: ParsedDataItem[]) => {
            const transformedData = parsedData
              .filter(
                (item) =>
                  item && item.ElectorID !== undefined && item.ElectorID !== ''
              )
              .map((item) => {
                const matchedEntry = geography.geography.find(
                  (entry: any) => entry['PD Code'] === item.PDCode
                );
                if (!matchedEntry) {
                  setErrorMessage(
                    `${item.PDCode} was not found in Geography file.`
                  );
                  throw `${item.PDCode} was not found in Geography file.`;
                }
                const electoralAreaNames = extractElectoralAreaNames(
                  matchedEntry.electoralAreas
                );

                const electoralAreaLevels = extractElectoralAreaLevels(
                  matchedEntry.electoralAreas
                );

                return {
                  'Elector ID': item.ElectorID,
                  'PD Code': item.PDCode,
                  ENO: item.RollNo ? item.RollNo.padStart(8, '0') : '',
                  Title: '',
                  'First Name': item.ElectorName
                    ? item.ElectorName != 'N'
                      ? item.ElectorName.split(',')[1].trim()
                      : 'N'
                    : '',
                  Initials: '',
                  Surname: item.ElectorName
                    ? item.ElectorName != 'N'
                      ? item.ElectorName.split(',')[0].trim()
                      : 'N'
                    : '',
                  Suffix: '',
                  Eligibility: item.IsDeceased
                    ? item.IsDeceased == '0' ||
                      item.IsDeceased.toLocaleUpperCase() == 'FALSE'
                      ? 'Eligible'
                      : 'Ineligible'
                    : '',
                  Anonymous: item.FranchiseMarker
                    ? item.FranchiseMarker.indexOf('N') !== -1
                      ? 'Anonymous'
                      : ''
                    : '',
                  'Elector Flags': `${
                    item.FranchiseMarker ? item.FranchiseMarker : ''
                  }${
                    item['AV IsPostal']
                      ? item['AV IsPostal'].toLocaleUpperCase() === 'TRUE' ||
                        item['AV IsPostal'] == '1'
                        ? 'A'
                        : ''
                      : ''
                  }${
                    item['AV IsProxy']
                      ? item['AV IsProxy'].toLocaleUpperCase() === 'TRUE' ||
                        item['AV IsProxy'] == '1'
                        ? 'P'
                        : ''
                      : ''
                  }`,
                  'Franchise Flag': item.FranchiseMarker,
                  'Absent Flag': `${
                    item['AV IsPostal']
                      ? item['AV IsPostal'].toLocaleUpperCase() === 'TRUE' ||
                        item['AV IsPostal'] == '1'
                        ? 'A'
                        : ''
                      : ''
                  }${
                    item['AV IsProxy']
                      ? item['AV IsProxy'].toLocaleUpperCase() === 'TRUE' ||
                        item['AV IsProxy'] == '1'
                        ? 'P'
                        : ''
                      : ''
                  }`,
                  Nationality: '',
                  'Date Of Birth': item.DOB,
                  'Date Of Attainment': '',
                  'Residing Address UPRN': '',
                  'Residing Address 1': item.PropertyNum,
                  'Residing Address 2': item.StreetDescriptor,
                  'Residing Address 3': item.TownName,
                  'Residing Address 4': '',
                  'Residing Address 5': '',
                  'Residing Address 6': '',
                  'Residing Address Postcode': item.PostCode,
                  'Qualifying Address UPRN': '',
                  'Qualifying Address 1': '',
                  'Qualifying Address 2': '',
                  'Qualifying Address 3': '',
                  'Qualifying Address 4': '',
                  'Qualifying Address 5': '',
                  'Qualifying Address 6': '',
                  'Qualifying Address Postcode': '',
                  'Polling Station Name': item.PlaceName,
                  'Polling Station Number': item.StationCode,
                  'Polling Station Address 1': '',
                  'Polling Station Address 2': '',
                  'Polling Station Address 3': '',
                  'Polling Station Address 4': '',
                  'Polling Station Address 5': '',
                  'Polling Station Postcode': '',
                  'Election Name': '',
                  'Election Date': '',
                  'Polling District': item.PDName,
                  'PD Name': item.PDName,
                  'Electoral Area Level(s)': electoralAreaLevels,
                  'Electoral Area Name(s)': electoralAreaNames,
                  'Officer Name': geography.RO.name,
                  'Officer Title': geography.RO.title,
                  'Authority Address 1': geography.authority.AuthorityAddress1,
                  'Authority Address 2': geography.authority.AuthorityAddress2,
                  'Authority Address 3': geography.authority.AuthorityAddress3,
                  'Authority Address 4': geography.authority.AuthorityAddress4,
                  'Authority Address 5': geography.authority.AuthorityAddress5,
                  'Authority Address 6': '',
                  'Authority Address 7': '',
                  'Authority Address 8': '',
                };
              });
            if (transformedData) {
              const csvData = Papa.unparse(transformedData);

              // Filter out empty rows from csvData
              const filteredCsvData = csvData
                .split('\n')
                .filter((row) => !/^\s*,*\s*$/.test(row))
                .join('\n');
              console.log(`Processed ${file.name}`);
              zip.file(`processed_${file.name}`, filteredCsvData);
              resolve();
            }
          });
        });

        if (index === files.length - 1) {
          await new Promise<void>((resolve) => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'processed_data.zip');
              console.log('Zip file generated');
              resolve();
            });
          });
        }
      }
    }
  };

  const parseCsvFile = (
    file: File,
    onDataParsed: (parsedData: ParsedDataItem[]) => void
  ) => {
    Papa.parse<ParsedDataItem>(file, {
      complete: (result) => {
        const parsedData = result.data;
        onDataParsed(parsedData);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      errorMessage={errorMessage}
      content={
        <Container component="div" maxWidth="xs">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
            <Select
              labelId="tenant-select-label"
              id="tenant-select"
              value={selectedTenant}
              onChange={handleTenantChange}
              label="Select Tenant"
              style={{ margin: '10px 0' }}
            >
              <MenuItem value="brent">Brent</MenuItem>
              <MenuItem value="fenland">Fenland District Council</MenuItem>
              <MenuItem value="gosport">Gosport County Council</MenuItem>
              <MenuItem value="rbwindosr">
                Royal Borough of Windsor and Maidenhead
              </MenuItem>
              <MenuItem value="staffordshire">Staffordshire</MenuItem>
              <MenuItem value="vale">Vale of Glamorgan</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" component="label">
            Upload File(s)
            <input
              type="file"
              accept=".csv"
              multiple
              onChange={(e) => handleFilesUpload(e.target.files)}
              hidden
            />
          </Button>
        </Container>
      }
    />
  );
  function extractElectoralAreaNames(input: any[]): string {
    const electoralAreaNames = input
      .map((item: any) => item.electoralAreaName)
      .filter((level: string) => level !== '');
    return electoralAreaNames.join(', ');
  }

  function extractElectoralAreaLevels(input: any[]): string {
    const electoralAreaLevels = input
      .map((item: any) => item.electoralAreaLevel)
      .filter((level: string) => level !== '');
    return electoralAreaLevels.join(', ');
  }
}

export default DcConverterModal;
