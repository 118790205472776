import React, { useMemo } from 'react';
import { Info, Warning, Cancel } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import {
  CustomerValidationMessage,
  ValidationInfo,
  CustomerValidationMessages,
} from '../../entities/Validations';
import parse from 'html-react-parser';

interface CustomerValidationProps {
  label: string;
  validation: CustomerValidationMessages;
}

const CustomerValidation: React.FC<CustomerValidationProps> = (
  props: CustomerValidationProps
) => {
  const formatTimestamp = (stamp: string): string => {
    const [d, m, y, h, min, sec] = stamp.split('_');
    return `${d}-${m}-${y} ${h}:${min}:${sec}`;
  };

  const renderValidationBlock = (validation: ValidationInfo) => {
    let icon = null;
    switch (validation.icon) {
      case 'info':
        icon = <Info fontSize="small" color="primary" />;
        break;
      case 'warning':
        icon = <Warning fontSize="small" style={{ color: 'orange' }} />;
        break;
      case 'error':
        icon = <Cancel fontSize="small" color="error" />;
        break;
    }
    return (
      <Tooltip
        title={validation?.help ? parse(getHelpText(validation.help)) : ''}
      >
        <div className="render-validation-status">
          {icon}
          <div className="render-validation-status-text">
            <Typography variant="body1" gutterBottom align="left">
              {validation.message}
            </Typography>
            <Typography variant="caption" gutterBottom align="left">
              {validation?.file || ''}
            </Typography>
          </div>
        </div>
      </Tooltip>
    );
  };

  const list = useMemo(() => {
    const list = [];
    if (props.validation?.messages) {
      for (const msg of props.validation.messages) {
        if ('validation' in msg) {
          const m = msg as CustomerValidationMessage;
          for (const v of m?.validation || []) {
            list.push(<>{renderValidationBlock(v)}</>);
          }
        } else {
          if ('icon' in msg) {
            const m = msg as ValidationInfo;
            list.push(<>{renderValidationBlock(m)}</>);
          }
        }
      }
    }
    return list;
  }, [props.validation]);

  return (
    <>
      <Typography variant="h6" align="left">
        {props.label}
      </Typography>
      {props.validation?.batchId ? (
        <>
          <Typography variant="body2" align="left" gutterBottom>
            {`Latest Upload @ ${formatTimestamp(props.validation.batchId)}`}
          </Typography>
          {list}
        </>
      ) : (
        <>
          <Typography variant="body2" align="left">
            {'No File Found'}
          </Typography>
        </>
      )}
    </>
  );
};

const getHelpText = (helpEnum: string) => {
  switch (helpEnum) {
    case 'REG_DUPLICATE_ELECTORS':
      return '<p>Duplicate registered electors found in file.</p><p>Possible cause and resolutions: </p><br></br><ul className="inline-list"><li>This file cannot be uploaded. Please remove the affected file by clicking the REMOVE button</li><li>Re-export a new batch of Register files from your EMS system and ensure they are in CSV or TSV format.</li><li>Upload the files into the Polling Station Registers area provided in the Election Files area.</li><li>Once validated, press the UPLOAD button to begin importing your register data</li></ul><p><b>*NOTE: If the issue persists, please contact support for assistance.</b></p>';
    case 'REG_INVALID_ABSENT_FLAG_HASHES':
      return '<p>Invalid Absent Flag found in file, hashes found.</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Open the register files and locate the absent flag column</li><li>Review the files to see if you have any entries in this column contains a hash (“#”) value.</li><li>If this is intentional and comes directly from the EMS (i.e., has not been modified), you can likely proceed with this warning being noted and UPLOAD the register files.</li></ul></p><p><b>*NOTE: Voter may not be marked as absent voter. If the issue persists, please contact support for assistance.</b></p>';
    case 'REG_INVALID_ABSENT_FLAG_SYMBOL':
      return '<p>Invalid Absent Flag found in file, @ found.</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Open the register files and locate the absent flag column</li><li>Review the files to see if any entry in this column contains a hash (“@”) value.</li><li>If this is intentional and comes directly from the EMS (i.e., has not been modified), you can likely proceed with this warning being noted and UPLOAD the register files.</li></ul></p><p><b>*NOTE: Voter may not be marked as absent voter. If the issue persists, please contact support for assistance.</b></p>';
    case 'REG_INVALID_FILETYPE':
      return '<p>Invalid file type</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Click the Remove button in the Polling Station Registers section of the Election Files area.</li><li>Ensure you are uploading registers into the correct “Polling Station Registers” section on the portal.</li><li>Ensure the file being uploaded is of type CSV or TSV and can be opened and viewed in a standard text editor without issue.</li><li>If the register files file format is incorrect, please export a new batch of files from your EMS and confirm they are in CSV or TSV format.</li><li>If opening File for whatever reason, do not save with Excel.</li></ul></p><p><b>*NOTE: If the issue persists, please contact support for assistance.</b></p>';
    case 'REG_INVALID_DATEOFATTAIN':
      return '<p>Invalid date of attainment found in file</p><p>The date of Attainment is outside the expected range for dates of Attainment</p><p>You may continue however the voter(s) may not be eligible to vote</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This may be valid, but we are just highlighting this voter may not be eligible to vote, you can proceed if this is indeed correct.</li><li>If content with this, press UPLOAD to commence processing of register files.</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'REG_INVALID_DOB':
      return '<p>Invalid date of birth found in file</p><p>The date of birth is outside the expected range for dates of birth</p><p>You may continue however the voter(s) may not be eligible to vote</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This may be valid, but we are just highlighting this voter may not be eligible to vote, you can proceed if this is indeed correct.</li><li>If content with this, press UPLOAD to commence processing of register files.</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'REG_MISSING_FROM_BALLOT':
      return '<p>Possible cause and resolutions: </p><br></br><p><ul><li>In supplied Register files, ensure the Ballot files area name value matches the Electoral Area Name listed in the register</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'REG_MISSING_FROM_REG':
      return '<p>Possible cause and resolutions: </p><br></br><p><ul><li>Electoral Area Name in register file doesn’t match an Area Name in Ballot file.</li><li>Review the Registers EAN and ensure it matches an Area Name. Possible causes for this are additional commas in the EAN, i.e. “Test Street, London” whereas the Ballot may only have “Test Street”</li><li>Review the relationship between EAN and Area Name and determine what changes need undertaking, noting the importance of rogue commas.</li><li>Upload a new set of registers once amendments have been made.</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'REG_MISSING_EA_FROM_BALLOT':
      return '<p>Register import is missing electoral areas that are contained in the ballot header</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Please upload the files in the correct order. Ballot Paper Files should not be uploaded before Registers.</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'REG_MORE_PROXIES_IN_REG':
      return '<p>More proxies in register than proxy records</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>There are more voters marked with a P flag in the register than there is in the corresponding AV (containing Proxies) File</li><li>This means that those voters marked as having a proxy, will only be able to vote in person</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'PROXY_INVALID_FILETYPE':
      return '<p>Invalid file type</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Click the Remove button in the Absent Voter List, which includes proxies’ section of the Election Files area.</li><li>Ensure you are uploading Absent voter / Proxy files into the correct “Absent Voter List, which includes proxies” section on the portal.</li><li>Ensure the file being uploaded is of type CSV or TSV and can be opened and viewed in a standard text editor without issue.</li><li>If the absent voter / proxy files file format is incorrect, please export a new batch of files from your EMS and confirm they are in CSV or TSV format.</li><li>If opening File for whatever reason, do not save with Excel</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'PROXY_MORE_REG_PROXIES':
      return '<p>More register proxies than proxy records</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>There are more named Proxies in the proxy file than there are associated voters marked with a P flag in the register</li><li>Ensure that the proxy file is correct / valid</li><li>May be worth doing another export of the register files and uploading these first in the event additional P flags have been added to electors on the register</li><li>Once latest register is uploaded and processed, re-upload the proxy file and review the validation messages (if any)</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'BALLOT_INVALID_FILETYPE':
      return '<p>Invalid file type</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Click the Remove button in the Ballot Paper Analysis section of the Election Files area.</li><li>Ensure you are uploading ballot file into the correct “Ballot Paper Analysis section” section on the portal.</li><li>Ensure the file being uploaded is of type CSV or TSV and can be opened and viewed in a standard text editor without issue.</li><li>If the ballot paper files file format is incorrect, please export a new batch of files from your EMS and confirm they are in CSV or TSV format.</li><li>If opening File for whatever reason, do not save with Excel</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'BALLOT_MISSING_BALLOT_AREA':
      return '<p>Possible cause and resolutions: </p><br></br><p><ul><li>In supplied Register files, ensure the Ballot files area name value matches the Electoral Area Name listed in the register</li><li>Check for missing registers and ensure all registers have been imported then re-try</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'BALLOT_DUP_RANGES_FOUND':
      return '<p>Duplicate ranges found</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This file cannot be uploaded, please remove the affected file</li><li>Update your ballot ranges and re-export the files then re-try uploading your ballot file</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'BALLOT_DUP_BALLOTS_FOUND':
      return '<p>Duplicate ballots found</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Ensure your ranges are defined correctly and as you expect against all stations.</li><li>Ensure your UIM is correct and as expected for all stations</li><li>Ensure that if you are using the one UIM that you supply a BP Prefix for all entries.</li><li>If amendments are made, please re-upload your updated ballots file for verification and processing as normal.</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'BALLOT_NOT_ALL_EAN_IN_REG':
      return '<p>Ballot Paper File does not cover all electoral areas in the register files</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>You may continue however the voter(s) in the affected electoral areas will not be able to vote</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'STAFF_INVALID_FILETYPE':
      return '<p>Invalid file type</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>Click the Remove button in the Staff Allocation section of the Election Files area.</li><li>Ensure you are uploading Staff Allocation files into the correct “Staff Allocation” section on the portal.</li><li>Ensure the file being uploaded is of type CSV or TSV and can be opened and viewed in a standard text editor without issue.</li><li>If the Staff Allocation files file format is incorrect, please export a new batch of files from your EMS and confirm they are in CSV or TSV format.</li><li>If opening File for whatever reason, do not save with Excel</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'STAFF_DUP_TELE_NUMS':
      return '<p>Staff Allocation File contains duplicate phone numbers</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This file cannot be uploaded, please remove the affected file</li><li>Update staff phone numbers and re-export the files then re-try</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'STAFF_INV_TELE_NUMS':
      return '<p>Staff Allocation File contains invalid phone numbers</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This file cannot be uploaded, please remove the affected file</li><li>Update staff phone numbers and re-export the files then re-try</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    case 'STAFF_MISSING_COL':
      return '<p>Staff Allocation File is missing a column</p><p>Possible cause and resolutions: </p><br></br><p><ul><li>This file cannot be uploaded, please remove the affected file</li><li>Update your staff file and re-export the files then re-try</li></ul></p><p><b>*NOTE: If further assistance is required, please contact support for assistance.</b></p>';
    default:
      return helpEnum;
  }
};

export default CustomerValidation;
