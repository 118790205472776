import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import MDModal from '../../shared/MDModal';
import { User } from '../../../entities/User';
import { deleteUser } from '../../../services/api/dashboard';

interface DeleteUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  tenant: string;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  open,
  onClose,
  user,
  tenant,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const deleteUserConfirmed = async () => {
    try {
      setLoading(true);
      await deleteUser({
        tenant,
        userId: user.id,
      });
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={'Confirm Delete'}
      loading={loading}
      success={success}
      errorMessage={errorMessage}
      content={
        <div>
          <Typography gutterBottom>
            Are you sure you wish to delete this user?
          </Typography>
          <Typography>ID: {user.id}</Typography>
          <Typography>
            Name: {user.firstName} {user.lastName}
          </Typography>
          <Typography>Phone: {user.phoneNumber}</Typography>
        </div>
      }
      actionBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={deleteUserConfirmed}
            disabled={loading || success}
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(DeleteUserModal);
