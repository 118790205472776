import React, { useContext, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DashboardContext } from '../../context/DashboardContext';
import { TenantContext } from '../../context/TenantContext';

import DeviceItemRow from './DeviceItemRow';
import StatusBreakdown from '../shared/StatusBreakdown';
import DeviceFilterBar from './DeviceFilterBar';
import Loader from '../shared/Loader';
import LastFetchTime from '../shared/LastFetchTime';

const DeviceList: React.FC = () => {
  const {
    setupComplete,
    lastFetch,
    filteredDevices,
    fetching,
    refreshDashboard,
  } = useContext(DashboardContext);

  const { selectedTenant, selectedElection } = useContext(TenantContext);

  const deviceItems = useMemo(() => {
    console.log(filteredDevices);
    return filteredDevices.map((device, index) => (
      <DeviceItemRow
        key={`${device.stationNumber}-${device.id}-${index}`}
        device={device}
      />
    ));
  }, [filteredDevices]);

  let message = null;
  if (!setupComplete) {
    if (!selectedTenant) {
      message = <Typography variant="body1">{'Select Tenant'}</Typography>;
    }
    if (selectedTenant && !selectedElection) {
      message = (
        <Typography variant="body1">{'Select Election Date'}</Typography>
      );
    }
    if (fetching) {
      message = <Loader message={'Fetching pollstation report data'} />;
    }
  }
  const headers: string[] = [
    'Station',
    'Status',
    'Unsynced',
    'Battery',
    'Memory Used',
    'Last Heartbeat',
    'Session Expires',
    'Vendor ID',
    'First Name',
    'Last Name',
    'Phone Number',
  ];

  return (
    <>
      <LastFetchTime
        lastFetch={lastFetch}
        onRefreshClick={refreshDashboard}
        disabled={(!selectedTenant && !selectedElection) || fetching}
      />
      <StatusBreakdown group={filteredDevices} field="overallStatus" />
      <DeviceFilterBar />
      <Paper elevation={6} style={{ borderRadius: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {setupComplete ? (
              fetching ? (
                <TableRow>
                  <TableCell align="center" colSpan={headers.length}>
                    <Loader message={'Fetching pollstation report data'} />
                  </TableCell>
                </TableRow>
              ) : (
                deviceItems
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headers.length}>
                  {message}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default React.memo(DeviceList);
