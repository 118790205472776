import React from 'react';
import { CircularProgress } from '@mui/material';

export interface LoaderProps {
  message?: string;
  size?: number;
  style?: React.CSSProperties;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { message = '', size = 60, style } = props;
  return (
    <>
      {message && <h3 data-cy="loader">{message}</h3>}
      <CircularProgress size={size} style={{ ...style, color: '#3e90c7' }} />
    </>
  );
};

export default Loader;
