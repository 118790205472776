import React, { useState, useContext } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { CheckCircle, AddCircle } from '@mui/icons-material';
import { Pollstation } from '../../../entities/Pollstation';
import {
  hasVotesCast,
  hasAllExpeditedReports,
  hasAllReports,
} from '../../../utils/utils';
import { TenantContext } from '../../../context/TenantContext';
import { DashboardContext } from '../../../context/DashboardContext';
import { updateUsingMP } from '../../../services/api/dashboard';
import MDModal from '../../shared/MDModal';
import { Buffer } from 'buffer';

interface StationDetailsProps {
  station: Pollstation;
}

const StationDetails: React.FC<StationDetailsProps> = ({ station }) => {
  const noBorder = { border: 'none' };

  const { selectedTenant, selectedElection } = useContext(TenantContext);
  const { refreshDashboard } = useContext(DashboardContext);

  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [selectedStation, setSelectedStation] = useState<Pollstation>();
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const updateStation = async () => {
    if (selectedStation && selectedElection && selectedTenant) {
      setLoading(true);
      try {
        const request = {
          tenant: selectedTenant,
          electionDate: selectedElection,
          station: Buffer.from(selectedStation.pollStationId, 'ascii').toString(
            'hex'
          ),
          checked: isChecked,
        };
        await updateUsingMP(request);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setShowConfirmUpdate(false);
          refreshDashboard();
        }, 3000);
        // eslint-disable-next-line
      } catch (error: any) {
        console.log('Error removing device');
        setError(error.message);
        setTimeout(() => setError(''), 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleUsingMPChange = (
    station: Pollstation,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedStation(station);
    setIsChecked(event.currentTarget.checked);
    setShowConfirmUpdate(true);
  };

  const closeHandler = () => {
    setSelectedStation(undefined);
    setShowConfirmUpdate(false);
  };

  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <FormControlLabel
                label="Using Modern Polling"
                control={
                  <Checkbox
                    color="default"
                    checked={station.usingModernPolling}
                    onChange={(e) => toggleUsingMPChange(station, e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="25%">Number:</TableCell>
            <TableCell width="75%">{station.number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="25%">Name:</TableCell>
            <TableCell width="75%">{station.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="25%">Address:</TableCell>
            <TableCell width="75%">
              <div>{station.pollingPlace?.address.address1 || ''}</div>
              <div>{station.pollingPlace?.address.address2 || ''}</div>
              <div>{station.pollingPlace?.address.postcode || ''}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="25%">Status:</TableCell>
            <TableCell width="75%">
              <Table size="small" padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell style={noBorder}>Devices Active:</TableCell>
                    <TableCell style={noBorder}>
                      {statusCheck(station.devices.some((d) => d.openStatus))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={noBorder}>Votes Cast:</TableCell>
                    <TableCell style={noBorder}>
                      {statusCheck(hasVotesCast(station))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={noBorder}>Expedited Reports:</TableCell>
                    <TableCell style={noBorder}>
                      {statusCheck(hasAllExpeditedReports(station))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={noBorder}>All Reports:</TableCell>
                    <TableCell style={noBorder}>
                      {statusCheck(hasAllReports(station))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {showConfirmUpdate && (
        <MDModal
          title={'Are you sure?'}
          width={450}
          height={300}
          open={showConfirmUpdate}
          onClose={closeHandler}
          success={success}
          loading={loading}
          errorMessage={error}
          content={
            <>
              <Typography variant="body1">
                {`Are you sure you wish to update this station to be ${
                  isChecked ? '' : 'not'
                } using Modern Polling?`}
              </Typography>
            </>
          }
          actionBar={
            <div className="device-remove-modal-action">
              <Button
                id="runTaskBtn"
                color="inherit"
                variant="contained"
                size="small"
                onClick={closeHandler}
              >
                No
              </Button>
              <Button
                id="runTaskBtn"
                color="primary"
                variant="contained"
                size="small"
                onClick={updateStation}
              >
                Yes
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

const statusCheck = (showCheck: boolean) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    {showCheck ? (
      <CheckCircle style={{ color: 'forestgreen' }} />
    ) : (
      <AddCircle style={{ transform: 'rotate(45deg)', color: 'red' }} />
    )}
  </div>
);

export default React.memo(StationDetails);
