import * as React from 'react';
import { AlarmStatus } from '../../entities/AlarmStatus';
import OverviewCircle from '../DeviceList/OverviewCircle';
import './StatusBreakdown.css';

interface StatusBreakdownProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group: any[];
  field: string;
}

const StatusBreakdown: React.FC<StatusBreakdownProps> = (
  props: StatusBreakdownProps
) => {
  const { group, field } = props;

  const warning = group.filter((d) => d[field] === AlarmStatus.warning).length;
  const alert = group.filter((d) => d[field] === AlarmStatus.alert).length;
  const healthy = group.filter((d) => d[field] === AlarmStatus.good).length;

  return (
    <div className="breakdown">
      <div>
        <OverviewCircle colour="red" label={warning} />
        <OverviewCircle colour="yellow" label={alert} />
        <OverviewCircle colour="green" label={healthy} />
      </div>
    </div>
  );
};

export default StatusBreakdown;
