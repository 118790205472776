import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Button, TextField } from '@mui/material';
import MDModal from '../../../shared/MDModal';
import { DropDown } from '../../../shared/DropDown';
import { BallotInfo, BallotInfoProps } from '../../../shared/BallotInfo';
import { GenericModalProps } from '../../GenericModalProps';
import { revertSkippedBallots } from '../../../../services/api/support';
import '../../../../styles.css';
import '../../../shared/MDModal.css';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import TenantPollstationPicker from './TenantPollstationPicker';

const RevertSkippedModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
}: GenericModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  const [uim, setUim] = useState('');
  const [startRange, setStartRange] = useState('');
  const [endRange, setEndRange] = useState('');

  const [ballotInfo, setBallotInfo] = useState<BallotInfoProps>({
    uim: '',
    start: '',
    end: '',
    colour: '',
  });

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const uims = useMemo(() => {
    if (selectedPollstation && selectedPollstation?.ballots) {
      return [
        '',
        ...Object.values(selectedPollstation.ballots)
          .map((election) =>
            Object.entries(election).map(([key, ballot]) => {
              if (ballot?.ordinary?.numbers) {
                return ballot.ordinary.numbers.map(
                  (num) => `${num.uimPrefix} - ${key}`
                );
              } else {
                return [''];
              }
            })
          )
          .flat(2),
      ];
    } else {
      return [];
    }
  }, [selectedPollstation]);

  useEffect(() => {
    setUim('');
  }, [selectedPollstation]);

  const handleRun = () => {
    const data = {
      tenant: selectedTenant || '',
      electionDate: selectedElectionDate || '',
      stationId: selectedPollstation?.pollStationId || '',
      uim: uim.split(' - ')[0],
      area: uim.split(' - ')[1],
      startRange,
      endRange,
    };
    Promise.resolve(setLoading(true))
      .then(() =>
        revertSkippedBallots(data)
          .then(() => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 5000);
          })
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (
      selectedTenant &&
      selectedElectionDate &&
      selectedPollstation &&
      uim &&
      startRange &&
      endRange &&
      !fetching
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [
    selectedTenant,
    selectedElectionDate,
    selectedPollstation,
    uim,
    startRange,
    endRange,
  ]);

  useEffect(() => {
    if (selectedPollstation && uim) {
      const [localUim, area] = uim.split(' - ');
      const elections = Object.values(selectedPollstation.ballots);

      elections.forEach((election) => {
        const elArea = election[area];
        if (elArea?.ordinary.numbers[0].uimPrefix === localUim) {
          setBallotInfo({
            uim: localUim,
            start: String(elArea.ordinary.numbers[0].from),
            end: String(elArea.ordinary.numbers[0].to),
            colour: elArea.ordinary.colour,
          });
        }
      });
    }
  }, [uim, selectedPollstation]);

  const content = (
    <>
      <TenantPollstationPicker />
      {selectedTenant && selectedElectionDate && selectedPollstation && (
        <>
          <DropDown
            options={uims}
            name="UIM"
            label="UIM"
            value={uim}
            onSelect={setUim}
            fullWidth
          />
          {uim && ballotInfo && (
            <BallotInfo
              uim={ballotInfo.uim}
              start={ballotInfo.start}
              end={ballotInfo.end}
              colour={ballotInfo.colour}
            />
          )}
          <TextField
            disabled={loading}
            id="startRange"
            label="Ballot Start Range"
            variant="outlined"
            margin="normal"
            fullWidth
            value={startRange}
            onChange={(e) => setStartRange(e.target.value)}
          />
          <TextField
            disabled={loading}
            id="endRange"
            label="Ballot End Range"
            variant="outlined"
            margin="normal"
            fullWidth
            value={endRange}
            onChange={(e) => setEndRange(e.target.value)}
          />
        </>
      )}
    </>
  );

  const actionBar = (
    <Button
      disabled={!submitEnabled}
      color="primary"
      variant="contained"
      size="medium"
      fullWidth
      onClick={handleRun}
    >
      Run Task
    </Button>
  );

  return (
    <MDModal
      open={open}
      content={content}
      actionBar={actionBar}
      onClose={onClose}
      title="Revert Skipped Ballots"
      height={700}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
    />
  );
};

export default RevertSkippedModal;
