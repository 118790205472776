import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Paper, ThemeProvider, CssBaseline, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import Login from './components/Login';
import AppHeader from './components/AppHeader';
import { TenantContextProvider } from './context/TenantContext';
import ProdTenantsTable from './components/ProdTenantsTable';

import Overview from './components/Overview/Overview';
import Dashboard from './components/Dashboard';
import Loader from './components/shared/Loader';
import { DashboardsContextProvider } from './context/DashboardContext';
import './styles.css';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#3e90c7',
    },
    secondary: {
      main: '#fff',
    },
  },
});

const queryClient = new QueryClient();
const App: React.FC = () => {
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const setUp = async () => {
    setLoading(true);
    try {
      await Auth.currentSession();
      setVerified(true);
      // eslint-disable-next-line
    } catch (error: any) {
      console.log(error);
      if (error) {
        setError(error.message);
        setTimeout(() => setError(null), 5000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  useEffect(() => {
    if (verified) setLoading(false);
  }, [verified]);

  const handleLoginSuccess = () => {
    setVerified(true);
    setLoading(false);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setVerified(false);
  };

  let component = (
    <Switch>
      <DashboardsContextProvider>
        <Route exact path="/" component={() => <ProdTenantsTable />} />
        <Route path="/overview" component={() => <Overview />} />
        <Route path="/dashboard" component={() => <Dashboard />} />
      </DashboardsContextProvider>
    </Switch>
  );
  if (!verified) {
    component = <Login handleLoginSuccess={handleLoginSuccess} />;
  }
  if (verified && loading) {
    component = <Loader />;
  }
  if (error) {
    // eslint-disable-next-line
    component = (
      <Paper>
        <Typography className="error">{error}</Typography>
      </Paper>
    );
  }

  return (
    <div className="app">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <TenantContextProvider>
            <Router>
              <AppHeader userVerified={verified} handleLogout={handleLogout} />
              <div className="app__container">{component}</div>
            </Router>
          </TenantContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
};

export default React.memo(App);
