import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Link,
  Typography,
} from '@mui/material';

import { accounts } from './Overview/HeaderControls';

const ProdTenantsTable: React.FC = () => {
  return (
    <>
      <Typography variant="h5" align="left">
        Environments / AWS Accounts
      </Typography>
      <Paper>
        <Table>
          <TableBody>
            {accounts.map((tenant) => (
              <TableRow key={tenant.name}>
                <TableCell align="center">
                  <Typography variant="h6">
                    <Link href={`${tenant.location}/overview`}>
                      {tenant.name}
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default React.memo(ProdTenantsTable);
