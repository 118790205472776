import React, { useState, useContext } from 'react';
import {
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Dialog,
  Slide,
} from '@mui/material';
import { Assignment, MoreHoriz } from '@mui/icons-material';
import { DashboardContext } from '../../../context/DashboardContext';
import { Pollstation } from '../../../entities/Pollstation';
import PollstationBPA from './PollstationBPA';
import PollstationReports from './PollstationReports';
import StaffRows from './StaffRows';
import DeviceRows from './DeviceRows';
import BPARows from './BPARows';
import StationDetails from './StationDetails';
import { TransitionProps } from '@mui/material/transitions';

export interface PollstationItemRowProps {
  pollstation: Pollstation;
}

const PollstationItemRow: React.FC<PollstationItemRowProps> = ({
  pollstation,
}) => {
  const { updateStationSelection, stationSelection } =
    useContext(DashboardContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [bpaOpen, setBpaOpen] = useState<boolean>(false);
  const [reportsOpen, setReportsOpen] = useState<boolean>(false);
  const menuOpen = Boolean(anchorEl);

  const menuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const viewBpaHandler = () => {
    setBpaOpen(true);
  };

  const viewReportsHandler = () => {
    setReportsOpen(true);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setBpaOpen(false);
    setReportsOpen(false);
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateStationSelection(
      pollstation.pollStationId,
      event.currentTarget.checked
    );
  };

  return (
    <>
      <TableRow style={bpaOpen ? { background: '#e9e9e9' } : {}}>
        <TableCell padding={'checkbox'} align="center">
          <Checkbox
            color={'primary'}
            checked={stationSelection[`${pollstation.pollStationId}`]}
            onChange={handleCheckChange}
          />
        </TableCell>
        <TableCell padding="none" align="left" style={{ verticalAlign: 'top' }}>
          <StationDetails station={pollstation} />
        </TableCell>
        <TableCell style={{ verticalAlign: 'top' }}>
          <StaffRows staff={pollstation.staff} />
        </TableCell>
        <TableCell style={{ verticalAlign: 'top' }}>
          <DeviceRows devices={pollstation.devices} />
        </TableCell>
        <TableCell style={{ verticalAlign: 'top' }}>
          <BPARows ballots={pollstation.ballots} />
        </TableCell>
        <TableCell align="center">
          <Tooltip title={'View More Information'}>
            <IconButton onClick={menuHandler}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {menuOpen && (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={onMenuClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              maxHeight: 130,
              width: '15rem',
            },
          }}
        >
          <MenuList>
            <MenuItem onClick={viewBpaHandler}>
              <ListItemIcon>
                <Assignment fontSize="small" />
              </ListItemIcon>
              <ListItemText>{'View BPA'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={viewReportsHandler}>
              <ListItemIcon>
                <Assignment fontSize="small" />
              </ListItemIcon>
              <ListItemText>{'View Reports'}</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {bpaOpen && (
        <Dialog
          open={bpaOpen}
          onClose={onMenuClose}
          maxWidth={false}
          keepMounted
          TransitionComponent={DialogTransition}
        >
          <PollstationBPA pollstation={pollstation} onClose={onMenuClose} />
        </Dialog>
      )}
      {reportsOpen && (
        <Dialog
          open={reportsOpen}
          onClose={onMenuClose}
          keepMounted
          maxWidth={'lg'}
          TransitionComponent={DialogTransition}
        >
          <PollstationReports pollstation={pollstation} onClose={onMenuClose} />
        </Dialog>
      )}
    </>
  );
};

const DialogTransition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => (
    <Slide
      ref={ref}
      direction="up"
      mountOnEnter
      unmountOnExit
      easing={'ease'}
      timeout={1000}
      {...props}
    />
  )
);
DialogTransition.displayName = 'DialogTransition';

export default React.memo(PollstationItemRow);
