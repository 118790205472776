import React, { useContext, useMemo } from 'react';

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Typography,
} from '@mui/material';

import { UsersContext } from '../../context/UsersContext';
import { TenantContext } from '../../context/TenantContext';
import LastFetchTime from '../shared/LastFetchTime';
import UsersFilterBar from './UsersFilterBar';
import Loader from '../shared/Loader';
import UserRow from './UserRow/UserRow';

const UsersList: React.FC = () => {
  const { selectedTenant } = useContext(TenantContext);
  const {
    setupComplete,
    lastFetch,
    fetching,
    error,
    filteredUsers,
    refreshUsers,
  } = useContext(UsersContext);

  const userRows = useMemo(
    () =>
      filteredUsers.map((user, index) => (
        <UserRow
          key={`user-row-${index}`}
          user={user}
          tenant={selectedTenant || ''}
        />
      )),
    [filteredUsers]
  );

  let message = null;
  if (!setupComplete) {
    if (!selectedTenant) {
      message = <Typography variant="body1">{'Select Tenant'}</Typography>;
    }
    if (fetching) {
      message = <Loader message={'Fetching Users'} />;
    }
  }
  if (error.length) {
    message = <Typography variant="body1">{`${error}`}</Typography>;
  }

  return (
    <>
      <div>
        <LastFetchTime
          lastFetch={lastFetch}
          onRefreshClick={refreshUsers}
          disabled={fetching || !selectedTenant}
        />
        <UsersFilterBar />
      </div>
      <Paper elevation={6} style={{ borderRadius: '10px' }}>
        <Table stickyHeader style={{ borderRadius: '10px', overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}>User ID</TableCell>
              <TableCell style={{ width: '10%' }}>Phone Number</TableCell>
              <TableCell style={{ width: '10%' }}>Name</TableCell>
              <TableCell style={{ width: '10%' }}>Latest Passcode</TableCell>
              <TableCell style={{ width: '45%' }}>Assignments</TableCell>
              <TableCell style={{ width: '10%' }} align="center">
                More
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setupComplete ? (
              fetching ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Loader message={'Fetching Users'} />
                  </TableCell>
                </TableRow>
              ) : error.length ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {message}
                  </TableCell>
                </TableRow>
              ) : (
                userRows
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {message}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default React.memo(UsersList);
