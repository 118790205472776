import { getData } from '../../../../services/api/rest';
import {
  AnonymisedDataUrlsResponseType,
  ScrubDataRequestType,
} from '../../model/scrubber';

/**
 * runScrubData.
 * @description Downloads file(s) from s3.
 * @param tenant.
 * @param electionDate.
 * @param file.
 * @returns File download.
 */
export const runScrubData = async ({
  tenant,
  electionDate,
  emsType,
}: ScrubDataRequestType): Promise<AnonymisedDataUrlsResponseType> => {
  return getData(
    `/dashboard/tenants/${tenant}
    /data-anonymise?electionDate=${electionDate}
    &emsType=${emsType}`
  );
};

export default runScrubData;
