/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { TenantContext } from './TenantContext';
import { fetchStationDashboards } from '../services/api/dashboard';
import { WithChildren } from '../types/WithChildren';
import { Pollstation } from '../entities/Pollstation';
import { AlarmStatus } from '../entities/AlarmStatus';
import { iPad } from '../entities/Device';
import { SyncFilterStatus } from '../entities/SyncStatus';
import {
  noop,
  getLastOnlineTime,
  getDeviceStatus,
  noBPASubmitted,
  hasVotesCast,
  noContactInLastHour,
  hasExpiredTokens,
} from '../utils/utils';

export enum DeviceOrder {
  None = 'None',
  Alarm = 'Alarm Status',
  Unsync = 'Unsynced Count',
}

interface DeviceFilterOptions {
  tenant?: string;
  batteryStatus?: AlarmStatus;
  unsyncdCountStatus?: AlarmStatus;
  overallStatus?: AlarmStatus;
  syncFilter?: SyncFilterStatus;
}
export interface DashboardContextI {
  fetching: boolean;
  dashError: string;
  pollstations: Pollstation[];
  devices: iPad[];
  filteredDevices: iPad[];
  selectedStations: Pollstation[];
  filteredStations: Pollstation[];
  setupComplete: boolean;
  lastFetch: number;
  refreshDashboard: () => void;
  filters: DeviceFilterOptions;
  stationSelection: Record<string, boolean>;
  orderSet: (order: DeviceOrder) => void;
  updateFilter: (filter: Partial<DeviceFilterOptions>) => void;
  updateStationSelection: (stationId: string, checked: boolean) => void;
  checkAllStations: (check: boolean) => void;
  allStationsChecked: boolean;
  noBPAFilter: boolean;
  setNoBPAFilter: (check: boolean) => void;
  closedStationsFilter: boolean;
  setClosedStationsFilter: (check: boolean) => void;
  votesNotCastFilter: boolean;
  setVotesNotCastFilter: (check: boolean) => void;
  activeFilterCount: number;
  usingMPFilter: boolean;
  setUsingMPFilter: (check: boolean) => void;
  tokensFilter: boolean;
  setTokensFilter: (check: boolean) => void;
  batteryFilter: boolean;
  setBatteryFilter: (check: boolean) => void;
  reportsFilter: boolean;
  setReportsFilter: (check: boolean) => void;
  heartbeatFilter: boolean;
  setHeartbeatFilter: (check: boolean) => void;
  syncIssuesFilter: boolean;
  setSyncIssuesFilter: (check: boolean) => void;
  resetAllStationFilters: () => void;
}

export const DashboardContext = createContext<DashboardContextI>({
  devices: [],
  dashError: '',
  filteredDevices: [],
  selectedStations: [],
  filteredStations: [],
  fetching: false,
  pollstations: [],
  setupComplete: false,
  lastFetch: 0,
  refreshDashboard: noop,
  filters: {},
  checkAllStations: noop,
  allStationsChecked: true,
  closedStationsFilter: false,
  setClosedStationsFilter: noop,
  votesNotCastFilter: false,
  setVotesNotCastFilter: noop,
  noBPAFilter: false,
  setNoBPAFilter: noop,
  activeFilterCount: 1,
  stationSelection: {},
  orderSet: noop,
  updateFilter: noop,
  updateStationSelection: noop,
  usingMPFilter: true,
  setUsingMPFilter: noop,
  tokensFilter: false,
  setTokensFilter: noop,
  batteryFilter: false,
  setBatteryFilter: noop,
  reportsFilter: false,
  setReportsFilter: noop,
  heartbeatFilter: false,
  setHeartbeatFilter: noop,
  syncIssuesFilter: false,
  setSyncIssuesFilter: noop,
  resetAllStationFilters: noop,
} as DashboardContextI);

let pollInterval: NodeJS.Timeout;

const useDashboardsContext = () => {
  const {
    setupComplete: tenantSetupComplete,
    selectedTenant,
    selectedElection,
    todaysElections,
  } = useContext(TenantContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [setupComplete, setupCompleteSet] = useState(false);
  const [lastFetch, setLastFetch] = useState<number>(0);
  const [dashError, setDashError] = useState<string>('');

  const [pollstations, setStations] = useState<Pollstation[]>([]);
  const [filteredStations, setFilteredStations] = useState<Pollstation[]>([]);
  const [selectedStations, setSelectedStations] = useState<Pollstation[]>([]);

  const [allStationsChecked, setAllStationsChecked] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stationSelection, setStationSelection] = useState<any>({});

  const [noBPAFilter, setNoBPAFilter] = useState(false);
  const [usingMPFilter, setUsingMPFilter] = useState(true);
  const [closedStationsFilter, setClosedStationsFilter] = useState(false);
  const [votesNotCastFilter, setVotesNotCastFilter] = useState(false);
  const [tokensFilter, setTokensFilter] = useState(false);
  const [batteryFilter, setBatteryFilter] = useState(false);
  const [reportsFilter, setReportsFilter] = useState(false);
  const [heartbeatFilter, setHeartbeatFilter] = useState(false);
  const [syncIssuesFilter, setSyncIssuesFilter] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const [devices, devicesSet] = useState<iPad[]>([]);
  const [filteredDevices, filteredDevicesSet] = useState<iPad[]>([]);
  const [filters, filtersSet] = useState<DeviceFilterOptions>({
    syncFilter: SyncFilterStatus.UnreachableOrUnsyncd,
  });
  const [order, orderSet] = useState<DeviceOrder>(DeviceOrder.Unsync);

  const filterStations = useCallback(() => {
    let count = 0;
    let filtered = pollstations;
    if (usingMPFilter) {
      filtered = filtered.filter((s) => s.usingModernPolling);
      count++;
    }
    if (votesNotCastFilter) {
      filtered = filtered.filter((s) => !hasVotesCast(s));
      count++;
    }
    if (tokensFilter) {
      filtered = filtered.filter((s) => hasExpiredTokens(s));
      count++;
    }
    if (batteryFilter) {
      filtered = filtered.filter((s) =>
        s.devices.some((d) => parseFloat(d.batteryLevel) <= 0.15)
      );
      count++;
    }
    if (reportsFilter) {
      filtered = filtered.filter((s) => !s.reports.length);
      count++;
    }
    if (heartbeatFilter) {
      filtered = filtered.filter((s) => noContactInLastHour(s));
      count++;
    }
    if (syncIssuesFilter) {
      filtered = filtered.filter((s) =>
        s.devices.some((d) => d.unsyncdCount > 0)
      );
      count++;
    }
    if (noBPAFilter) {
      filtered = filtered.filter((s) => noBPASubmitted(s));
      count++;
    }
    if (closedStationsFilter) {
      filtered = filtered.filter((s) => s.devices.every((d) => !d.openStatus));
      count++;
    }
    if (todaysElections) {
      count++;
    }
    setFilteredStations(filtered);
    setActiveFilterCount(count);
  }, [
    todaysElections,
    pollstations,
    noBPAFilter,
    closedStationsFilter,
    votesNotCastFilter,
    usingMPFilter,
    tokensFilter,
    batteryFilter,
    reportsFilter,
    heartbeatFilter,
    syncIssuesFilter,
  ]);

  const selectStationList = useCallback(() => {
    const selected = filteredStations.filter(
      (s) => stationSelection[s.pollStationId]
    );
    setSelectedStations(selected);
  }, [filteredStations, stationSelection]);

  const filterDevicesList = useCallback(() => {
    const fiveMinsAgo = Date.now() - 5 * 60 * 1000;

    let fd = devices;
    if (filters.batteryStatus) {
      fd = fd.filter(
        (device) => device.batteryStatus === filters.batteryStatus
      );
    }
    if (filters.unsyncdCountStatus) {
      fd = fd.filter(
        (device) => device.unsyncdCountStatus === filters.unsyncdCountStatus
      );
    }
    if (filters.overallStatus) {
      fd = fd.filter(
        (device) => device.overallStatus === filters.overallStatus
      );
    }
    if (filters.syncFilter) {
      switch (filters.syncFilter) {
        case SyncFilterStatus.LoggedOut:
          fd = fd.filter((device) => device.openStatus === false);
          break;
        case SyncFilterStatus.Unreachable:
          fd = fd.filter(
            (device) =>
              device.openStatus &&
              getLastOnlineTime(device.lastTimestamp) < fiveMinsAgo
          );
          break;
        case SyncFilterStatus.Unsyncd:
          fd = fd.filter((device) => device.unsyncdCount > 0);
          break;
        case SyncFilterStatus.UnreachableOrUnsyncd:
          fd = fd.filter(
            (device) =>
              device.openStatus &&
              (device.unsyncdCount > 0 ||
                getLastOnlineTime(device.lastTimestamp) < fiveMinsAgo)
          );
          break;
      }
    }
    switch (order) {
      case DeviceOrder.Alarm:
        fd.sort((a, b) => {
          const getWeight = (status?: AlarmStatus) => {
            switch (status) {
              case AlarmStatus.warning:
                return 3;
              case AlarmStatus.alert:
                return 2;
              case AlarmStatus.good:
                return 1;
              case AlarmStatus.none:
              default:
                return 0;
            }
          };

          if (a.overallStatus === b.overallStatus) {
            return 0;
          } else if (getWeight(a.overallStatus) > getWeight(b.overallStatus)) {
            return -1;
          } else {
            return 1;
          }
        });
        break;
      case DeviceOrder.Unsync:
        fd.sort((a, b) => {
          if (a.unsyncdCount === b.unsyncdCount) {
            return 0;
          } else if (a.unsyncdCount > b.unsyncdCount) {
            return -1;
          } else {
            return 1;
          }
        });
        break;
      case DeviceOrder.None:
      default:
        break;
    }
    filteredDevicesSet(fd);
  }, [devices, filters, order]);

  const refreshDashboard = () => {
    fetchDashboardsForElection();
  };

  const fetchDashboardsForElection = useCallback(async () => {
    if (tenantSetupComplete && selectedTenant && selectedElection) {
      try {
        setLoading(true);
        setLastFetch(Date.now());

        const dashboards = await fetchStationDashboards(
          selectedTenant,
          selectedElection
        );

        const selectedStations: Record<string, boolean> = {};
        const electionKeys: Record<string, string> = {};
        const allDevices: iPad[] = [];

        const stations = dashboards.map((dash) => {
          selectedStations[`${dash.pollStationId}`] = true;

          const devices = dash.devices;
          dash.devices = devices.map((d) => {
            const deviceStatus = getDeviceStatus(
              d.batteryLevel,
              d.unsyncdCount,
              d.lastTimestamp
            );
            return {
              ...d,
              ...deviceStatus,
              stationNumber: dash.number,
            };
          });
          allDevices.push(...dash.devices);

          for (const [elKey, election] of Object.entries(dash?.ballots || {})) {
            for (const area of Object.values(election)) {
              electionKeys[elKey] = area.bref;
            }
          }
          for (const [elKey, election] of Object.entries(dash?.voters || {})) {
            for (const [areaName, area] of Object.entries(election)) {
              dash.voters[elKey][areaName] = {
                ...area,
                electionname: electionKeys[elKey],
              };
            }
          }
          return dash;
        });

        setDashError('');
        devicesSet(allDevices);
        setStations(stations);
        setStationSelection(selectedStations);
        setAllStationsChecked(true);
      } catch (error) {
        console.log(`Error fetching dashboards: ${error}`);
        setDashError(`Error fetching dashboards: ${error}`);
      } finally {
        setLoading(false);
      }
    }
  }, [tenantSetupComplete, selectedTenant, selectedElection]);

  useEffect(() => {
    if (tenantSetupComplete && selectedTenant && selectedElection) {
      Promise.resolve(fetchDashboardsForElection()).then(() =>
        setupCompleteSet(true)
      );
      pollInterval = setInterval(fetchDashboardsForElection, 300000);
      return () => {
        if (pollInterval) {
          clearInterval(pollInterval);
          if (pollInterval?.unref) {
            pollInterval.unref();
          }
        }
      };
    }
  }, [
    tenantSetupComplete,
    selectedTenant,
    selectedElection,
    fetchDashboardsForElection,
  ]);

  useEffect(() => {
    filterDevicesList();
  }, [filterDevicesList]);

  useEffect(() => {
    selectStationList();
  }, [selectStationList]);

  useEffect(() => {
    filterStations();
  }, [filterStations]);

  useEffect(() => {
    setStations([]);
  }, [todaysElections]);

  const updateFilter = (changedFilter: Partial<DeviceFilterOptions>) => {
    filtersSet({ ...filters, ...changedFilter });
  };

  const updateStationSelection = (stationId: string, checked: boolean) => {
    setStationSelection({ ...stationSelection, [`${stationId}`]: checked });
    setAllStationsChecked(false);
  };

  const selectAllStations = (check: boolean) => {
    const newFilters: Record<string, boolean> = {};
    Object.keys(stationSelection).forEach(
      (key: string) => (newFilters[`${key}`] = check)
    );
    setStationSelection(newFilters);
  };

  const checkAllStations = (check: boolean) => {
    setAllStationsChecked(check);
    selectAllStations(check);
  };

  const resetAllStationFilters = () => {
    setClosedStationsFilter(false);
    setNoBPAFilter(false);
    setUsingMPFilter(true);
    setVotesNotCastFilter(false);
    setTokensFilter(false);
    setBatteryFilter(false);
    setReportsFilter(false);
    setHeartbeatFilter(false);
    setSyncIssuesFilter(false);
  };

  return {
    fetching: loading,
    devices,
    filteredDevices,
    filteredStations,
    selectedStations,
    pollstations,
    setupComplete,
    lastFetch,
    allStationsChecked,
    refreshDashboard,
    filters,
    stationSelection,
    orderSet,
    updateFilter,
    updateStationSelection,
    checkAllStations,
    closedStationsFilter,
    setClosedStationsFilter,
    noBPAFilter,
    setNoBPAFilter,
    votesNotCastFilter,
    setVotesNotCastFilter,
    activeFilterCount,
    dashError,
    usingMPFilter,
    setUsingMPFilter,
    tokensFilter,
    setTokensFilter,
    batteryFilter,
    setBatteryFilter,
    reportsFilter,
    setReportsFilter,
    heartbeatFilter,
    setHeartbeatFilter,
    syncIssuesFilter,
    setSyncIssuesFilter,
    resetAllStationFilters,
  };
};

export const DashboardsContextProvider: React.FC<WithChildren> = ({
  children,
}: WithChildren) => {
  const value = useDashboardsContext();
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
