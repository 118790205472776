import React from 'react';
import { VictoryLabel, VictoryPie } from 'victory';
import { Paper, Typography, CardContent } from '@mui/material';
import { TurnoutStats } from '../../../entities/Pollstation';

export interface OverallTurnoutProps {
  className?: string;
  style?: React.CSSProperties;
  data: TurnoutStats;
  title?: string;
  size?: number;
  noFooter?: boolean;
  elevation?: number;
}

const OverallTurnout: React.FC<OverallTurnoutProps> = (
  props: OverallTurnoutProps
) => {
  const {
    className,
    style,
    data,
    title,
    size = 200,
    noFooter,
    elevation,
  } = props;
  return (
    <Paper elevation={elevation} className={className} style={style}>
      <CardContent style={{ padding: '0.5rem' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <svg width={size} height={size}>
            <>
              <VictoryPie
                origin={{ x: size / 2, y: size / 2 }}
                radius={size / 2}
                width={size}
                height={size}
                standalone={false}
                innerRadius={size / 2 / 1.5}
                colorScale={['#36b5e5', '#E0E0E0']}
                style={{ labels: { display: 'none' } }}
                data={[
                  { y: data?.totalTurnout || 0 },
                  {
                    y: data?.totalEligible - data?.totalTurnout || 360,
                  },
                ]}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: size * 0.1, fontWeight: 'bold' }}
                x={size / 2}
                y={size / 2}
                text={`${(
                  (data?.totalTurnout / data?.totalEligible) *
                  100
                ).toFixed(2)}%`}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: size * 0.075 }}
                x={size / 2}
                y={size / 2 + size / 10}
                text={data?.totalTurnout || '0'}
              />
            </>
          </svg>
        </div>
        {!noFooter && (
          <div>
            <Typography variant="body1" align="right">
              {data?.totalEligible || '0'}
            </Typography>
            <Typography variant="body1" align="right" color="textSecondary">
              Registered
            </Typography>
          </div>
        )}
      </CardContent>
    </Paper>
  );
};

export default React.memo(OverallTurnout);
