/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useReducer,
} from 'react';

import useTenants from '../features/data-validation/hooks/useTenants';
import useScrubber from '../features/data-scrubber/hooks/useScrubber';

import { noop, sortAlphaNumeric } from '../utils/utils';
import { WithChildren } from '../types/WithChildren';
import { TenantConfig } from '../entities/DynamoObjects';
import {
  DataFileType,
  DataValidationTenant,
  DescriptionMapping,
} from '../features/data-validation/model';
import { FileTypeOptionType } from '../features/data-scrubber';
import {
  ActionTypes as ScrubActions,
  dataScrubbingReducer,
  INITIAL_STATE as INITIAL_SCRUBBING_STATE,
} from '../features/data-scrubber/state/scrubbingSlice';
import { Validation } from '../entities/Validations';

const s3Url = 'init';
export interface TenantContextI {
  isLoading: boolean;
  tenants: string[];
  tenantDetails: TenantConfig[];
  selectedTenant?: string;
  setTenant: (tenant: string) => void;
  selectedEmsType?: string;
  setSelectedEmsType: (emsType: string) => void;
  useTenantsData?: DataValidationTenant[];
  setScrub: (run: boolean) => void;
  shouldScrub: boolean;
  scrubbing: boolean;
  scrubSuccess: boolean;
  scrubError: any;
  scrubbedUrls: string[];
  fileType: FileTypeOptionType;
  setFileType: (fileType: FileTypeOptionType) => void;
  electionsForTenant: string[];
  selectedElection?: string;
  setElection: (date: string) => void;
  setupComplete: boolean;
  setTodaysElections: (check: boolean) => void;
  todaysElections: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableQueries: Record<string, string>;
  dispatchScrub: any;
  scrubState: any;
  validations: Validation[];
  setValidations: (v: Validation[]) => void;
  validationsRunning: boolean;
  setValidationsRunning: (running: boolean) => void;
}

export const TenantContext = createContext<TenantContextI>({
  isLoading: false,
  tenants: [],
  tenantDetails: [],
  selectedTenant: undefined,
  setTenant: noop,
  selectedEmsType: undefined,
  setSelectedEmsType: noop,
  useTenantsData: [],
  electionsForTenant: [],
  selectedElection: undefined,
  setElection: noop,
  setupComplete: false,
  setTodaysElections: noop,
  todaysElections: false,
  availableQueries: {},
  dispatchScrub: noop,
  scrubState: INITIAL_SCRUBBING_STATE,
  shouldScrub: false,
  setScrub: noop,
  scrubbing: false,
  scrubSuccess: false,
  scrubError: undefined,
  scrubbedUrls: [],
  fileType: undefined,
  setFileType: noop,
  validations: [],
  setValidations: noop,
  validationsRunning: false,
  setValidationsRunning: noop,
} as TenantContextI);

export const TenantContextProvider: React.FC<WithChildren> = ({
  children,
}: WithChildren) => {
  const value = useTenantContext();
  return (
    <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
  );
};

const getValidationFileData = (tenant: string) => {
  const output: Record<string, string> = {};
  Object.keys(DescriptionMapping).forEach((rtm) => {
    if (rtm !== 'all') {
      const dk = rtm as DataFileType;
      output[
        rtm
      ] = `https://${s3Url}.s3.eu-west-2.amazonaws.com/${tenant}/${tenant}_${DescriptionMapping[dk].name}.csv`; // ReportTextMapping[dft]
    }
  });
  return output;
};
const useTenantContext = () => {
  const [scrubState, dispatchScrub] = useReducer(
    dataScrubbingReducer,
    INITIAL_SCRUBBING_STATE
  );

  const { data, isLoading } = useTenants();
  const [tenantDetails, tenantsSet] = useState<TenantConfig[]>(data ?? []);
  const [selectedEmsType, setSelectedEmsType] = useState<string>('');
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [electionsForTenant, setElections] = useState<string[]>([]);
  const [selectedElection, setSelectedElection] = useState<string>('');
  const [setupComplete, setupCompleteSet] = useState(false);
  const [todaysElections, setTodaysElections] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [electionDate, setElectionDate] = useState<string | null>('');

  const [validations, setValidations] = useState<Validation[]>([]);
  const [validationsRunning, setValidationsRunning] = useState(false);
  const [shouldScrub, setShouldScrub] = useState<boolean>(false);
  const [fileType, setScrubFileType] = useState<FileTypeOptionType>('all');

  const availableQueriesCallback = useCallback(
    (tenant: string) => {
      const input = tenant && tenant.length > 0 ? tenant : selectedTenant;
      if (input && input.length > 0) {
        return getValidationFileData(input);
      }
    },
    [selectedTenant]
  );

  const availableQueries = useMemo(() => {
    let output: Record<string, string> = { all: '' };

    if (selectedTenant && selectedTenant?.length > 0) {
      const r = availableQueriesCallback(selectedTenant);
      if (r !== undefined) {
        output = r;
      }
    }
    return output;
  }, [selectedTenant]);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: redactedUrls,
    isLoading: scrubbing,
    isSuccess: scrubSuccess,
    error: scrubError,
  } = useScrubber({
    variables: {
      tenant: selectedTenant,
      emsType: selectedEmsType,
      electionDate: selectedElection,
      run: shouldScrub,
    },
  });

  const scrubbedUrls = useMemo<string[]>(() => {
    if (redactedUrls && redactedUrls.length > 0) {
      return redactedUrls;
    } else if (
      scrubState &&
      scrubState[selectedTenant] &&
      scrubState[selectedTenant][selectedElection]
    ) {
      return scrubState[selectedTenant][selectedElection];
    }
    return redactedUrls ?? [];
  }, [redactedUrls, scrubState]);

  useEffect(() => {
    if (selectedTenant && !scrubbing && scrubbedUrls) {
      dispatchScrub({
        type: ScrubActions.loadScrubbedSignedUrlsSuccess,
        payload: {
          tenant: selectedTenant,
          elections: electionsForTenant,
          signedUrls: scrubbedUrls,
        },
      });
    }
  }, [electionsForTenant, scrubbedUrls, scrubbing, selectedTenant]);

  const setScrub = (should: boolean) => {
    setShouldScrub(should);
  };

  const setTenant = (tenant: string) => {
    setSelectedTenant(tenant);
    setElection('');
  };

  const setFileType = (fileType: FileTypeOptionType) => {
    setScrubFileType(fileType);
  };

  const setElection = (date: string) => {
    setSelectedElection(date);
  };

  const useTenantsData = useMemo<DataValidationTenant[] | undefined>(() => {
    try {
      if (!isLoading && data) {
        tenantsSet(data);
        return data;
      }
    } catch (error) {
      console.log('Error getting Tenants:', error);
    } finally {
      if (!isLoading) {
        setupCompleteSet(true);
      }
    }
  }, [data, isLoading]);

  const tenants = useMemo(() => {
    if (tenantDetails.length) {
      let details = [...tenantDetails];

      if (todaysElections) {
        details = tenantDetails.filter((tenant) =>
          tenant.electionDates.includes(new Date().toISOString().split('T')[0])
        );
      }

      return details.map((t) => t.tenant).sort(sortAlphaNumeric);
    } else {
      return [];
    }
  }, [tenantDetails, todaysElections]);

  useEffect(() => {
    if (scrubbing || ((scrubSuccess || scrubError) && shouldScrub)) {
      setScrub(false);
    }
  }, [scrubbing, scrubSuccess, scrubError, shouldScrub]);

  useEffect(() => {
    setSelectedTenant('');
    setSelectedElection('');
  }, [todaysElections]);

  useEffect(() => {
    if (selectedTenant) {
      const dates =
        tenantDetails.find((t) => t.tenant === selectedTenant)?.electionDates ||
        [];

      if (dates) {
        let filtered = dates.filter((v, i, s) => s.indexOf(v) === i);
        if (todaysElections) {
          filtered = filtered.filter((date) =>
            date.includes(new Date().toISOString().split('T')[0])
          );
        }
        setElections(filtered.sort());
      }
    }
  }, [selectedTenant, todaysElections]);

  useEffect(() => {
    if (selectedTenant) {
      const ems =
        tenantDetails.find((td) => td.tenant === selectedTenant)?.emsType ||
        undefined;

      if (ems !== undefined) {
        setSelectedEmsType(ems);
      }
    }
  }, [selectedTenant]);

  return {
    isLoading,
    tenants,
    tenantDetails,
    setTenant,
    selectedTenant,
    selectedEmsType,
    setSelectedEmsType,
    electionsForTenant,
    setElection,
    selectedElection,
    setTodaysElections,
    todaysElections,
    setupComplete,
    useTenantsData,
    availableQueries,
    scrubState,
    dispatchScrub,
    setScrub,
    shouldScrub,
    scrubbing,
    scrubSuccess,
    scrubError,
    scrubbedUrls,
    setFileType,
    fileType,
    validations,
    setValidations,
    validationsRunning,
    setValidationsRunning,
  };
};
