import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { TenantContext } from './TenantContext';
import { User } from '../entities/User';
import { WithChildren } from '../types/WithChildren';
import { getUsers } from '../services/api/dashboard';
import { noop } from '../utils/utils';

export interface UsersContextI {
  users: User[];
  filteredUsers: User[];
  searchVal: string;
  setSearchVal: (val: string) => void;
  setupComplete: boolean;
  fetching: boolean;
  lastFetch: number;
  error: string;
  refreshUsers: () => void;
}

export const UsersContext = createContext<UsersContextI>({
  users: [],
  filteredUsers: [],
  searchVal: '',
  setSearchVal: noop,
  setupComplete: false,
  fetching: false,
  lastFetch: 0,
  error: '',
  refreshUsers: noop,
} as UsersContextI);

const useUsersContext = () => {
  const {
    setupComplete: tenantSetupComplete,
    selectedTenant,
    selectedElection,
  } = useContext(TenantContext);

  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchVal, setSearchVal] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [setupComplete, setupCompleteSet] = useState<boolean>(false);
  const [lastFetch, setLastFetch] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const fetchUsers = useCallback(async () => {
    if (tenantSetupComplete && selectedTenant) {
      try {
        setLoading(true);
        setLastFetch(Date.now());
        const results = await getUsers(selectedTenant);
        setUsers(results);
      } catch (error: unknown) {
        setError(error as string);
      } finally {
        setLoading(false);
      }
    }
  }, [tenantSetupComplete, selectedTenant]);

  useEffect(() => {
    if (tenantSetupComplete && selectedTenant) {
      Promise.resolve(fetchUsers()).then(() => setupCompleteSet(true));
    }
  }, [tenantSetupComplete, selectedTenant, fetchUsers]);

  useEffect(() => {
    let filteredUsers = users;

    if (selectedElection) {
      if (selectedElection !== 'All') {
        filteredUsers = filteredUsers.filter((user) =>
          user.assignments.find((a) => a.date === selectedElection)
        );
      }
    }

    if (searchVal) {
      const loweredSearch = searchVal.toLowerCase();
      filteredUsers = filteredUsers.filter((user) => {
        if (user.id.toLowerCase().includes(loweredSearch)) {
          return user;
        }
        if (user.phoneNumber.includes(loweredSearch)) {
          return user;
        }
        if (
          `${user.firstName}${user.lastName}`
            .replaceAll(' ', '')
            .toLowerCase()
            .includes(loweredSearch.replaceAll(' ', ''))
        ) {
          return user;
        }
        if (user.firstName.toLowerCase().includes(loweredSearch)) {
          return user;
        }
        if (user.lastName.toLowerCase().includes(loweredSearch)) {
          return user;
        }
      });
    }

    setFilteredUsers(filteredUsers);
  }, [users, searchVal, selectedElection]);

  const refreshUsers = () => {
    fetchUsers();
  };

  return {
    users,
    filteredUsers,
    searchVal,
    setSearchVal,
    fetching: loading,
    setupComplete,
    lastFetch,
    error,
    refreshUsers,
  };
};

export const UsersContextProvider: React.FC<WithChildren> = ({
  children,
}: WithChildren) => {
  const value = useUsersContext();
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
