/* eslint-disable */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Link,
} from '@mui/material';
import moment from 'moment';
import { Warning, Error } from '@mui/icons-material';

import { TenantContext } from '../../context/TenantContext';
import { DashboardContext } from '../../context/DashboardContext';

import { TenantOverview } from '../../entities/Pollstation';

interface LinkProps {
  count: number;
  onClick: () => void;
}
const StatLink: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <Link
      underline="hover"
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
    >
      <Typography variant="body1">{props.count}</Typography>
    </Link>
  );
};

interface OverviewTableProps {
  data: any[];
  electionDate: string | undefined;
}
const OverviewTable: React.FC<OverviewTableProps> = (
  props: OverviewTableProps
) => {
  const history = useHistory();
  const { setTenant, setElection } = useContext(TenantContext);
  const {
    resetAllStationFilters,
    setNoBPAFilter,
    setVotesNotCastFilter,
    setTokensFilter,
    setBatteryFilter,
    setReportsFilter,
    setHeartbeatFilter,
    setSyncIssuesFilter,
  } = useContext(DashboardContext);

  const tenantOnClick = (tenant: string) => {
    resetAllStationFilters();
    setTenant(tenant);
    history.push('/dashboard');
    if (props.electionDate) {
      setElection(props.electionDate);
    }
  };

  const afterClose = (): boolean => {
    let issue = false;
    if (props.electionDate) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g Europe/London
      const close = moment.utc(`${props.electionDate} 22:00`).tz(timeZone);
      if (close.isBefore()) {
        issue = true;
      }
    }
    return issue;
  };

  const stationsAreOpen = (): boolean => {
    let open = false;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g Europe/London
    const nowHour = moment().tz(timeZone).hour();
    if (nowHour >= 7 && nowHour < 22) {
      open = true;
    }
    return open;
  };

  const isElectionDay = (): boolean => {
    let isElectionDay = false;
    const todayDate = moment().format(`YYYY-MM-DD`);
    if (todayDate === props?.electionDate) {
      isElectionDay = true;
    }
    return isElectionDay;
  };

  const checkTokenStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setTokensFilter(true);
    };
    if (count > 0 && isElectionDay() && stationsAreOpen()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <Typography variant="caption" paragraph>
              <div>{`Some stations have users who have become unauthenticated.`}</div>
              <div>{`This can be caused due to: `}</div>
              <div>{`  - A user has decided to log out. `}</div>
              <div>{`  - A user is logged in and having sync issues. `}</div>
              <div>{`Please monitor the situation or contact the effected station(s) to ensure the system is working correctly.`}</div>
            </Typography>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Warning style={{ color: 'gold', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkHeartbeatStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setHeartbeatFilter(true);
    };
    if (count > 0 && isElectionDay() && stationsAreOpen()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>{`Some stations have devices which have not sent a heartbeat in over 1 hour. `}</div>
              <div>{`This can be caused due to:`}</div>
              <div>{`  - Connectivity issues (device or poor network).`}</div>
              <div>{`  - A logged out user.`}</div>
              <div>{`  - The station is quiet and the tablet has gone to sleep.`}</div>
              <div>{`Please monitor the situation or contact the effected station(s) to ensure the system is working correctly.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Warning style={{ color: 'gold', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkVotesStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setVotesNotCastFilter(true);
    };
    if (count > 0 && isElectionDay() && stationsAreOpen()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>{`Some stations have not cast any votes. `}</div>
              <div>{`This can be caused due to:`}</div>
              <div>{`  - A quiet station / slow turnout.`}</div>
              <div>{`  - Connectivity issues (device or poor network).`}</div>
              <div>{`  - The station can't issue ballots.`}</div>
              <div>{`  - The users assigned to the station can't log in.`}</div>
              <div>{`Please monitor the situation or contact the effected station(s) to ensure the system is working correctly.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Warning style={{ color: 'gold', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkSyncStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setSyncIssuesFilter(true);
    };
    if (count > 0 && isElectionDay() && stationsAreOpen()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>{`Some stations are experiencing sync issues. E.g. There are marks that can't leave the tablet to the server.`}</div>
              <div>{`This can be caused due to:`}</div>
              <div>{`  - The station can't issue ballots.`}</div>
              <div>{`  - The station has a misconfiguration causing marks to fail.`}</div>
              <div>{`  - The logged in user has become unauthenticated mid session. Causing marks going to the server to fail.`}</div>
              <div>{`Please monitor the situation or contact the effected station(s) to ensure the system is working correctly.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Warning style={{ color: 'gold', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkBatteryStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setBatteryFilter(true);
    };
    if (count > 0 && isElectionDay() && stationsAreOpen()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>{`Some stations have devices that are running low on battery.`}</div>
              <div>{`Please monitor the situation or contact the effected station(s) to ensure that the device is being charged and no charging equipment is faulty.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Warning style={{ color: 'gold', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkBPAStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setNoBPAFilter(true);
    };
    if (count > 0 && afterClose()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>{`Some stations have not submitted their Ballot Paper Accounts.`}</div>
              <div>{`This needs addressed immedietely, contact the station to ensure:`}</div>
              <div>{`  - They're not currently counting.`}</div>
              <div>{`  - The BPA matches what the PO expects.`}</div>
              <div>{`  - There are no records on the device that still need sent (The sync icon has a green tick)`}</div>
              <div>{`  - The PO hasn't logged out or physically left the station without submitting the BPA.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Error style={{ color: 'red', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  const checkReportStatus = (count: number, tenant: string) => {
    const onClick = () => {
      tenantOnClick(tenant);
      setReportsFilter(true);
    };
    if (count > 0 && afterClose()) {
      return (
        <Tooltip
          onClick={onClick}
          title={
            <>
              <div>
                {`Some stations have not submitted ANY reports yet. This needs addressed immedietely, contact the station to ensure: `}
              </div>
              <div>{`  - They're not currently counting. `}</div>
              <div>{`  - The BPA matches what the PO expects" `}</div>
              <div>{`  - There are no records on the device that still need sent (The sync icon has a green tick) `}</div>
              <div>{`  - The PO hasn't logged out or physically left the station without submitting the BPA. `}</div>
              <div>{`  - The BPA can be submitted.`}</div>
            </>
          }
        >
          <div className="align-middle">
            <StatLink count={count} onClick={onClick} />
            <Error style={{ color: 'red', marginLeft: '1rem' }} />
          </div>
        </Tooltip>
      );
    }
    return <StatLink count={count} onClick={onClick} />;
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{'Tenant Name'}</TableCell>
          <TableCell>{'Polling Stations (Using Modern Polling)'}</TableCell>
          <TableCell>{'Expired Tokens'}</TableCell>
          <TableCell>{'Last Heartbeat 1hr+'}</TableCell>
          <TableCell>{'No Votes Cast'}</TableCell>
          <TableCell>{'Data Not Synced'}</TableCell>
          <TableCell>{'Low Battery'}</TableCell>
          <TableCell>{'BPA Not Submitted'}</TableCell>
          <TableCell>{'No Reports Generated'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data
          .sort((a: TenantOverview, b: TenantOverview) =>
            a.tenant.localeCompare(b.tenant)
          )
          .map((details: TenantOverview, index: number) => (
            <TableRow key={index}>
              <TableCell id={details.tenant} onClick={() => tenantOnClick(details.tenant)}>
                {details.tenant}
              </TableCell>
              <TableCell onClick={() => tenantOnClick(details.tenant)}>
                {details.usingMP}
              </TableCell>
              <TableCell className="align-middle">
                {checkTokenStatus(details.tokens, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkHeartbeatStatus(details.heartbeat, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkVotesStatus(details.noVotes, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkSyncStatus(details.syncIssues, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkBatteryStatus(details.lowBattery, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkBPAStatus(details.noBPA, details.tenant)}
              </TableCell>
              <TableCell className="align-middle">
                {checkReportStatus(details.noReports, details.tenant)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(OverviewTable);
