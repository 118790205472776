import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { BallotConfig } from '../../../entities/Pollstation';
import Table from '@mui/material/Table';
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BPASubmitRows = (props: any) => {
  const ballots: BallotConfig = props.ballots;
  return (
    <Table size={'small'} style={{ padding: 'none' }}>
      <TableHead>
        <TableRow>
          <TableCell>{'Election'}</TableCell>
          <TableCell>{'Time Created'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(ballots).map(([key, election]) =>
          Object.values(election).map((ballot, index) => (
            <TableRow
              key={`Pollstation-${index}-device-${key}-${ballot.electionname}-${index}}`}
            >
              <TableCell style={{ borderBottom: 'none' }}>
                {ballot.electionname}
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {ballot?.bpaSubmitTime?.split(' ')[1] || '...'}
                  {ballot?.bpaSubmitTime && (
                    <CheckCircle
                      fontSize="small"
                      style={{ marginLeft: '0.5rem', color: 'forestgreen' }}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default React.memo(BPASubmitRows);
