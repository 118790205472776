import React, { useState } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { GenericModalProps } from '../../GenericModalProps';
import MDModal from '../../../shared/MDModal';
import { csvExtension } from '../../../../utils/utils';

interface ParsedDataItem {
  ElectionDate: string;
  ElectionDescription: string;
  TypeDescription: string;
  AreaName: string;
  PollingStationNumber: string;
  PollingPlaceName: string;
  PollingPlaceAddress1: string;
  PollingPlaceAddress2: string;
  PollingPlaceAddress3: string;
  PollingPlaceAddress4: string;
  PollingPlaceAddress5: string;
  PollingPlacePostCode: string;
  PostalColour: string;
  PostalBookSize: string;
  AreaPostalStart: string;
  AreaPostalEnd: string;
  OrdinaryColour: string;
  OrdinaryBookSize: string;
  OrdinaryStart: string;
  OrdinaryEnd: string;
  TenderedColour: string;
  TenderedBookSize: string;
  TenderedStart: string;
  TenderedEnd: string;
  sysPSAreaElectorCount: string;
  ElectionId: string;
  ElectionTypeId: string;
  ElectionAreaId: string;
  PSId: string;
  UseBPRanges: string;
  OverseasEarlyPostalColour: string;
  OverseasEarlyPostalBookSize: string;
  OverseasEarlyPostalStart: string;
  OverseasEarlyPostalEnd: string;
  LostSpoiltColour: string;
  LostSpoiltBookSize: string;
  LostSpoiltStart: string;
  LostSpoiltEnd: string;
  UIM: string;
  BPPrefix: string;
  'Area Body Name (Level)': string;
  'Electoral Area Name': string;
  'Electoral Area Type': string;
  'Election Date': string;
  'Election Date 2': string;
  'Station Number': string;
  'Colour 1 (Ordinary)': string;
  'Colour 1 B.P.UIM.Prefix': string;
  'Colour 1 B.P. Start No.': string;
  'Colour 1 B.P. Finish No.': string;
  'Colour 1 UIM Start No.': string;
  'Colour 1 UIM Finish No.': string;
  'Colour 2 (Tendered)': string;
  'Colour 2 B.P.UIM.Prefix': string;
  'Colour 2 B.P. Start No.': string;
  'Colour 2 B.P. Finish No.': string;
  'Colour 2 UIM Start No.': string;
  'Colour 2 UIM Finish No.': string;
  'Area Code': string;
  'Electorate for Area': string;
  'No of AVs Issued for Area': string;
  'No of Proxies Issued for Area': string;
  'No of Vacancies for Area': string;
  'Election Date (DD/MM/YYYY)': string;
  'Election Level Code': string;
  'Election Level Description': string;
  'Officer Title': string;
  'Officer Name': string;
  'Election Title': string;
  'Election Title 2': string;
  'Election Ref': string;
  'Election ID': string;
  'Premise Name': string;
  'UA Name': string;
  'No of Ord Books': string;
  'No of Tend Books': string;
  'Station Letter': string;
  'Station Text': string;
  'Ballot Box No': string;
  'Ballot Box Letter': string;
  'Poll Station ID': string;
  ElectionID: string;
  ElectionName: string;
  ElectoralArea: string;
  ElectoralAreaType: string;
  ElectoralAreaID: string;
  Colour1_Ordinary: string;
  Colour1BPStartNo: string;
  Colour1BPEndNo: string;
  Colour1UIMStartNo: string;
  Colour1UIMEndNo: string;
  Colour2_Tendered: string;
  Colour2BPStartNo: string;
  Colour2BPEndNo: string;
  Colour2UIMStartNo: string;
  Colour2UIMEndNo: string;
  NumberOfSeats: string;
  OfficerTitle: string;
  OfficerName: string;
  PollingPlace: string;
  CouncilName: string;
  NoOfOrdBooks: string;
  NoOfTendBooks: string;
  PollingStationID: string;
  BallotBoxNo: string;
  BallotBoxID: string;
  UIMPrefix: string;
}

interface ConvertBallotProps extends GenericModalProps {
  title: string;
}

const ConvertBallotModal = (props: ConvertBallotProps) => {
  const { title, open, onClose } = props;
  const [selectedToType, setSelectedToType] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleSelectedToTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedToType(event.target.value);
  };

  const handleFilesUpload = async (files: FileList | null) => {
    if (files) {
      const zip = new JSZip();
      const fileArray = Array.from(files);
      for (let index = 0; index < fileArray.length; index++) {
        const file = fileArray[index];
        console.log(`Processing file: ${file.name}`);

        await new Promise<void>((resolve) => {
          parseCsvFile(file, (parsedData: ParsedDataItem[], fields: string) => {
            let inputType = undefined;
            let transformedData = undefined;
            console.log(fields);
            switch (fields) {
              case '["Area Body Name (Level)","Electoral Area Name","Electoral Area Type","Election Date","Election Date 2","Station Number","Colour 1 (Ordinary)","Colour 1 B.P.UIM.Prefix","Colour 1 B.P. Start No.","Colour 1 B.P. Finish No.","Colour 1 UIM Start No.","Colour 1 UIM Finish No.","Colour 2 (Tendered)","Colour 2 B.P.UIM.Prefix","Colour 2 B.P. Start No.","Colour 2 B.P. Finish No.","Colour 2 UIM Start No.","Colour 2 UIM Finish No.","Area Code","Electorate for Area","No of AVs Issued for Area","No of Proxies Issued for Area","No of Vacancies for Area","Election Date (DD/MM/YYYY)","Election Level Code","Election Level Description","Officer Title","Officer Name","Election Title","Election Title 2","Election Ref","Election ID","Premise Name","UA Name","No of Ord Books","No of Tend Books","Station Letter","Station Text","Ballot Box No","Ballot Box Letter","Poll Station ID"]':
                inputType = 'idox';
                break;
              case '["ElectionDate","ElectionDescription","TypeDescription","AreaName","PollingStationNumber","PollingPlaceName","PollingPlaceAddress1","PollingPlaceAddress2","PollingPlaceAddress3","PollingPlaceAddress4","PollingPlaceAddress5","PollingPlacePostCode","PostalColour","PostalBookSize","AreaPostalStart","AreaPostalEnd","OrdinaryColour","OrdinaryBookSize","OrdinaryStart","OrdinaryEnd","TenderedColour","TenderedBookSize","TenderedStart","TenderedEnd","sysPSAreaElectorCount","ElectionId","ElectionTypeId","ElectionAreaId","PSId","UseBPRanges","OverseasEarlyPostalColour","OverseasEarlyPostalBookSize","OverseasEarlyPostalStart","OverseasEarlyPostalEnd","LostSpoiltColour","LostSpoiltBookSize","LostSpoiltStart","LostSpoiltEnd","UIM","BPPrefix"]':
                inputType = 'xpress';
                break;
              case '["ElectionID","ElectionName","ElectoralArea","ElectoralAreaType","ElectoralAreaID","ElectionDate","PollingStationNumber","Colour1_Ordinary","Colour1BPStartNo","Colour1BPEndNo","Colour1UIMStartNo","Colour1UIMEndNo","Colour2_Tendered","Colour2BPStartNo","Colour2BPEndNo","Colour2UIMStartNo","Colour2UIMEndNo","NumberOfSeats","OfficerTitle","OfficerName","PollingPlace","CouncilName","NoOfOrdBooks","NoOfTendBooks","PollingStationID","BallotBoxNo","BallotBoxID","UIMPrefix"]':
              case '["ElectionID","ElectionName","ElectoralArea","ElectoralAreaType","ElectoralAreaID","ElectionDate","PollingStationNumber","Colour1_Ordinary","Colour1BPStartNo","Colour1BPEndNo","Colour1UIMStartNo","Colour1UIMEndNo","Colour2_Tendered","Colour2BPStartNo","Colour2BPEndNo","Colour2UIMStartNo","Colour2UIMEndNo","NumberOfSeats","OfficerTitle","OfficerName","PollingPlace","CouncilName","NoOfOrdBooks","NoOfTendBooks","PollingStationID","BallotBoxNo","BallotBoxID","UIMPrefix","First Name","Surname"]':
                inputType = 'dc';
                break;
              default:
                return setErrorMessage(
                  'EMS input type could not be determined'
                );
            }

            console.log('Convert from ' + inputType);
            console.log('Convert to ' + selectedToType);
            if (inputType === selectedToType) {
              return setErrorMessage(
                `The input file type ${inputType} is the same as output type ${selectedToType}`
              );
            }
            switch (inputType) {
              case 'idox':
                switch (selectedToType) {
                  case 'xpress':
                    transformedData = parsedData.map((item) => {
                      console.log(JSON.stringify(item));
                      return {
                        ElectionDate: item['Election Date'],
                        ElectionDescription: item['Election Title'],
                        TypeDescription: item['Election Ref'],
                        AreaName: item['Electoral Area Name'],
                        PollingStationNumber: item['Station Number'],
                        PollingPlaceName: item['Premise Name'],
                        PollingPlaceAddress1: '',
                        PollingPlaceAddress2: '',
                        PollingPlaceAddress3: '',
                        PollingPlaceAddress4: '',
                        PollingPlaceAddress5: '',
                        PollingPlacePostCode: '',
                        PostalColour: '',
                        PostalBookSize: '',
                        AreaPostalStart: '',
                        AreaPostalEnd: '',
                        OrdinaryColour: item['Colour 1 (Ordinary)'],
                        OrdinaryBookSize:
                          (parseInt(item['Colour 1 B.P. Finish No.'], 10) -
                            (parseInt(item['Colour 1 B.P. Start No.'], 10) -
                              1)) /
                          parseInt(item['No of Ord Books'], 10),
                        OrdinaryStart: item['Colour 1 B.P. Start No.'],
                        OrdinaryEnd: item['Colour 1 B.P. Finish No.'],
                        TenderedColour: item['Colour 2 (Tendered)'],
                        TenderedBookSize:
                          (parseInt(item['Colour 2 B.P. Finish No.'], 10) -
                            (parseInt(item['Colour 2 B.P. Start No.'], 10) -
                              1)) /
                          parseInt(item['No of Tend Books'], 10),
                        TenderedStart: item['Colour 2 B.P. Start No.'],
                        TenderedEnd: item['Colour 2 B.P. Finish No.'],
                        sysPSAreaElectorCount: item['Electorate for Area'],
                        ElectionId: item['Election ID'],
                        ElectionTypeId: item['Election Level Code'],
                        ElectionAreaId: item['Area Code'],
                        PSId: item['Poll Station ID'],
                        UseBPRanges: '',
                        OverseasEarlyPostalColour: '',
                        OverseasEarlyPostalBookSize: '',
                        OverseasEarlyPostalStart: '',
                        OverseasEarlyPostalEnd: '',
                        LostSpoiltColour: '',
                        LostSpoiltBookSize: '',
                        LostSpoiltStart: '',
                        LostSpoiltEnd: '',
                        UIM: item['Colour 1 B.P.UIM.Prefix'],
                        BPPrefix: '',
                      };
                    });
                    break;
                  case 'dc':
                    transformedData = parsedData.map((item) => {
                      return {
                        ElectionID: item['Election ID'],
                        ElectionName: item['Election Ref'],
                        ElectoralArea: item['Electoral Area Name'],
                        ElectoralAreaType: item['Electoral Area Type'],
                        ElectoralAreaID: item['Area Code'],
                        ElectionDate: item['Election Date'],
                        PollingStationNumber: item['Station Number'],
                        Colour1_Ordinary: item['Colour 1 (Ordinary)'],
                        Colour1BPStartNo: item['Colour 1 B.P. Start No.'],
                        Colour1BPEndNo: item['Colour 1 B.P. Finish No.'],
                        Colour1UIMStartNo: item['Colour 1 UIM Start No.'],
                        Colour1UIMEndNo: item['Colour 1 UIM Finish No.'],
                        Colour2_Tendered: item['Colour 2 (Tendered)'],
                        Colour2BPStartNo: item['Colour 2 B.P. Start No.'],
                        Colour2BPEndNo: item['Colour 2 B.P. Finish No.'],
                        Colour2UIMStartNo: item['Colour 2 UIM Start No.'],
                        Colour2UIMEndNo: item['Colour 2 UIM Finish No.'],
                        NumberOfSeats: item['No of Vacancies for Area'],
                        OfficerTitle: item['Officer Title'],
                        OfficerName: item['Officer Name'],
                        PollingPlace: item['Premise Name'],
                        CouncilName: item['Area Body Name (Level)'],
                        NoOfOrdBooks: item['No of Ord Books'],
                        NoOfTendBooks: item['No of Tend Books'],
                        PollingStationID: item['Poll Station ID'],
                        BallotBoxNo: item['Ballot Box No'],
                        BallotBoxID: item['Ballot Box Letter'],
                        UIMPrefix: item['Colour 1 B.P.UIM.Prefix'],
                      };
                    });
                    break;
                  case 'idox':
                    return setErrorMessage(
                      'EMS input type idox is the same as output type idox'
                    );
                  default:
                    return setErrorMessage(
                      'EMS output type could not be determined'
                    );
                }
                break;
              case 'xpress':
                switch (selectedToType) {
                  case 'idox':
                    transformedData = parsedData.map((item) => {
                      console.log(JSON.stringify(item));
                      return {
                        'Area Body Name (Level)': '',
                        'Electoral Area Name': item['AreaName'],
                        'Electoral Area Type': item['TypeDescription'],
                        'Election Date': item['ElectionDate'],
                        'Election Date 2': '',
                        'Station Number': item['PollingStationNumber'],
                        'Colour 1 (Ordinary)': item['OrdinaryColour'],
                        'Colour 1 B.P.UIM.Prefix': item['UIM'],
                        'Colour 1 B.P. Start No.': item['OrdinaryStart'],
                        'Colour 1 B.P. Finish No.': item['OrdinaryEnd'],
                        'Colour 1 UIM Start No.':
                          item['UIM'] + item['OrdinaryStart'],
                        'Colour 1 UIM Finish No.':
                          item['UIM'] + item['OrdinaryEnd'],
                        'Colour 2 (Tendered)': item['TenderedColour'],
                        'Colour 2 B.P.UIM.Prefix': item['UIM'],
                        'Colour 2 B.P. Start No.': item['TenderedStart'],
                        'Colour 2 B.P. Finish No.': item['TenderedEnd'],
                        'Colour 2 UIM Start No.':
                          item['UIM'] + item['TenderedStart'],
                        'Colour 2 UIM Finish No.':
                          item['UIM'] + item['TenderedEnd'],
                        'Area Code': item['ElectionAreaId'],
                        'Electorate for Area': '0',
                        'No of AVs Issued for Area': '0',
                        'No of Proxies Issued for Area': '0',
                        'No of Vacancies for Area': '0',
                        'Election Date (DD/MM/YYYY)': item['ElectionDate'],
                        'Election Level Code': item['ElectionTypeId'],
                        'Election Level Description': item['TypeDescription'],
                        'Officer Title': '',
                        'Officer Name': '',
                        'Election Title': item['ElectionDescription'],
                        'Election Title 2': '',
                        'Election Ref': item['TypeDescription'],
                        'Election ID': item['ElectionId'],
                        'Premise Name': item['PollingPlaceName'],
                        'UA Name': '',
                        'No of Ord Books':
                          (parseInt(item['OrdinaryEnd'], 10) -
                            (parseInt(item['OrdinaryStart'], 10) - 1)) /
                          parseInt(item['OrdinaryBookSize'], 10),
                        'No of Tend Books':
                          (parseInt(item['TenderedEnd'], 10) -
                            (parseInt(item['TenderedStart'], 10) - 1)) /
                          parseInt(item['TenderedBookSize'], 10),
                        'Station Letter': '',
                        'Station Text': '',
                        'Ballot Box No': item['PollingStationNumber'],
                        'Ballot Box Letter': '',
                        'Poll Station ID': item['PSId'],
                      };
                    });

                    break;
                  case 'dc':
                    transformedData = parsedData.map((item) => {
                      console.log(JSON.stringify(item));
                      return {
                        ElectionID: item['ElectionId'],
                        ElectionName: item['TypeDescription'],
                        ElectoralArea: item['AreaName'],
                        ElectoralAreaType: item['ElectionDescription'],
                        ElectoralAreaID: item['ElectionAreaId'],
                        ElectionDate: item['ElectionDate'],
                        PollingStationNumber: item['PollingStationNumber'],
                        Colour1_Ordinary: item['OrdinaryColour'],
                        Colour1BPStartNo: item['OrdinaryStart'],
                        Colour1BPEndNo: item['OrdinaryEnd'],
                        Colour1UIMStartNo: item['UIM'] + item['OrdinaryStart'],
                        Colour1UIMEndNo: item['UIM'] + item['OrdinaryEnd'],
                        Colour2_Tendered: item['TenderedColour'],
                        Colour2BPStartNo: item['TenderedStart'],
                        Colour2BPEndNo: item['TenderedEnd'],
                        Colour2UIMStartNo: item['UIM'] + item['TenderedStart'],
                        Colour2UIMEndNo: item['UIM'] + item['TenderedEnd'],
                        NumberOfSeats: '0',
                        OfficerTitle: '',
                        OfficerName: '',
                        PollingPlace: item['PollingPlaceName'],
                        CouncilName: '',
                        NoOfOrdBooks:
                          (parseInt(item['OrdinaryEnd'], 10) -
                            (parseInt(item['OrdinaryStart'], 10) - 1)) /
                          parseInt(item['OrdinaryBookSize'], 10),
                        NoOfTendBooks:
                          (parseInt(item['TenderedEnd'], 10) -
                            (parseInt(item['TenderedStart'], 10) - 1)) /
                          parseInt(item['TenderedBookSize'], 10),
                        PollingStationID: item['PSId'],
                        BallotBoxNo: item['PollingStationNumber'],
                        BallotBoxID: '',
                        UIMPrefix: item['UIM'],
                      };
                    });
                    break;
                  case 'xpress':
                    return setErrorMessage(
                      'EMS input type xpress is the same as output type xpress'
                    );
                  default:
                    return setErrorMessage(
                      'EMS output type could not be determined'
                    );
                }
                break;
              case 'dc':
                switch (selectedToType) {
                  case 'idox':
                    transformedData = parsedData.map((item) => {
                      console.log(JSON.stringify(item));
                      return {
                        'Area Body Name (Level)': item['CouncilName'],
                        'Electoral Area Name': item['ElectoralArea'],
                        'Electoral Area Type': item['ElectoralAreaType'],
                        'Election Date': item['ElectionDate'],
                        'Election Date 2': '',
                        'Station Number': item['PollingStationNumber'],
                        'Colour 1 (Ordinary)': item['Colour1_Ordinary'],
                        'Colour 1 B.P.UIM.Prefix':
                          item['UIMPrefix'] + item['ElectoralAreaID'],
                        'Colour 1 B.P. Start No.': item['Colour1BPStartNo'],
                        'Colour 1 B.P. Finish No.': item['Colour1BPEndNo'],
                        'Colour 1 UIM Start No.': item['Colour1UIMStartNo'],
                        'Colour 1 UIM Finish No.': item['Colour1UIMEndNo'],
                        'Colour 2 (Tendered)': item['Colour2_Tendered'],
                        'Colour 2 B.P.UIM.Prefix':
                          item['UIMPrefix'] + item['ElectoralAreaID'],
                        'Colour 2 B.P. Start No.': item['Colour2BPStartNo'],
                        'Colour 2 B.P. Finish No.': item['Colour2BPEndNo'],
                        'Colour 2 UIM Start No.': item['Colour2UIMStartNo'],
                        'Colour 2 UIM Finish No.': item['Colour2UIMEndNo'],
                        'Area Code': item['ElectoralAreaID'],
                        'Electorate for Area': '0',
                        'No of AVs Issued for Area': '0',
                        'No of Proxies Issued for Area': '0',
                        'No of Vacancies for Area': item['NumberOfSeats'],
                        'Election Date (DD/MM/YYYY)': item['ElectionDate'],
                        'Election Level Code': '',
                        'Election Level Description': item['ElectoralAreaType'],
                        'Officer Title': item['OfficerTitle'],
                        'Officer Name': item['OfficerName'],
                        'Election Title': item['ElectionName'],
                        'Election Title 2': '',
                        'Election Ref': item['ElectionName'],
                        'Election ID': item['ElectionID'],
                        'Premise Name': item['PollingPlace'],
                        'UA Name': '',
                        'No of Ord Books': item['NoOfOrdBooks'],
                        'No of Tend Books': item['NoOfTendBooks'],
                        'Station Letter': '',
                        'Station Text': '',
                        'Ballot Box No': item['BallotBoxNo'],
                        'Ballot Box Letter': item['BallotBoxID'],
                        'Poll Station ID': item['PollingStationID'],
                      };
                    });
                    break;
                  case 'xpress':
                    transformedData = parsedData.map((item) => {
                      console.log(JSON.stringify(item));
                      return {
                        ElectionDate: item['ElectionDate'],
                        ElectionDescription: item['ElectoralAreaType'],
                        TypeDescription: item['ElectionName'],
                        AreaName: item['ElectoralArea'],
                        PollingStationNumber: item['PollingStationNumber'],
                        PollingPlaceName: item['PollingPlace'],
                        PollingPlaceAddress1: '',
                        PollingPlaceAddress2: '',
                        PollingPlaceAddress3: '',
                        PollingPlaceAddress4: '',
                        PollingPlaceAddress5: '',
                        PollingPlacePostCode: '',
                        PostalColour: '',
                        PostalBookSize: '',
                        AreaPostalStart: '',
                        AreaPostalEnd: '',
                        OrdinaryColour: item['Colour1_Ordinary'],
                        OrdinaryBookSize:
                          (parseInt(item['Colour1BPEndNo'], 10) -
                            (parseInt(item['Colour1BPStartNo'], 10) - 1)) /
                          parseInt(item['NoOfOrdBooks'], 10),
                        OrdinaryStart: item['Colour1BPStartNo'],
                        OrdinaryEnd: item['Colour1BPEndNo'],
                        TenderedColour: item['Colour2_Tendered'],
                        TenderedBookSize:
                          (parseInt(item['Colour2BPEndNo'], 10) -
                            (parseInt(item['Colour2BPStartNo'], 10) - 1)) /
                          parseInt(item['NoOfTendBooks'], 10),
                        TenderedStart: item['Colour2BPStartNo'],
                        TenderedEnd: item['Colour2BPEndNo'],
                        sysPSAreaElectorCount: '0',
                        ElectionId: item['ElectionID'],
                        ElectionTypeId: '',
                        ElectionAreaId: item['ElectoralAreaID'],
                        PSId: item['PollingStationID'],
                        UseBPRanges: '',
                        OverseasEarlyPostalColour: '',
                        OverseasEarlyPostalBookSize: '',
                        OverseasEarlyPostalStart: '',
                        OverseasEarlyPostalEnd: '',
                        LostSpoiltColour: '',
                        LostSpoiltBookSize: '',
                        LostSpoiltStart: '',
                        LostSpoiltEnd: '',
                        UIM: item['UIMPrefix'] + item['ElectoralAreaID'],
                        BPPrefix: '',
                      };
                    });
                    break;
                  case 'dc':
                    return setErrorMessage(
                      'EMS input type dc is the same as output type dc'
                    );
                  default:
                    return setErrorMessage(
                      'EMS output type could not be determined'
                    );
                }
                break;
              default:
                return setErrorMessage('Could not determine input file type');
            }

            if (transformedData) {
              const csvData = Papa.unparse(transformedData as any);

              const fileName = csvExtension(file.name);
              // Filter out empty rows from csvData
              const filteredCsvData = csvData
                .split('\n')
                .filter((row) => !/^\s*,*\s*$/.test(row))
                .join('\n');
              console.log(`Processed ${fileName}`);
              zip.file(`processed_${fileName}`, filteredCsvData);
              resolve();
            }
          });
        });

        if (index === files.length - 1) {
          await new Promise<void>((resolve) => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'processed_data.zip');
              console.log('Zip file generated');
              resolve();
            });
          });
        }
      }
    }
  };

  const parseCsvFile = (
    file: File,
    onDataParsed: (parsedData: ParsedDataItem[], fields: string) => void
  ) => {
    Papa.parse<ParsedDataItem>(file, {
      complete: (result) => {
        onDataParsed(result.data, JSON.stringify(result.meta.fields));
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      errorMessage={errorMessage}
      content={
        <Container component="div" maxWidth="xs">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="type-to-select-label">
              Select Output EMS Type
            </InputLabel>
            <Select
              labelId="type-to-select-label"
              id="output-select"
              value={selectedToType}
              onChange={handleSelectedToTypeChange}
              label="Select Output EMS Type"
              style={{ margin: '10px 0' }}
            >
              <MenuItem value="xpress">Xpress</MenuItem>
              <MenuItem value="idox">Idox-Eros</MenuItem>
              <MenuItem value="dc">Democracy Counts</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" component="label">
            Upload File(s)
            <input
              type="file"
              accept=".csv, .tsv"
              multiple
              onChange={(e) => handleFilesUpload(e.target.files)}
              hidden
            />
          </Button>
        </Container>
      }
    />
  );
};

export default ConvertBallotModal;
