import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import '../../../../styles.css';
import MDModal from '../../../shared/MDModal';
import { Storage } from 'aws-amplify';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { format } from 'date-fns';
import { postcodeUpload } from '../../../../services/api/support';

interface UploadCSV extends GenericModalProps {
  title: string;
}

const UploadCSVModal = ({
  open,
  onClose,
  title = 'Upload CSV',
}: UploadCSV): JSX.Element => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSubmitEnabled(fileSelected ? true : false);
  }, [fileSelected]);

  const selectFile = () => {
    inputFile?.current?.click();
  };
  // eslint-disable-next-line
  const handleChange = async (event: any) => {
    const file = event.target.files[0];
    setFileSelected(file ? true : false);
    setCurrentFile(file);
    setFileName(file.name);
  };
  // eslint-disable-next-line
  function downloadFile(file: any, filename: string) {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  const upload = async () => {
    const date = format(new Date(), 'yyyy-MM-dd-HH-mm-ss');
    const parsedFileName = fileName.replace(/[^\w.]/gi, '_');
    const destination = `${date}/${parsedFileName}`;
    try {
      setLoading(true);
      const response = await Storage.put(destination, currentFile, {
        level: 'public',
        contentType: currentFile?.type,
      });
      const filePath = response.key;
      await postcodeUpload({ filePath });
      setSuccess(true);
      // eslint-disable-next-line
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setLoading(false);
      await Storage.get('parsedResults/latestResults.csv', {
        level: 'public',
        download: true,
      }).then((file) => {
        downloadFile(file.Body, 'latestResults.csv');
      });
    }
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
            onClick={selectFile}
          >
            Upload File
          </Button>
          <input
            style={{ display: 'none' }}
            ref={inputFile}
            type="file"
            onChange={handleChange}
          />
          {fileSelected && (
            <TextField
              label="Uploaded File: "
              size="medium"
              style={{ marginTop: '20px' }}
              value={fileName}
              disabled={true}
              fullWidth
            ></TextField>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Example template:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>postcode</TableCell>
                  <TableCell>address</TableCell>
                  <TableCell>address2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>BT487PB</TableCell>
                  <TableCell>2 Strand Rd</TableCell>
                  <TableCell>Lesley Retail Park</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={upload}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default UploadCSVModal;
