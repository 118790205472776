import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import '../styles.css';
interface AppHeaderProps {
  userVerified: boolean;
  handleLogout: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  userVerified,
  handleLogout,
}: AppHeaderProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const homeClick = () => {
    history.push('/overview');
  };

  const logoutClick = () => {
    history.push('/');
    handleLogout();
  };

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <img className={classes.logo} src={logo} alt="MD Logo" /> */}
        <div
          id="dashboards"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" onClick={homeClick}>
            Dashboards
          </Typography>
          {pathname === '/dashboard' && (
            <Tooltip title="Go Back to Overview">
              <IconButton id="back" color="primary" onClick={homeClick}>
                <ArrowBackIcon color="secondary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {userVerified && (
          <IconButton id="logOut" color="primary" onClick={logoutClick}>
            <LockIcon color="secondary" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(AppHeader);
