import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button, Input } from '@mui/material';
import { DDBElector } from '../../../../entities/DynamoObjects';
import { GetElectorsRequest } from '../../../../entities/Support';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import '../../../../styles.css';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import TenantPollstationPicker from './TenantPollstationPicker';
import { DropDown } from '../../../shared/DropDown';
import {
  getFirstLastElector,
  updateFirstLastElector,
} from '../../../../services/api/support';

interface ElectorModalProps extends GenericModalProps {
  electorFetch: (params: GetElectorsRequest) => Promise<DDBElector[]>;
  title: string;
}

interface foundFirstLastElectors {
  stationnumber: string;
  firstElector: string;
  lastElector: string;
}

const UpdateFirstLastElectorModal: React.FC<ElectorModalProps> = ({
  open,
  onClose,
  electorFetch,
  title,
}: ElectorModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  const [selectedFirstElector, setSelectedFirstElector] = useState<string>('');
  const [selectedLastElector, setSelectedLastElector] = useState<string>('');

  const [electors, setElectors] = useState<any[]>([]);
  const [foundFirstLastElectors, setSelectedFirstElectors] =
    useState<foundFirstLastElectors>();

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = async () => {
    const data = {
      tenant: selectedTenant || '',
      stationId: selectedPollstation?.pollStationId || '',
      electionDate: selectedElectionDate || '',
      firstElector: selectedFirstElector,
      lastElector: selectedLastElector,
    };
    return Promise.resolve(setLoading(true))
      .then(() =>
        updateFirstLastElector(data)
          .then(() => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 5000);
          })
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  const handleGetElectors = async () => {
    const data = {
      tenant: selectedTenant || '',
      electionDate: selectedElectionDate || '',
      stationId: selectedPollstation?.pollStationId || '',
    };
    return Promise.resolve(setLoading(true))
      .then(() =>
        getFirstLastElector(data)
          .then((results) => setSelectedFirstElectors(results))
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedTenant && selectedElectionDate && selectedPollstation) {
      const params = {
        tenant: selectedTenant,
        electionDate: selectedElectionDate,
        stationId: selectedPollstation?.pollStationId || '',
      };

      Promise.resolve(setLoading(true))
        .then(() =>
          electorFetch(params)
            .then((electors) => {
              setElectors(electors);
            })
            .catch((error) => {
              setSuccess(false);
              setErrorMessage(error.message);
              setElectors([]);
              setSelectedFirstElector('');
              setSelectedLastElector('');
              setTimeout(() => setErrorMessage(''), 5000);
            })
        )
        .then(() => {
          console.log(foundFirstLastElectors);
          setLoading(false);
        });
    }
  }, [selectedTenant, selectedElectionDate, selectedPollstation]);

  useEffect(() => {
    setSubmitEnabled(
      selectedFirstElector?.length > 0 && selectedLastElector?.length > 0
    );
  }, [selectedFirstElector, selectedLastElector]);

  const electorOptions = useMemo(() => {
    if (electors) {
      return electors.map((elector) => {
        const idParts = elector.electorId.split('::');
        const id = idParts[idParts.length - 1];
        return {
          display: `${id} - ${elector.firstName} ${elector?.initials || ''}
            ${elector.lastName}`,
          value: `${elector.electorId}`,
        };
      });
    } else {
      return [];
    }
  }, [electors, selectedPollstation]);

  useEffect(() => {
    if (electorOptions?.length) {
      for (const el of electorOptions) {
        const [electorId, name] = el.display.split('-');
        if (
          selectedPollstation?.firstElector.includes(electorId) &&
          selectedPollstation?.firstElector.includes(name)
        ) {
          setSelectedFirstElector(el.value);
        }
        if (
          selectedPollstation?.lastElector.includes(electorId) &&
          selectedPollstation?.lastElector.includes(name)
        ) {
          setSelectedLastElector(el.value);
        }
      }
    }
  }, [electorOptions, selectedPollstation]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <TenantPollstationPicker />
          {selectedPollstation && (
            <DropDown
              disabled={loading}
              options={electorOptions}
              name="First Elector"
              label="First Elector"
              value={selectedFirstElector}
              onSelect={(value) => setSelectedFirstElector(value)}
              fullWidth
            />
          )}
          {selectedPollstation && (
            <DropDown
              disabled={loading}
              options={electorOptions}
              name="Last Elector"
              label="Last Elector"
              value={selectedLastElector}
              onSelect={(value) => setSelectedLastElector(value)}
              fullWidth
            />
          )}
          <Button
            disabled={!selectedPollstation}
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
            onClick={handleGetElectors}
          >
            Get expected first and last electors
          </Button>

          {foundFirstLastElectors && (
            <p>
              First Elector: {foundFirstLastElectors.firstElector}, Last
              Elector: {foundFirstLastElectors.lastElector}
            </p>
          )}
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default UpdateFirstLastElectorModal;
