import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Alert,
} from '@mui/material';
import TenantPollstationPicker from '../../ElectionDaySupport/Modals/TenantPollstationPicker';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { certificateOfEmploymentElector } from '../../../../services/api/support';
import { getData } from '../../../../services/api/rest';

interface ElectorModalProps extends GenericModalProps {
  title: string;
}
interface SearchElectorbyPdEnoParams {
  tenant: string;
  electionDate: string;
  pdEno: string;
}

const CreateCertificateOfEmploymentElector: React.FC<ElectorModalProps> = ({
  open,
  onClose,
  title,
}: ElectorModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    initials: '',
    lastName: '',
    uniqueIdentifier: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    areaName: [] as string[],
  });
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const [errorVisible, setErrorVisible] = useState<boolean>(false); // New state for error visibility

  const onSubmit = async () => {
    if (!formValues.firstName && !formValues.lastName) {
      setErrorMessage('Please enter at least a first name or a last name.');
      setTimeout(() => resetErrors(), 5000);
      return;
    }
    const data = {
      tenant: selectedTenant || '',
      stationId: selectedPollstation?.pollStationId || '',
      electionDate: selectedElectionDate || '',
      firstName: formValues.firstName.trim(),
      initials: formValues.initials.trim(),
      lastName: formValues.lastName.trim(),
      electorId: formValues.uniqueIdentifier.trim(),
      address1: formValues.address1.trim(),
      address2: formValues.address2.trim(),
      address3: formValues.address3.trim(),
      postcode: formValues.postcode.trim(),
      areaName: formValues.areaName.join(', ') || '',
    };
    try {
      setLoading(true);
      await certificateOfEmploymentElector(data);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        resetForm();
      }, 5000);
    } catch (error) {
      setSuccess(false);
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unknown error occurred');
      }
      setErrorVisible(true);
      setTimeout(() => resetErrors(), 5000);
    } finally {
      setLoading(false);
    }
  };

  const resetErrors = () => {
    setErrorMessage('');
    setErrorVisible(false);
  };

  const resetValues = () => {
    setFormValues({
      firstName: '',
      initials: '',
      lastName: '',
      uniqueIdentifier: '',
      address1: '',
      address2: '',
      address3: '',
      postcode: '',
      areaName: [],
    });
    setSubmitEnabled(false);
    setErrorVisible(true);
  };

  const validatePostcode = (postcode: string) => {
    const regex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0AA)$/i;
    return regex.test(postcode.replace(/\s/g, ''));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    let isValid = true;

    if (value) {
      if (id === 'firstName' || id === 'lastName') {
        isValid = validateName(value);
      } else if (id === 'address') {
        isValid = validateAddress(value);
      }
    }

    if (isValid || value === '') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    } else {
      setErrorMessage('Invalid input');
    }
  };

  const searchElectorByPdEno = async ({
    tenant,
    electionDate,
    pdEno,
  }: SearchElectorbyPdEnoParams) => {
    try {
      const url = `/support/getElectorById`;
      const queryParams = {
        tenant,
        pdEno,
        electionDate,
      };

      try {
        const response = await getData(url, queryParams);
        if (!response) {
          throw new Error('Network response was not ok');
        }

        return response;
      } catch (error) {
        console.error('Unable to get elector by PD-ENO:', error);
        throw error;
      }
    } catch (error) {
      console.error(
        'An error occurred while searching for the elector:',
        error
      );
    }
  };

  const validateName = (name: string): boolean => {
    const regex = /^(?=.*[a-zA-Z\u00C0-\u017F])[a-zA-Z\u00C0-\u017F0-9 .'-]+$/;
    return regex.test(name);
  };

  const validateAddress = (address: string): boolean => {
    const regex =
      /^[a-zA-Z\u00C0-\u017F0-9][a-zA-Z\u00C0-\u017F0-9 .,'-]*[a-zA-Z\u00C0-\u017F0-9]$/;
    return regex.test(address.trim());
  };

  const areaChange = (val: string[]) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      areaName: val || [],
    }));
  };

  const createMenuItem = (index: number, area: string): JSX.Element => {
    return (
      <MenuItem id={`${index}-${area}`} value={area}>
        {area}
      </MenuItem>
    );
  };

  const resetForm = () => {
    resetValues();
    setShowInputs(false);
    setFormValues((prevValues) => ({
      ...prevValues,
      uniqueIdentifier: '',
    }));
  };

  const handleCheckClick = async () => {
    console.log(
      `Checking elector with Tenant: ${selectedTenant}, Election Date: ${selectedElectionDate}, PD-ENO: ${formValues.uniqueIdentifier}`
    );
    if (selectedTenant && selectedElectionDate && formValues.uniqueIdentifier) {
      try {
        console.log('Attempting to search elector details...');
        let electorDetails = await searchElectorByPdEno({
          tenant: selectedTenant,
          electionDate: selectedElectionDate,
          pdEno: formValues.uniqueIdentifier.toLowerCase().trim(),
        });
        if (typeof electorDetails === 'string') {
          electorDetails = JSON.parse(electorDetails);
        }
        console.log(
          'Parsed elector details:',
          electorDetails,
          typeof electorDetails
        );

        if (electorDetails) {
          const { registeredAddress } = electorDetails;
          const { address1, address2, address3, postcode } =
            registeredAddress || {};
          setFormValues((prevValues) => ({
            ...prevValues,
            ...electorDetails,
            address1: address1 || '',
            address2: address2 || '',
            address3: address3 || '',
            postcode: postcode || '',
          }));
        } else {
          console.log('No elector found with the provided PD-ENO.');
          setErrorMessage('No elector found with the provided PD-ENO.');
          setErrorVisible(true);
          setTimeout(() => resetErrors(), 5000);
        }
      } catch (error) {
        console.error(
          'An error occurred while searching for the elector:',
          error
        );
        setErrorMessage('An error occurred while searching for the elector.');
        setErrorVisible(true); // Show error message when there's an error
        setTimeout(() => resetErrors(), 5000);
      }
    } else {
      console.log('Missing required fields: Tenant, Election Date, or PD-ENO.');
    }
    setShowInputs(true);
  };

  const areBothNamesBlank = () => {
    return !formValues.firstName && !formValues.lastName;
  };

  useEffect(() => {
    setSubmitEnabled(
      Boolean(
        selectedTenant &&
          selectedPollstation &&
          formValues.areaName.length > 0 &&
          /^[A-Za-z](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
            formValues.uniqueIdentifier
          ) &&
          validatePostcode(formValues.postcode)
      )
    );
  }, [selectedTenant, selectedPollstation, formValues]);

  const electoralAreas = useMemo(() => {
    if (selectedPollstation) {
      const areas = selectedPollstation?.electoralAreas
        .replace(/[[\]']+/g, '')
        .split('|');
      return Array.from(new Set(areas));
    } else {
      return [];
    }
  }, [selectedPollstation]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading || fetching}
      success={success}
      content={
        <>
          <TenantPollstationPicker />
          <div
            style={{
              margin: '2rem 0',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            {selectedPollstation && (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    disabled={loading}
                    id="uniqueIdentifier"
                    label="PD-ENO"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={formValues.uniqueIdentifier}
                    onChange={handleInputChange}
                    error={
                      !/^[A-Za-z0-9](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
                        formValues.uniqueIdentifier
                      ) && formValues.uniqueIdentifier !== ''
                    }
                    helperText={
                      !/^[A-Za-z0-9](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
                        formValues.uniqueIdentifier
                      ) && formValues.uniqueIdentifier !== ''
                        ? 'Invalid PD-ENO format'
                        : ''
                    }
                  />
                  <Button
                    id="checkBtn"
                    variant="contained"
                    color="primary"
                    onClick={handleCheckClick}
                    disabled={
                      loading ||
                      !formValues.uniqueIdentifier ||
                      !/^[A-Za-z0-9](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
                        formValues.uniqueIdentifier
                      )
                    }
                    style={{ marginLeft: '1rem', height: '56px' }}
                  >
                    Check
                  </Button>
                </div>
                {showInputs && (
                  <>
                    <TextField
                      disabled={loading}
                      id="firstName"
                      label="First Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.firstName}
                      onChange={handleInputChange}
                      error={
                        formValues.firstName.includes(' ') ||
                        (!validateName(formValues.firstName) &&
                          formValues.firstName !== '') ||
                        (!formValues.firstName && !formValues.lastName)
                      }
                      helperText={
                        (!validateName(formValues.firstName) &&
                          formValues.firstName !== '') ||
                        formValues.firstName.includes(' ')
                          ? 'Invalid name'
                          : !formValues.firstName && !formValues.lastName
                          ? 'At least one name is required'
                          : ''
                      }
                    />
                    <TextField
                      disabled={loading}
                      id="initials"
                      label="Middle Initial"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.initials}
                      onChange={handleInputChange}
                      error={formValues.initials.includes(' ')}
                    />
                    <TextField
                      disabled={loading}
                      id="lastName"
                      label="Last Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.lastName}
                      onChange={handleInputChange}
                      error={
                        !validateName(formValues.lastName) &&
                        !formValues.firstName &&
                        !formValues.lastName
                      }
                      helperText={
                        !validateName(formValues.lastName) &&
                        !formValues.firstName &&
                        !formValues.lastName
                          ? 'At least one name is required'
                          : ''
                      }
                    />
                    <TextField
                      disabled={loading}
                      id="address1"
                      label="Address Line 1"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.address1}
                      onChange={handleInputChange}
                    />
                    <TextField
                      disabled={loading}
                      id="address2"
                      label="Address Line 2"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.address2}
                      onChange={handleInputChange}
                    />
                    <TextField
                      disabled={loading}
                      id="address3"
                      label="Address Line 3"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.address3}
                      onChange={handleInputChange}
                    />
                    <TextField
                      disabled={loading}
                      id="postcode"
                      label="Postcode"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formValues.postcode}
                      onChange={handleInputChange}
                      error={
                        !validatePostcode(formValues.postcode) &&
                        formValues.postcode !== ''
                      }
                      helperText={
                        !validatePostcode(formValues.postcode) &&
                        formValues.postcode !== ''
                          ? 'Invalid postcode format'
                          : ''
                      }
                    />
                    <InputLabel id="areaName-label">Electoral Area</InputLabel>
                    <Select
                      labelId="areaName-label"
                      id="areaName"
                      multiple
                      value={formValues.areaName}
                      onChange={(event) =>
                        areaChange(event.target.value as string[])
                      }
                      fullWidth
                      disabled={loading}
                    >
                      {electoralAreas.map((area) => (
                        <MenuItem key={area} value={area}>
                          {area}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </>
            )}
          </div>
          {errorVisible && errorMessage && (
            <Alert severity="error" style={{ marginTop: '1rem' }}>
              {errorMessage}
            </Alert>
          )}
        </>
      }
      actionBar={
        <>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            id="submitBtn"
            onClick={onSubmit}
            color="primary"
            disabled={loading || !submitEnabled}
          >
            Submit
          </Button>
        </>
      }
    />
  );
};

export default CreateCertificateOfEmploymentElector;
