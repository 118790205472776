import React, { useContext } from 'react';
import { Popover, FormGroup } from '@mui/material';

import { TenantContext } from '../../../context/TenantContext';
import { DashboardContext } from '../../../context/DashboardContext';

import FilterListItem from './FilterListItem';

interface FilterListProps {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
}

const FilterList: React.FC<FilterListProps> = ({ open, anchor, onClose }) => {
  const { setTodaysElections, todaysElections } = useContext(TenantContext);

  const {
    closedStationsFilter,
    setClosedStationsFilter,
    noBPAFilter,
    setNoBPAFilter,
    votesNotCastFilter,
    setVotesNotCastFilter,
    setUsingMPFilter,
    usingMPFilter,
    tokensFilter,
    setTokensFilter,
    batteryFilter,
    setBatteryFilter,
    reportsFilter,
    setReportsFilter,
    heartbeatFilter,
    setHeartbeatFilter,
    syncIssuesFilter,
    setSyncIssuesFilter,
  } = useContext(DashboardContext);

  const closedCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClosedStationsFilter(event.currentTarget.checked);
  };

  const NoBPACheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoBPAFilter(event.currentTarget.checked);
  };

  const todayCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTodaysElections(event.currentTarget.checked);
  };

  const votesNotCast = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVotesNotCastFilter(event.currentTarget.checked);
  };

  const usingModernPolling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsingMPFilter(event.currentTarget.checked);
  };

  const tokensIssues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTokensFilter(event.currentTarget.checked);
  };

  const lowBattery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBatteryFilter(event.currentTarget.checked);
  };

  const missingReports = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportsFilter(event.currentTarget.checked);
  };

  const heartbeatIssues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeartbeatFilter(event.currentTarget.checked);
  };

  const syncIssues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncIssuesFilter(event.currentTarget.checked);
  };

  return (
    <Popover
      style={{ borderRadius: '10px !important' }}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
    >
      <div style={{ padding: '1rem' }}>
        <FormGroup>
          <FilterListItem
            label="Today's Elections"
            onChange={todayCheck}
            checked={todaysElections}
          />
          <FilterListItem
            label="Closed Stations"
            onChange={closedCheck}
            checked={closedStationsFilter}
          />
          <FilterListItem
            label="Stations Using Modern Polling"
            onChange={usingModernPolling}
            checked={usingMPFilter}
          />
          <FilterListItem
            label="Expired Tokens"
            onChange={tokensIssues}
            checked={tokensFilter}
          />
          <FilterListItem
            label="1H+ Heartbeat"
            onChange={heartbeatIssues}
            checked={heartbeatFilter}
          />
          <FilterListItem
            label="No Votes Cast"
            onChange={votesNotCast}
            checked={votesNotCastFilter}
          />
          <FilterListItem
            label="Sync Issues"
            onChange={syncIssues}
            checked={syncIssuesFilter}
          />
          <FilterListItem
            label="Low Battery"
            onChange={lowBattery}
            checked={batteryFilter}
          />
          <FilterListItem
            label="No BPA Submitted"
            onChange={NoBPACheck}
            checked={noBPAFilter}
          />
          <FilterListItem
            label="No Reports"
            onChange={missingReports}
            checked={reportsFilter}
          />
        </FormGroup>
      </div>
    </Popover>
  );
};

export default React.memo(FilterList);
