import React from 'react';
import { Box } from '@mui/material';

type TabPanelProps = {
  value: number;
  index: number;
  children: JSX.Element;
};

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div hidden={value !== index} style={{ padding: '1rem 1rem 0 1rem' }}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
