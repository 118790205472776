import { createQuery } from 'react-query-kit';

import { runScrubData } from '../api/scrub';
import {
  ScrubDataRequestType,
  ScrubDataQueryRequestType,
} from '../model/scrubber';
export const apiPath = '/dashboard/data-anonymisation';

const validated = (variables: ScrubDataRequestType): boolean => {
  let valid = false;

  valid =
    variables?.tenant?.length !== undefined &&
    variables?.tenant?.length > 0 &&
    variables.electionDate?.length !== undefined &&
    variables.electionDate?.length > 0 &&
    variables?.emsType?.length !== undefined &&
    variables.emsType?.length > 0;

  if (!valid) {
    throw new Error('Check required parameters.');
  }

  return valid;
};

/**
 * useScrubber.
 * @description Custom hook for running the data anonymising tasks.
 * Gives us useful stuff like isLoading, error, caching etc.
 */
export const useScrubber = createQuery<
  string[],
  ScrubDataQueryRequestType,
  Error
>(
  apiPath,
  async ({ queryKey: [, variables] }) => {
    let result: string[] = [];
    if (!validated(variables)) {
      return result;
    }

    try {
      const input: ScrubDataRequestType = {
        tenant: variables.tenant,
        electionDate: variables.electionDate,
        emsType: variables.emsType,
      };

      result = await runScrubData(input);
    } catch (error) {
      console.log(JSON.stringify(error));
      throw new Error('Scrubbing issue');
    }

    return result;
  },
  {
    // if u only wanna fetch once
    // enabled: (data) => !data,
    enabled: (data, variables) => {
      return (
        (!data || data.length === 0) &&
        variables.run !== undefined &&
        variables.run === true
      );
    },
    //suspense: true,
  }
);

export default useScrubber;
