/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pollstation, TenantOverview } from '../../entities/Pollstation';
import { TenantConfig } from '../../entities/DynamoObjects';
import { User, AvailableAssignments } from '../../entities/User';
import {
  UserAssignmentRequest,
  CreateUserRequest,
  DeleteUserAssignmentRequest,
  DeleteUserRequest,
  GetAvailableAssignmentsRequest,
  EditUserRequest,
  RemoveDeviceFromStationRequest,
  UpdateUsingMPRequest,
  RunValidationRequest,
  DownloadQueryViewRequest,
  scrubDataRequest,
  StartStateMachineRequest,
  StaffCheckRequest,
} from '../../entities/Support';
import { getData, postData, deleteData, patchData } from './rest';
import { ValidationResponseItem } from '../../entities/Tenant';
import { VALIDATION_LAMBDA_URL, DATA_SCRUBBING_URL } from '../../Env';
import { CnlCheckResults } from '../../entities/CnlResults';
import { S3FileObject } from '../../entities/S3FileObject';
import { StepFunctionResp } from '../../entities/StepFunctionResp';

export const fetchTenants = async (): Promise<TenantConfig[]> =>
  getData('/dashboard/tenants');

export const fetchStationDashboards = async (
  tenant: string,
  electionDate: string
): Promise<Pollstation[]> =>
  getData(`/dashboard?tenant=${tenant}&electionDate=${electionDate}`);

export const fetchOverview = async (
  electionDate: string
): Promise<TenantOverview[]> =>
  getData(`/dashboard/overview?electionDate=${electionDate}`);

export const getLatestReports = async (
  tenant: string,
  electionDate: string,
  combined: boolean
): Promise<any[]> =>
  getData(
    `/dashboard/reports?tenant=${tenant}&electionDate=${electionDate}&combined=${combined}`
  );

export const downloadElectionReport = async (
  file: string,
  combined: boolean
): Promise<any> =>
  getData(
    `/dashboard/download?filePath=${encodeURI(file)}&combined=${combined}`
  );

/**
 * downloadDataValidationResult.
 * @description Downloads file(s) from s3.
 * @param tenant.
 * @param electionDate.
 * @param file.
 * @returns File download.
 */
export const downloadDataValidationResult = async ({
  tenant,
  queryType,
  emsType,
}: DownloadQueryViewRequest): Promise<any> => {
  if (!queryType || queryType === 'all') {
    throw new Error('Query type is required and all is not valid.');
  }
  return getData(
    `/dashboard/tenants/${tenant}/data-validation/download/${queryType}?emsType=${emsType}`
  );
};

/**
 * runDataValidation.
 * @description Runs the data validation queries.
 * Executes a post given that files may be created.
 * @param tenant
 * @param electionDate
 * @param file
 * @param emsType
 * @returns An array of s3 signed url for downloading the csv.
 */
export const runDataValidation = async ({
  tenant,
  electionDate,
  emsType,
}: RunValidationRequest): Promise<ValidationResponseItem[]> => {
  const org = encodeURIComponent(tenant);
  const date = encodeURIComponent(electionDate);
  const ems = encodeURIComponent(emsType);
  const options = {
    mode: 'cors' as RequestMode,
    method: 'GET',
    headers: {
      'Access-Control-Request-Method': 'GET',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const res = await fetch(
    `${VALIDATION_LAMBDA_URL}data-validation/all?tenant=${org}&electionDate=${date}&emsType=${ems}`,
    options
  );
  const data = await res.json();
  return data;
};

export const getUsers = async (tenant: string): Promise<User[]> =>
  getData(`/dashboard/users?tenant=${tenant}`);

export const createUser = async (params: CreateUserRequest): Promise<void> => {
  const response = await postData(`/dashboard/createUser`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const deleteUser = async (params: DeleteUserRequest): Promise<void> => {
  const response = await deleteData(`/dashboard/user`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const editUser = async (params: EditUserRequest): Promise<void> => {
  const response = await patchData(`/dashboard/user`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const deleteUserAssignment = async (
  params: DeleteUserAssignmentRequest
): Promise<void> => {
  const response = await deleteData(`/dashboard/userAssignment`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const createUserAssignment = async (
  params: UserAssignmentRequest
): Promise<void> => {
  const response = await postData(`/dashboard/userAssignment`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const editUserAssignment = async (
  params: UserAssignmentRequest
): Promise<void> => {
  const response = await patchData(`/dashboard/userAssignment`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const getAvailableAssignments = async (
  params: GetAvailableAssignmentsRequest
): Promise<AvailableAssignments[]> => {
  const response = await getData(`/dashboard/availableAssignments`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const removeDeviceFromStation = async (
  params: RemoveDeviceFromStationRequest
): Promise<void> => {
  const response = await deleteData(`/dashboard/removeDevice`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const updateUsingMP = async (
  params: UpdateUsingMPRequest
): Promise<void> => {
  const response = await patchData(`/dashboard/usingMP`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const startStateMachine = async (
  params: StartStateMachineRequest
): Promise<StepFunctionResp> => {
  const response = await postData(`/dashboard/start-statemachine`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const startStaffCheck = async (
  params: StaffCheckRequest
): Promise<any> => {
  const response = await postData(`/dashboard/startstaffchecks`, params);
  if (response.message) throw new Error(response.message);
  return response;
};

export const runScrubData = async ({
  tenant,
  electionDate,
}: scrubDataRequest): Promise<string> => {
  const org = encodeURIComponent(tenant);
  const date = encodeURIComponent(electionDate);
  const options = {
    mode: 'cors' as RequestMode,
    method: 'GET',
    headers: {
      'Access-Control-Request-Method': 'GET',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const res = await fetch(
    `${DATA_SCRUBBING_URL}data-anonymise?tenant=${org}&electionDate=${date}`,
    options
  )
    .then((response) => response.text())
    .then((data) => {
      const sanitizedData = data.replace(/"/g, '');
      return sanitizedData;
    });
  console.log(`getScrubbedDataUrl > response > ${res}`);
  if (res) return res;
  return '';
};

// export const cnlrangeresults = async (
export const gets3results = async (
  electionDate: string,
  tenant: string,
  location: string
): Promise<S3FileObject[]> =>
  await getData(
    `/dashboard/gets3fileresults?tenant=${tenant}&electionDate=${electionDate}&location=${location}`
  );

// export const getcnlresultfile = async (
export const gets3resultfile = async (
  s3key: string,
  location: string
): Promise<CnlCheckResults> => {
  const key = encodeURIComponent(s3key);
  return await getData(
    `/dashboard/gets3resultfile?key=${key}&location=${location}`
  );
};

export const GetStateMachineStatus = async (params: {
  executionArn: string;
}): Promise<any> => {
  const response = await postData(`/dashboard/get-statemachine-status`, params);
  console.log(
    `GetStateMachineStatus >> response >> ${JSON.stringify(response)}`
  );
  if (response.message) throw new Error(response.message);
  return response;
};
