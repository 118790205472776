import React, { useState, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Popover,
  IconButton,
  Typography,
} from '@mui/material';
import { Notes, CheckCircle, Close } from '@mui/icons-material';
import { Pollstation } from '../../../entities/Pollstation';
import { BallotAccounting } from '../../../entities/DynamoObjects';
import './TableRow.css';

export interface PollstationBPAProps {
  pollstation: Pollstation;
  onClose: () => void;
}

const renderTotals = (bpa: BallotAccounting) => (
  <Table size="small" style={{ padding: 'none !important' }}>
    <TableBody>
      <TableRow>
        <TableCell>First Ballot Number</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.firstBallot}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Next Ballot Number</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.nextBallot}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Received</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.totalReceived}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Unused</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.totalUnused}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Issued</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.totalIssued}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Issued and Not Spoilt</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.totalIssuedAndNotSpoilt}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Spoilt</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="right">
          {bpa.totalSpoilt}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const PollstationBPA: React.FC<PollstationBPAProps> = (
  props: PollstationBPAProps
) => {
  const { pollstation, onClose } = props;

  const [notes, setNotes] = useState<string>('');
  const [anchorEl, setAnchor] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const areas = useMemo(
    () =>
      Object.values(pollstation.ballots)
        .map((election) => Object.entries(election))
        .flat(),
    [pollstation]
  );

  const handleNotesClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    notes: string
  ) => {
    setAnchor(event.currentTarget);
    setNotes(notes);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const renderBPAsubmit = (submitTime?: string) =>
    submitTime ? (
      <div className="ps-bpa-submit-time">
        <CheckCircle style={{ color: 'forestgreen' }} />
        <Typography variant="caption">{submitTime.split(' ')[1]}</Typography>
      </div>
    ) : (
      <></>
    );

  return (
    <div className="ps-bpa">
      <div className="ps-bpa-header">
        <Typography variant="body1">{`Station ${pollstation.number} BPA`}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow selected>
            <TableCell>Election Ref</TableCell>
            <TableCell>Electoral Area</TableCell>
            <TableCell>Ordinary Totals</TableCell>
            <TableCell align="center">Submitted</TableCell>
            <TableCell align="center">Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas.map(([area, ballotInfo], index) => (
            <TableRow key={`${area}-${index}-index`}>
              <TableCell>{ballotInfo.bref}</TableCell>
              <TableCell>{area}</TableCell>
              <TableCell>{renderTotals(ballotInfo.ordinary)}</TableCell>
              <TableCell align="center">
                {renderBPAsubmit(ballotInfo?.bpaSubmitTime)}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={(e) => handleNotesClick(e, ballotInfo.notes)}
                >
                  <Notes fontSize="large" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {open && notes && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div style={{ padding: '1rem' }}>
            <Typography variant="body1">
              {notes !== ' ' ? notes : 'No Notes'}
            </Typography>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default React.memo(PollstationBPA);
