import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import {
  BatteryFull,
  Battery90,
  Battery80,
  Battery60,
  Battery50,
  Battery30,
  Battery20,
  BatteryAlert,
  AddCircle,
  CheckCircle,
} from '@mui/icons-material';
import moment from 'moment';

import { iPad } from '../../entities/Device';

import DeviceStatus from '../shared/DeviceStatus';

export interface DeviceItemRowProps {
  device: iPad;
}

const DeviceItemRow: React.FC<DeviceItemRowProps> = (
  props: DeviceItemRowProps
) => {
  const device: iPad = props.device;

  const renderBatteryIcon = (batteryLevel: string, online: boolean) => {
    let icon;
    const battery = parseFloat(batteryLevel);
    if (battery > 0.9) {
      icon = (
        <BatteryFull
          fontSize="small"
          style={{ color: online ? 'forestgreen' : 'gray' }}
        />
      );
    } else if (battery > 0.8) {
      icon = (
        <Battery90
          fontSize="small"
          style={{ color: online ? 'forestgreen' : 'gray' }}
        />
      );
    } else if (battery > 0.6) {
      icon = (
        <Battery80
          fontSize="small"
          style={{ color: online ? 'forestgreen' : 'gray' }}
        />
      );
    } else if (battery > 0.5) {
      icon = (
        <Battery60
          fontSize="small"
          style={{ color: online ? 'forestgreen' : 'gray' }}
        />
      );
    } else if (battery > 0.3) {
      icon = (
        <Battery50
          fontSize="small"
          style={{ color: online ? 'gold' : 'gray' }}
        />
      );
    } else if (battery > 0.2) {
      icon = (
        <Battery30
          fontSize="small"
          style={{ color: online ? 'gold' : 'gray' }}
        />
      );
    } else if (battery > 0.1) {
      icon = (
        <Battery20
          fontSize="small"
          style={{ color: online ? 'red' : 'gray' }}
        />
      );
    } else {
      icon = (
        <BatteryAlert
          fontSize="small"
          style={{ color: online ? 'red' : 'gray' }}
        />
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        {`${(Number(batteryLevel) * 100).toFixed(0)}%`}
      </div>
    );
  };

  const renderSessionExpiry = (expiry: number) => {
    const isExpired = moment().isAfter(expiry * 1000);
    const expiredStr = moment(expiry * 1000).fromNow();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isExpired ? (
          <AddCircle
            style={{
              transform: 'rotate(45deg)',
              color: 'red',
              marginRight: '0.5rem',
            }}
          />
        ) : (
          <CheckCircle
            style={{ color: 'forestgreen', marginRight: '0.5rem' }}
          />
        )}
        {expiredStr}
      </div>
    );
  };

  return (
    <TableRow>
      <TableCell>{device.stationNumber}</TableCell>
      <TableCell>
        <DeviceStatus
          openStatus={device.openStatus}
          lastTimestamp={device.lastTimestamp}
          unsyncdCount={device.unsyncdCount}
        />
      </TableCell>
      <TableCell>{device.unsyncdCount}</TableCell>
      <TableCell>
        {renderBatteryIcon(device.batteryLevel, device.openStatus)}
      </TableCell>
      <TableCell>{device?.usedMemory || '0'} MB</TableCell>
      <TableCell>
        {new Date(device.lastTimestamp * 1000).toLocaleString()}
      </TableCell>
      <TableCell>{renderSessionExpiry(device.tokenExpiry)}</TableCell>
      <TableCell>{device.vendorDeviceId}</TableCell>
      <TableCell>{device.firstName}</TableCell>
      <TableCell>{device.lastName}</TableCell>
      <TableCell>{device.phoneNumber}</TableCell>
    </TableRow>
  );
};

export default React.memo(DeviceItemRow);
