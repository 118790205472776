import React, { useState, useContext } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Delete, MoreHoriz, Assignment, Edit } from '@mui/icons-material';
import { User } from '../../../entities/User';

import UserAssignments from './UserAssignments';
import EditUserModal from '../Modals/EditUser';
import DeleteUserConfirmModal from '../Modals/DeleteUser';
import CreateUserAssignmentModal from '../Modals/CreateUserAssignment';

import { UsersContext } from '../../../context/UsersContext';

interface UserRowProps {
  tenant: string;
  user: User;
}

const UserRow: React.FC<UserRowProps> = ({ user, tenant }) => {
  const { refreshUsers } = useContext(UsersContext);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [createAssignment, setCreateAssignment] = useState(false);
  const [editUser, setEditUser] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  const menuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setDeleteConfirm(false);
    setCreateAssignment(false);
    setEditUser(false);
    setAnchorEl(null);
    refreshUsers();
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.phoneNumber}</TableCell>
        <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
        <TableCell>{user.recentPasscode}</TableCell>
        <TableCell align={'center'} padding="none">
          {(user.assignments.length > 0 && (
            <UserAssignments
              assignments={user.assignments}
              user={user}
              tenant={tenant}
            />
          )) ||
            (user.electionManager && <div>Election Manager</div>)}
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={menuHandler}>
            <MoreHoriz fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {menuOpen && (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              maxHeight: 180,
              width: '15rem',
            },
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => setCreateAssignment(true)}
              disabled={user.electionManager}
            >
              <ListItemIcon>
                <Assignment fontSize="small" />
              </ListItemIcon>
              <ListItemText>{'Add Assignment'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setEditUser(true)}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>{'Edit User'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setDeleteConfirm(true)}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>{'Delete User'}</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      {deleteConfirm && (
        <DeleteUserConfirmModal
          open={deleteConfirm}
          onClose={onClose}
          user={user}
          tenant={tenant}
        />
      )}

      {createAssignment && (
        <CreateUserAssignmentModal
          open={createAssignment}
          onClose={onClose}
          user={user}
          tenant={tenant}
        />
      )}

      {editUser && (
        <EditUserModal
          open={editUser}
          onClose={onClose}
          user={user}
          tenant={tenant}
        />
      )}
    </>
  );
};

export default React.memo(UserRow);
