import React, { useContext, useMemo } from 'react';
import { TenantContext } from '../../context/TenantContext';
import Filter from './FilterBar';
import ValidationTable from './ValidationFindings';
import CustomerFacingResults from './CustomerFacingResults';
import {
  CustomerValidationReport,
  InternalValidationReport,
} from '../../entities/Validations';

const ValidationContainer: React.FC = () => {
  const { validations } = useContext(TenantContext);

  const customerValidationFindings: CustomerValidationReport = useMemo(() => {
    const valid = validations.find((v) => 'customerView' in v);
    if (valid) {
      return valid['customerView'] as CustomerValidationReport;
    } else {
      return {
        ballots: { batchId: '', messages: [] },
        register: { batchId: '', messages: [] },
        proxy: { batchId: '', messages: [] },
        staff: { batchId: '', messages: [] },
      };
    }
  }, [validations]);

  const internalValidationFindings = useMemo(() => {
    const findings: InternalValidationReport[] = [];
    for (const v of validations) {
      if (!('customerView' in v)) {
        findings.push(v as InternalValidationReport);
      }
    }
    return findings;
  }, [validations]);

  return (
    <div>
      <Filter />
      <CustomerFacingResults data={customerValidationFindings} />
      <ValidationTable data={internalValidationFindings} />
    </div>
  );
};

export default React.memo(ValidationContainer);
